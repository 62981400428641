<template>
<svg class="hematit-layer wrapperSvgNeg30 marginstrutMax2000" xmlns="http://www.w3.org/2000/svg" width="2762.31" height="4836.06" viewBox="-300 -100 14500 11000">

  <g
      v-for="postInfo in branchStandPosInfo"
      :key="postInfo.index"
      class="rightGroup postStandSector"
      v-bind:style="postInfo.style"
  >

    <template
        v-if="includeUprightProtector && postInfo.index === 0">
      <SignalDoubleLeft v-show="doubleBranch"/>
      <SignalSingleLeft v-show="!doubleBranch"/>
    </template>

    <template  v-if="postInfo.index > 0">
      <Strut750 v-show="selectedStagSection.width == 750" />
      <Strut1000 v-show="selectedStagSection.width == 1000" />
      <Strut1250 v-show="selectedStagSection.width == 1250" />
      <Strut1500 v-show="selectedStagSection.width == 1500" />
      <Strut2000 v-show="selectedStagSection.width == 2000" />
    </template>

    <Foot1425 v-show="!doubleBranch"/>
    <Foot2225 v-show="doubleBranch" />

    <!-- Manages the Post stand -->
    <template>
      <Post2000 v-show="postOpt == 2000" />
      <Post2500 v-show="postOpt == 2500" />
      <Post3000 v-show="postOpt == 3000" />
      <Post3500 v-show="postOpt == 3500" />
      <Post4000 v-show="postOpt == 4000" />
    </template>

    <!-- Manages the arm -->
    <template v-if="selectedArm.length == 600">
      <Arm600
          v-for="armPos in armPosInfo"
          :key="armPos.index"
          :doubleBranch="doubleBranch"
          v-bind:style ="armPos.stylePos"
      />
    </template>
    <template v-if="selectedArm.length == 1000">
      <Arm1000
          v-for="armPos in armPosInfo"
          :key="armPos.index"
          :doubleBranch="doubleBranch"
          v-bind:style ="armPos.stylePos"
      />
    </template>

    <!-- Manages second upright protector -->
    <template
        v-bind:style="uprightProtectorPosStyle"
        v-if="includeUprightProtector && ((postInfo.index+1) === branchStandPosInfo.length)">
      <SignalDoubleRight v-show="doubleBranch"/>
      <SignalSingleRight v-show="!doubleBranch"/>
    </template>
    </g>

    <ArrowLength
        :startX="arrowLengthPosBaseCoords.x"
        :startY="arrowLengthPosBaseCoords.y"
        :totalLength="totalLength"
    />

    <ArrowDepth
        :startX="arrowDepthPosBaseCoords.x"
        :startY="arrowDepthPosBaseCoords.y"
        :totalDepth="totalDepth"
    />

    <ArrowHeight
        :startX="arrowHeightPosBaseCoords.x"
        :startY="arrowHeightPosBaseCoords.y"
        :totalHeight="totalHeight"
    />
</svg>

</template>
<script>

import Arm600 from "@/components/svg-parts/hematit/arms/Arm600-H.vue";
import Arm1000 from "@/components/svg-parts/hematit/arms/Arm1000-H.vue";

import Foot1425 from "@/components/svg-parts/hematit/foots/Foot1425-H.vue";
import Foot2225 from "@/components/svg-parts/hematit/foots/Foot2225-H.vue";

import Post2000 from "@/components/svg-parts/hematit/posts/Post2000-H.vue";
import Post2500 from "@/components/svg-parts/hematit/posts/Post2500-H.vue";
import Post3000 from "@/components/svg-parts/hematit/posts/Post3000-H.vue";
import Post3500 from "@/components/svg-parts/hematit/posts/Post3500-H.vue";
import Post4000 from "@/components/svg-parts/hematit/posts/Post4000-H.vue";

import Strut750 from "@/components/svg-parts/hematit/struts/Strut750-H.vue";
import Strut1000 from "@/components/svg-parts/hematit/struts/Strut1000-H.vue";
import Strut1250 from "@/components/svg-parts/hematit/struts/Strut1250-H.vue";
import Strut1500 from "@/components/svg-parts/hematit/struts/Strut1500-H.vue";
import Strut2000 from "@/components/svg-parts/hematit/struts/Strut2000-H.vue";

// post protection
import SignalDoubleLeft from "@/components/svg-parts/post-protection/HematitSignalDoubleLeft.vue";
import SignalDoubleRight from "@/components/svg-parts/post-protection/HematitSignalDoubleRight.vue";
import SignalSingleLeft from "@/components/svg-parts/post-protection/HematitSignalSingleLeft.vue";
import SignalSingleRight from "@/components/svg-parts/post-protection/HematitSignalSingleRight.vue";

import {mapGetters} from "vuex";

// Arrows
import ArrowHeight from "@/components/svg-parts/misc/ArrowHeight.vue";
import ArrowLength from "@/components/svg-parts/misc/ArrowLength.vue";
import ArrowDepth from "@/components/svg-parts/misc/ArrowDepth.vue";

export default {
  name: "HematitCombinedParts.vue",
  components: {
    Arm600,
    Arm1000,

    Foot1425,
    Foot2225,

    Post2000,
    Post2500,
    Post3000,
    Post3500,
    Post4000,

    Strut750,
    Strut1000,
    Strut1250,
    Strut1500,
    Strut2000,
    SignalDoubleLeft,
    SignalDoubleRight,
    SignalSingleLeft,
    SignalSingleRight,

    ArrowHeight,
    ArrowLength,
    ArrowDepth
  },

  computed: {
    selectedStagSection: {
      get() {
        return this.$store.state.selectedStagSection;
      },
    },
    selectedArm: {
      get() {
        return this.$store.state.selectedCarryingArm;
      }
    },

    selectedArmPlanes: {
      get() {
        return this.$store.state.selectedArmPlanes;
      }
    },
    doubleBranch: {
      get() {
        return this.$store.state.doubleBranch;
      }
    },
    totalHeight: {
      get() {
        return this.$store.state.totalHeight;
      }
    },
    totalLength: {
      get() {
        return this.$store.state.totalLength;
      }
    },
    totalDepth: {
      get() {
        return this.$store.state.totalDepth;
      }
    },
    includeUprightProtector: {
      get() {
        return this.$store.state.includeUprightProtectors;
      }
    },
    uprightProtectorPosStyle: {
      get() {
        return "translate: transform(0, "+this.totalHeight+" px)";
      }
    },
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
    footLength: {
      get() {
        return this.$store.state.postFootLength;
      }
    },
    ...mapGetters([
      "availableHeights",
      "armPosInfo",
      "selectedPostStand",
      "countBranchStands",
      "branchStandPosInfo",
      "arrowHeightPosBaseCoords",
      "arrowLengthPosBaseCoords",
      "arrowDepthPosBaseCoords"
    ]),
  }
};
</script>

<style scoped>

</style>
