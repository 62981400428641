<template>
      <g class="foot1425">
        <g class="leftsidefoot">
          <g class="top-rightside">
            <polygon points="818.99 4419.56 645.78 4519.56 645.78 4498.56 818.99 4398.56 818.99 4419.56" fill="#157c46"/>
          </g>
          <g class="topside">
            <polygon points="768.73 4739.54 682.13 4789.54 645.76 4768.54 732.36 4718.54 768.73 4739.54" fill="#1f9456"/>
          </g>
          <g class="top-rightside" data-name="top-rightside">
            <polygon points="818.96 4668.54 645.76 4768.54 645.76 4747.54 818.96 4647.54 818.96 4668.54" fill="#157b46"/>
          </g>
          <g class="right-backside">
            <polygon points="1916.23 4077.04 682.14 4789.54 682.14 4519.54 1916.23 3807.04 1916.23 4077.04" fill="#083a1f"/>
          </g>
          <g class="topside" data-name="topside">
            <polygon points="1916.24 3807.05 682.15 4519.55 645.78 4498.55 1879.87 3786.05 1916.24 3807.05" fill="#1f9456"/>
          </g>
          <g class="bolt">
            <path d="M2699.64,3837.52l-38.3-22.11a7,7,0,0,1-2.93-5.61v-.17c0-2.17,1.31-3.16,2.93-2.22l38.3,22.11a7,7,0,0,1,2.93,5.61v.17C2702.57,3837.46,2701.26,3838.45,2699.64,3837.52Z" transform="translate(-1963.6 909.45)" fill="#c9c3c7"/>
            <path d="M2697,3833.48l-34.15-19.71a2,2,0,0,1-.82-1.58v-.8c0-.6.37-.88.82-.62l34.15,19.71a2,2,0,0,1,.83,1.59v.79C2697.81,3833.47,2697.44,3833.75,2697,3833.48Z" transform="translate(-1963.6 909.45)" fill="#e5e3e4"/>
          </g>
          <g class="bolt" data-name="bolt">
            <path d="M2699.64,3700.1l-38.3-22.11a7,7,0,0,1-2.93-5.61v-.17c0-2.16,1.31-3.16,2.93-2.22l38.3,22.11a7,7,0,0,1,2.93,5.61v.17C2702.57,3700,2701.26,3701,2699.64,3700.1Z" transform="translate(-1963.6 909.45)" fill="#c9c3c7"/>
            <path d="M2697,3696.07l-34.15-19.72a2,2,0,0,1-.82-1.58V3674c0-.61.37-.89.82-.63l34.15,19.72a2,2,0,0,1,.83,1.58v.79C2697.81,3696.05,2697.44,3696.33,2697,3696.07Z" transform="translate(-1963.6 909.45)" fill="#e5e3e4"/>
          </g>
        </g>
        <g class="rightsidefoot">
          <g class="main-rightside">
            <polygon points="1960.39 4102.56 726.3 4815.06 726.3 4545.06 1960.39 3832.56 1960.39 4102.56" fill="#0b502e"/>
          </g>
          <g class="topside" data-name="topside">
            <polygon points="1996.76 3853.55 762.68 4566.06 726.3 4545.06 1960.39 3832.55 1996.76 3853.55" fill="#1f9456"/>
          </g>
          <g class="topside" data-name="topside">
            <polygon points="1996.76 4123.56 762.68 4836.06 726.3 4815.06 1960.39 4102.56 1996.76 4123.56" fill="#1f9456"/>
          </g>
          <g class="top-rightside" data-name="top-rightside">
            <polygon points="1996.76 4123.56 762.68 4836.06 762.68 4815.06 1996.76 4102.56 1996.76 4123.56" fill="#157b46"/>
          </g>
          <g class="top-rightside" data-name="top-rightside">
            <polygon points="1996.78 3874.56 762.7 4587.06 762.7 4566.06 1996.78 3853.56 1996.78 3874.56" fill="#157b46"/>
          </g>
        </g>
      </g>
</template>
<script>
export default {
  name: "Foot1425-H.vue",
};
</script>