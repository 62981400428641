<template>
<g class="post3952">
      <g class="topPost-2" data-name="topPost">
        <g class="topPost-rotate">
          <polygon class="top-post-inner-2" data-name="top-post-inner" points="1654.89 170 1526.72 96 1693 0 1821.17 74 1654.89 170" fill="#a2d2ee"/>
          <polygon class="top-post-outer-2" data-name="top-post-outer" points="1653.16 147 1566.56 97 1694.73 23 1781.33 73 1653.16 147" fill="#64a7d1"/>
        </g>
        <polygon class="topPost-front-2" data-name="topPost-front" points="1694.73 23 1694.73 123 1781.33 73 1694.73 23" fill="#88c3e6"/>
      </g>
      <g class="frontParts-2" data-name="frontParts">
        <polygon class="frontTriangle-2" data-name="frontTriangle" points="1526.72 3856 1526.72 4004.44 1654.89 3929.57 1526.72 3856" fill="#88c3e6"/>
        <polygon class="frontPost-2" data-name="frontPost" points="1654.89 3929.99 1526.72 3855.99 1526.72 95.99 1654.89 169.99 1654.89 3929.99" fill="#88c3e6"/>
      </g>
      <g class="rightSide-2" data-name="rightSide">
        <polygon class="rightSideBottom-2" data-name="rightSideBottom" points="1821.17 3834.02 1654.89 3930.02 1654.89 3530.02 1821.17 3434.02 1821.17 3834.02" fill="#64a7d1"/>
        <g class="holeSection-2" data-name="holeSection">
          <g>
            <polygon points="1821.17 3434.02 1654.89 3530.02 1654.89 3458.53 1821.17 3362.53 1821.17 3434.02" fill="#64a7d1"/>
            <polygon points="1699.93 3500.26 1675.7 3514.23 1675.68 3488.52 1699.9 3474.55 1699.93 3500.26" fill="#010015"/>
            <polygon points="1800.36 3442.26 1776.14 3456.26 1776.16 3430.52 1800.38 3416.52 1800.36 3442.26" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3362.53 1654.89 3458.53 1654.89 3387.04 1821.17 3291.04 1821.17 3362.53" fill="#64a7d1"/>
            <polygon points="1699.93 3428.77 1675.7 3442.74 1675.68 3417.03 1699.9 3403.06 1699.93 3428.77" fill="#010015"/>
            <polygon points="1800.36 3370.77 1776.14 3384.77 1776.16 3359.03 1800.38 3345.03 1800.36 3370.77" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3291.04 1654.89 3387.04 1654.89 3315.55 1821.17 3219.55 1821.17 3291.04" fill="#64a7d1"/>
            <polygon points="1699.93 3357.28 1675.7 3371.25 1675.68 3345.54 1699.9 3331.57 1699.93 3357.28" fill="#010015"/>
            <polygon points="1800.36 3299.28 1776.14 3313.28 1776.16 3287.54 1800.38 3273.54 1800.36 3299.28" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3219.55 1654.89 3315.55 1654.89 3244.06 1821.17 3148.06 1821.17 3219.55" fill="#64a7d1"/>
            <polygon points="1699.93 3285.79 1675.7 3299.76 1675.68 3274.05 1699.9 3260.08 1699.93 3285.79" fill="#010015"/>
            <polygon points="1800.36 3227.79 1776.14 3241.79 1776.16 3216.05 1800.38 3202.05 1800.36 3227.79" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3148.06 1654.89 3244.06 1654.89 3172.57 1821.17 3076.57 1821.17 3148.06" fill="#64a7d1"/>
            <polygon points="1699.93 3214.3 1675.7 3228.27 1675.68 3202.57 1699.9 3188.59 1699.93 3214.3" fill="#010015"/>
            <polygon points="1800.36 3156.3 1776.14 3170.3 1776.16 3144.57 1800.38 3130.57 1800.36 3156.3" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3076.57 1654.89 3172.57 1654.89 3101.08 1821.17 3005.08 1821.17 3076.57" fill="#64a7d1"/>
            <polygon points="1699.93 3142.81 1675.7 3156.78 1675.68 3131.08 1699.9 3117.1 1699.93 3142.81" fill="#010015"/>
            <polygon points="1800.36 3084.81 1776.14 3098.81 1776.16 3073.08 1800.38 3059.08 1800.36 3084.81" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3005.08 1654.89 3101.08 1654.89 3029.59 1821.17 2933.59 1821.17 3005.08" fill="#64a7d1"/>
            <polygon points="1699.93 3071.32 1675.7 3085.3 1675.68 3059.59 1699.9 3045.61 1699.93 3071.32" fill="#010015"/>
            <polygon points="1800.36 3013.32 1776.14 3027.32 1776.16 3001.59 1800.38 2987.59 1800.36 3013.32" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2933.59 1654.89 3029.59 1654.89 2958.1 1821.17 2862.1 1821.17 2933.59" fill="#64a7d1"/>
            <polygon points="1699.93 2999.83 1675.7 3013.8 1675.68 2988.1 1699.9 2974.13 1699.93 2999.83" fill="#010015"/>
            <polygon points="1800.36 2941.83 1776.14 2955.83 1776.16 2930.1 1800.38 2916.1 1800.36 2941.83" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2862.1 1654.89 2958.1 1654.89 2886.61 1821.17 2790.61 1821.17 2862.1" fill="#64a7d1"/>
            <polygon points="1699.93 2928.34 1675.7 2942.32 1675.68 2916.61 1699.9 2902.64 1699.93 2928.34" fill="#010015"/>
            <polygon points="1800.36 2870.34 1776.14 2884.34 1776.16 2858.61 1800.38 2844.61 1800.36 2870.34" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2790.61 1654.89 2886.61 1654.89 2815.12 1821.17 2719.12 1821.17 2790.61" fill="#64a7d1"/>
            <polygon points="1699.93 2856.86 1675.7 2870.83 1675.68 2845.12 1699.9 2831.15 1699.93 2856.86" fill="#010015"/>
            <polygon points="1800.36 2798.85 1776.14 2812.85 1776.16 2787.12 1800.38 2773.12 1800.36 2798.85" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2719.12 1654.89 2815.12 1654.89 2743.63 1821.17 2647.63 1821.17 2719.12" fill="#64a7d1"/>
            <polygon points="1699.93 2785.36 1675.7 2799.34 1675.68 2773.63 1699.9 2759.66 1699.93 2785.36" fill="#010015"/>
            <polygon points="1800.36 2727.36 1776.14 2741.36 1776.16 2715.63 1800.38 2701.63 1800.36 2727.36" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2647.63 1654.89 2743.63 1654.89 2672.14 1821.17 2576.14 1821.17 2647.63" fill="#64a7d1"/>
            <polygon points="1699.93 2713.88 1675.7 2727.85 1675.68 2702.14 1699.9 2688.17 1699.93 2713.88" fill="#010015"/>
            <polygon points="1800.36 2655.88 1776.14 2669.88 1776.16 2644.14 1800.38 2630.14 1800.36 2655.88" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2576.14 1654.89 2672.14 1654.89 2600.66 1821.17 2504.66 1821.17 2576.14" fill="#64a7d1"/>
            <polygon points="1699.93 2642.39 1675.7 2656.36 1675.68 2630.65 1699.9 2616.68 1699.93 2642.39" fill="#010015"/>
            <polygon points="1800.36 2584.39 1776.14 2598.39 1776.16 2572.65 1800.38 2558.65 1800.36 2584.39" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2504.65 1654.89 2600.66 1654.89 2529.16 1821.17 2433.16 1821.17 2504.65" fill="#64a7d1"/>
            <polygon points="1699.93 2570.9 1675.7 2584.87 1675.68 2559.16 1699.9 2545.19 1699.93 2570.9" fill="#010015"/>
            <polygon points="1800.36 2512.9 1776.14 2526.9 1776.16 2501.16 1800.38 2487.16 1800.36 2512.9" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2433.16 1654.89 2529.16 1654.89 2457.68 1821.17 2361.68 1821.17 2433.16" fill="#64a7d1"/>
            <polygon points="1699.93 2499.41 1675.7 2513.38 1675.68 2487.67 1699.9 2473.7 1699.93 2499.41" fill="#010015"/>
            <polygon points="1800.36 2441.41 1776.14 2455.41 1776.16 2429.67 1800.38 2415.67 1800.36 2441.41" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2361.68 1654.89 2457.68 1654.89 2386.19 1821.17 2290.19 1821.17 2361.68" fill="#64a7d1"/>
            <polygon points="1699.93 2427.92 1675.7 2441.89 1675.68 2416.18 1699.9 2402.21 1699.93 2427.92" fill="#010015"/>
            <polygon points="1800.36 2369.92 1776.14 2383.92 1776.16 2358.18 1800.38 2344.18 1800.36 2369.92" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2290.19 1654.89 2386.19 1654.89 2314.7 1821.17 2218.7 1821.17 2290.19" fill="#64a7d1"/>
            <polygon points="1699.93 2356.43 1675.7 2370.4 1675.68 2344.69 1699.9 2330.72 1699.93 2356.43" fill="#010015"/>
            <polygon points="1800.36 2298.43 1776.14 2312.43 1776.16 2286.69 1800.38 2272.69 1800.36 2298.43" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2218.7 1654.89 2314.7 1654.89 2243.21 1821.17 2147.21 1821.17 2218.7" fill="#64a7d1"/>
            <polygon points="1699.93 2284.94 1675.7 2298.91 1675.68 2273.2 1699.9 2259.23 1699.93 2284.94" fill="#010015"/>
            <polygon points="1800.36 2226.94 1776.14 2240.94 1776.16 2215.2 1800.38 2201.2 1800.36 2226.94" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2147.21 1654.89 2243.21 1654.89 2171.72 1821.17 2075.72 1821.17 2147.21" fill="#64a7d1"/>
            <polygon points="1699.93 2213.45 1675.7 2227.42 1675.68 2201.71 1699.9 2187.74 1699.93 2213.45" fill="#010015"/>
            <polygon points="1800.36 2155.45 1776.14 2169.45 1776.16 2143.72 1800.38 2129.71 1800.36 2155.45" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2075.72 1654.89 2171.72 1654.89 2100.23 1821.17 2004.23 1821.17 2075.72" fill="#64a7d1"/>
            <polygon points="1699.93 2141.96 1675.7 2155.93 1675.68 2130.22 1699.9 2116.25 1699.93 2141.96" fill="#010015"/>
            <polygon points="1800.36 2083.96 1776.14 2097.96 1776.16 2072.22 1800.38 2058.22 1800.36 2083.96" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2004.23 1654.89 2100.23 1654.89 2028.74 1821.17 1932.74 1821.17 2004.23" fill="#64a7d1"/>
            <polygon points="1699.93 2070.47 1675.7 2084.44 1675.68 2058.74 1699.9 2044.76 1699.93 2070.47" fill="#010015"/>
            <polygon points="1800.36 2012.47 1776.14 2026.47 1776.16 2000.74 1800.38 1986.73 1800.36 2012.47" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1932.74 1654.89 2028.74 1654.89 1957.25 1821.17 1861.25 1821.17 1932.74" fill="#64a7d1"/>
            <polygon points="1699.93 1998.98 1675.7 2012.95 1675.68 1987.25 1699.9 1973.27 1699.93 1998.98" fill="#010015"/>
            <polygon points="1800.36 1940.98 1776.14 1954.98 1776.16 1929.25 1800.38 1915.25 1800.36 1940.98" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1861.25 1654.89 1957.25 1654.89 1885.76 1821.17 1789.76 1821.17 1861.25" fill="#64a7d1"/>
            <polygon points="1699.93 1927.49 1675.7 1941.46 1675.68 1915.76 1699.9 1901.79 1699.93 1927.49" fill="#010015"/>
            <polygon points="1800.36 1869.49 1776.14 1883.49 1776.16 1857.76 1800.38 1843.76 1800.36 1869.49" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1789.76 1654.89 1885.76 1654.89 1814.27 1821.17 1718.27 1821.17 1789.76" fill="#64a7d1"/>
            <polygon points="1699.93 1856 1675.7 1869.97 1675.68 1844.27 1699.9 1830.3 1699.93 1856" fill="#010015"/>
            <polygon points="1800.36 1798 1776.14 1812 1776.16 1786.27 1800.38 1772.27 1800.36 1798" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1718.27 1654.89 1814.27 1654.89 1742.78 1821.17 1646.78 1821.17 1718.27" fill="#64a7d1"/>
            <polygon points="1699.93 1784.51 1675.7 1798.49 1675.68 1772.78 1699.9 1758.81 1699.93 1784.51" fill="#010015"/>
            <polygon points="1800.36 1726.51 1776.14 1740.51 1776.16 1714.78 1800.38 1700.78 1800.36 1726.51" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1646.78 1654.89 1742.78 1654.89 1671.29 1821.17 1575.29 1821.17 1646.78" fill="#64a7d1"/>
            <polygon points="1699.93 1713.03 1675.7 1727 1675.68 1701.29 1699.9 1687.32 1699.93 1713.03" fill="#010015"/>
            <polygon points="1800.36 1655.02 1776.14 1669.03 1776.16 1643.29 1800.38 1629.29 1800.36 1655.02" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1575.29 1654.89 1671.29 1654.89 1599.8 1821.17 1503.8 1821.17 1575.29" fill="#64a7d1"/>
            <polygon points="1699.93 1641.54 1675.7 1655.51 1675.68 1629.8 1699.9 1615.83 1699.93 1641.54" fill="#010015"/>
            <polygon points="1800.36 1583.54 1776.14 1597.54 1776.16 1571.8 1800.38 1557.8 1800.36 1583.54" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1503.8 1654.89 1599.8 1654.89 1528.31 1821.17 1432.31 1821.17 1503.8" fill="#64a7d1"/>
            <polygon points="1699.93 1570.05 1675.7 1584.02 1675.68 1558.31 1699.9 1544.34 1699.93 1570.05" fill="#010015"/>
            <polygon points="1800.36 1512.05 1776.14 1526.05 1776.16 1500.31 1800.38 1486.31 1800.36 1512.05" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1432.31 1654.89 1528.31 1654.89 1456.83 1821.17 1360.83 1821.17 1432.31" fill="#64a7d1"/>
            <polygon points="1699.93 1498.56 1675.7 1512.53 1675.68 1486.82 1699.9 1472.85 1699.93 1498.56" fill="#010015"/>
            <polygon points="1800.36 1440.56 1776.14 1454.56 1776.16 1428.82 1800.38 1414.82 1800.36 1440.56" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1360.83 1654.89 1456.83 1654.89 1385.34 1821.17 1289.34 1821.17 1360.83" fill="#64a7d1"/>
            <polygon points="1699.93 1427.07 1675.7 1441.04 1675.68 1415.33 1699.9 1401.36 1699.93 1427.07" fill="#010015"/>
            <polygon points="1800.36 1369.07 1776.14 1383.07 1776.16 1357.33 1800.38 1343.33 1800.36 1369.07" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1289.34 1654.89 1385.34 1654.89 1313.85 1821.17 1217.85 1821.17 1289.34" fill="#64a7d1"/>
            <polygon points="1699.93 1355.58 1675.7 1369.55 1675.68 1343.84 1699.9 1329.87 1699.93 1355.58" fill="#010015"/>
            <polygon points="1800.36 1297.58 1776.14 1311.58 1776.16 1285.84 1800.38 1271.84 1800.36 1297.58" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1217.85 1654.89 1313.85 1654.89 1242.36 1821.17 1146.36 1821.17 1217.85" fill="#64a7d1"/>
            <polygon points="1699.93 1284.09 1675.7 1298.06 1675.68 1272.35 1699.9 1258.38 1699.93 1284.09" fill="#010015"/>
            <polygon points="1800.36 1226.09 1776.14 1240.09 1776.16 1214.35 1800.38 1200.35 1800.36 1226.09" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1146.36 1654.89 1242.36 1654.89 1170.87 1821.17 1074.87 1821.17 1146.36" fill="#64a7d1"/>
            <polygon points="1699.93 1212.6 1675.7 1226.57 1675.68 1200.86 1699.9 1186.89 1699.93 1212.6" fill="#010015"/>
            <polygon points="1800.36 1154.6 1776.14 1168.6 1776.16 1142.86 1800.38 1128.86 1800.36 1154.6" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1074.87 1654.89 1170.87 1654.89 1099.38 1821.17 1003.38 1821.17 1074.87" fill="#64a7d1"/>
            <polygon points="1699.93 1141.11 1675.7 1155.08 1675.68 1129.37 1699.9 1115.4 1699.93 1141.11" fill="#010015"/>
            <polygon points="1800.36 1083.11 1776.14 1097.11 1776.16 1071.37 1800.38 1057.37 1800.36 1083.11" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1003.38 1654.89 1099.38 1654.89 1027.89 1821.17 931.89 1821.17 1003.38" fill="#64a7d1"/>
            <polygon points="1699.93 1069.62 1675.7 1083.59 1675.68 1057.88 1699.9 1043.91 1699.93 1069.62" fill="#010015"/>
            <polygon points="1800.36 1011.62 1776.14 1025.62 1776.16 999.88 1800.38 985.88 1800.36 1011.62" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 931.89 1654.89 1027.89 1654.89 956.4 1821.17 860.4 1821.17 931.89" fill="#64a7d1"/>
            <polygon points="1699.93 998.13 1675.7 1012.1 1675.68 986.39 1699.9 972.42 1699.93 998.13" fill="#010015"/>
            <polygon points="1800.36 940.13 1776.14 954.13 1776.16 928.4 1800.38 914.39 1800.36 940.13" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 860.4 1654.89 956.4 1654.89 884.91 1821.17 788.91 1821.17 860.4" fill="#64a7d1"/>
            <polygon points="1699.93 926.64 1675.7 940.61 1675.68 914.91 1699.9 900.93 1699.93 926.64" fill="#010015"/>
            <polygon points="1800.36 868.64 1776.14 882.64 1776.16 856.91 1800.38 842.91 1800.36 868.64" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 788.91 1654.89 884.91 1654.89 813.42 1821.17 717.42 1821.17 788.91" fill="#64a7d1"/>
            <polygon points="1699.93 855.15 1675.7 869.12 1675.68 843.42 1699.9 829.44 1699.93 855.15" fill="#010015"/>
            <polygon points="1800.36 797.15 1776.14 811.15 1776.16 785.42 1800.38 771.42 1800.36 797.15" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 717.42 1654.89 813.42 1654.89 741.93 1821.17 645.93 1821.17 717.42" fill="#64a7d1"/>
            <polygon points="1699.93 783.66 1675.7 797.63 1675.68 771.93 1699.9 757.96 1699.93 783.66" fill="#010015"/>
            <polygon points="1800.36 725.66 1776.14 739.66 1776.16 713.93 1800.38 699.93 1800.36 725.66" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 645.93 1654.89 741.93 1654.89 670.44 1821.17 574.44 1821.17 645.93" fill="#64a7d1"/>
            <polygon points="1699.93 712.17 1675.7 726.15 1675.68 700.44 1699.9 686.47 1699.93 712.17" fill="#010015"/>
            <polygon points="1800.36 654.17 1776.14 668.17 1776.16 642.44 1800.38 628.44 1800.36 654.17" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 574.44 1654.89 670.44 1654.89 598.95 1821.17 502.95 1821.17 574.44" fill="#64a7d1"/>
            <polygon points="1699.93 640.68 1675.7 654.66 1675.68 628.95 1699.9 614.98 1699.93 640.68" fill="#010015"/>
            <polygon points="1800.36 582.68 1776.14 596.68 1776.16 570.95 1800.38 556.95 1800.36 582.68" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 502.95 1654.89 598.95 1654.89 527.46 1821.17 431.46 1821.17 502.95" fill="#64a7d1"/>
            <polygon points="1699.93 569.2 1675.7 583.17 1675.68 557.46 1699.9 543.49 1699.93 569.2" fill="#010015"/>
            <polygon points="1800.36 511.19 1776.14 525.2 1776.16 499.46 1800.38 485.46 1800.36 511.19" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 431.46 1654.89 527.46 1654.89 455.97 1821.17 359.97 1821.17 431.46" fill="#64a7d1"/>
            <polygon points="1699.93 497.71 1675.7 511.68 1675.68 485.97 1699.9 472 1699.93 497.71" fill="#010015"/>
            <polygon points="1800.36 439.7 1776.14 453.71 1776.16 427.97 1800.38 413.97 1800.36 439.7" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 359.97 1654.89 455.97 1654.89 384.49 1821.17 288.48 1821.17 359.97" fill="#64a7d1"/>
            <polygon points="1699.93 426.22 1675.7 440.19 1675.68 414.48 1699.9 400.51 1699.93 426.22" fill="#010015"/>
            <polygon points="1800.36 368.22 1776.14 382.22 1776.16 356.48 1800.38 342.48 1800.36 368.22" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 288.48 1654.89 384.48 1654.89 313 1821.17 217 1821.17 288.48" fill="#64a7d1"/>
            <polygon points="1699.93 354.73 1675.7 368.7 1675.68 342.99 1699.9 329.02 1699.93 354.73" fill="#010015"/>
            <polygon points="1800.36 296.73 1776.14 310.73 1776.16 284.99 1800.38 270.99 1800.36 296.73" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 217 1654.89 313 1654.89 241.51 1821.17 145.51 1821.17 217" fill="#64a7d1"/>
            <polygon points="1699.93 283.24 1675.7 297.21 1675.68 271.5 1699.9 257.53 1699.93 283.24" fill="#010015"/>
            <polygon points="1800.36 225.24 1776.14 239.24 1776.16 213.5 1800.38 199.5 1800.36 225.24" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 145.51 1654.89 241.51 1654.89 170.02 1821.17 74.02 1821.17 145.51" fill="#64a7d1"/>
            <polygon points="1699.93 211.75 1675.7 225.72 1675.68 200.01 1699.9 186.04 1699.93 211.75" fill="#010015"/>
            <polygon points="1800.36 153.75 1776.14 167.75 1776.16 142.01 1800.38 128.01 1800.36 153.75" fill="#010015"/>
          </g>
        </g>
      </g>
    </g>
</template>
<script>
export default {
  name: "Post3952.vue",
};
</script>