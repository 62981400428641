<template>
    <g class="arm500">
      <g class="armfront" data-name="armfront">
        <polygon class="innertop topside" data-name="innertop topside" points="1337.58 3028.47 1302.93 3008.47 1302.93 2968.47 1337.58 2988.47 1337.58 3028.47" fill="#5070af"/>
        <polygon class="innerright rightside" data-name="innerright rightside" points="1302.93 2968.47 1302.93 3008.47 1337.58 2988.47 1302.93 2968.47" fill="#365593"/>
      </g>
      <g class="armholder" data-name="armholder">
        <polygon class="armholder-front" data-name="armholder-front" points="1779.69 2815.72 1727.73 2785.72 1727.73 2665.72 1779.69 2695.72 1779.69 2815.72" fill="#7793bf"/>
        <polygon class="armholder-right" data-name="armholder-right-10px" points="1788.36 2810.72 1779.7 2815.72 1779.7 2695.72 1788.36 2690.72 1788.36 2810.72" fill="#3d6199"/>
      </g>
      <polygon class="rightarm500" points="1770.59 2778.47 1337.58 3028.47 1337.58 2988.47 1770.59 2738.47 1770.59 2778.47" fill="#365593"/>
      <polygon class="toparm top" data-name="toparm top" points="1770.59 2738.47 1337.58 2988.47 1302.93 2968.47 1735.95 2718.47 1770.59 2738.47" fill="#5070af"/>
      <g class="double-arm500" v-if="doubleBranch">
        <polygon class="armholder-right" data-name="armholder-right-10px" points="1814.33 2795.44 1805.67 2800.44 1805.67 2680.44 1814.33 2675.44 1814.33 2795.44" fill="#3d6199"/>
        <polygon class="darm500 rightside" data-name="darm500 rightside" points="1814.35 2753.2 2238.24 2508.47 2238.24 2468.47 1814.33 2713.22 1814.35 2753.2" fill="#365593"/>
        <polygon class="darm500 frontside" data-name="darm500 frontside" points="1814.33 2713.22 1814.34 2673.21 2203.6 2448.47 2238.24 2468.47 1814.33 2713.22" fill="#5070af"/>
      </g>
    </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean,
  },

  name: "Arm500-B.vue",
};
</script>