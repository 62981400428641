<template>
  <g class="strut1500">
    <polygon class="cross-5" data-name="cross" points="1521.28 1928.6 408.93 2345.35 399.77 2315.57 1512.13 1898.82 1521.28 1928.6" fill="#7db0c9"/>
    <polygon class="cross-6" data-name="cross" points="408.21 1263.33 1521.96 2961.57 1512.84 2980.83 399.09 1282.59 408.21 1263.33" fill="#7db0c9"/>
    <ellipse class="centerNut-3" data-name="centerNut" cx="2127.43" cy="1236.32" rx="5.61" ry="10.7" transform="translate(-1422.87 1510.62) rotate(-15.77)" fill="#c7d0d3"/>
    <polygon class="bottomBeam-3" data-name="bottomBeam" points="1526.91 3017.26 394.15 2363.26 394.15 2283.26 1526.91 2937.26 1526.91 3017.26" fill="#88c3e6"/>
    <polygon class="topBeam-3" data-name="topBeam" points="1526.91 1977.26 394.15 1323.26 394.15 1243.26 1526.91 1897.26 1526.91 1977.26" fill="#88c3e6"/>
    <polygon class="strut-top-3" data-name="strut-top" points="1526.91 1897.26 394.15 1243.26 407.52 1235.54 1540.28 1889.54 1526.91 1897.26" fill="#7db0c9"/>
    <polygon class="strut-top-4" data-name="strut-top" points="1526.91 2937.26 394.15 2283.26 407.52 2275.54 1540.28 2929.54 1526.91 2937.26" fill="#7db0c9"/>
  </g>
</template>
<script>
export default {
  name: "Strut1500.vue",
};
</script>