<template>
   <g id="arm1000">
      <g id="armHolder">
        <polygon id="armholderRight-4" points="1720.71 3507.23 1654.89 3545.23 1654.89 3353.23 1720.71 3315.23 1720.71 3507.23" fill="#8b0606"/>
        <polygon id="armHolderFront-3" points="1654.89 3545.23 1526.72 3471.23 1526.72 3279.23 1654.89 3353.23 1654.89 3545.23" fill="#c12727"/>
      </g>
      <g id="singleArm">
        <polygon id="topArm-3" points="1648.88 3412.02 782.85 3912.02 667.67 3845.52 1533.7 3345.52 1648.88 3412.02" fill="#c44d4d"/>
        <polygon id="armRight-3" points="1648.88 3492.02 782.85 3992.02 782.85 3912.02 1648.88 3412.02 1648.88 3492.02" fill="#930c0c"/>
        <polygon id="blackBottomArm-3" points="782.85 3992.02 667.67 3925.52 667.67 3845.52 782.85 3912.02 782.85 3992.02" fill="#100"/>
        <polygon id="innerArm-3" points="667.67 3925.52 737 3885.49 667.67 3845.52 667.67 3925.52" fill="#8b0606"/>
      </g>
      <g id="armDouble" v-if="doubleBranch">
        <polygon id="armholderRight-5" points="1821.17 3449.69 1755.35 3487.69 1755.35 3295.69 1821.17 3257.69 1821.17 3449.69" fill="#8b0606"/>
        <polygon id="rightPath-2" points="1821.17 3392.55 1821.17 3312.87 2681.18 2816.02 2681.18 2896.02 1821.17 3392.55" fill="#930c0c"/>
        <polygon id="topPath-2" points="1821.17 3179.55 1821.17 3312.55 2681.18 2816.02 2566 2749.52 1821.17 3179.55" fill="#c44d4d"/>
      </g>
    </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean,
  },

  name: "Arm1000.vue",
};
</script>
