<template>
  <g class="arrows">
    <g class="newarrow">
      <g class="depth-arrow">

        <line class="arrowline"
            :x1="arrowCoords.x1"
            :y1="arrowCoords.y1"
            :x2="arrowCoords.x2"
            :y2="arrowCoords.y2"
            fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="30"/>


        <text id="lenghtLineInfoText"
            :x="arrowPreviewTextCoords.x"
            :y="arrowPreviewTextCoords.y"
            font-size="300px"
            fill="#000">{{arrowPreviewText}}</text>

          <g class="leftarrow">
            <g>
              <polygon
                  :points="arrowLeftEndPoints"/>
            </g>
          </g>

          <g class="rightarrow">
            <g>
              <polygon
                  :points="arrowRightEndPoints"/>

            </g>
          </g>

        <line class="leftline-arrow"
            :x1="arrowLeftMarkerLineCoords.x1"
            :y1="arrowLeftMarkerLineCoords.y1"
            :x2="arrowLeftMarkerLineCoords.x2"
            :y2="arrowLeftMarkerLineCoords.y2"
            fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"/>
        <line class="righline-arrow"
            :x1="arrowRightMarkerLineCoords.x1"
            :y1="arrowRightMarkerLineCoords.y1"
            :x2="arrowRightMarkerLineCoords.x2"
            :y2="arrowRightMarkerLineCoords.y2"
            fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"/>
      </g>
    </g>
  </g>
</template>
<script>

import {translateHorizontalCoords,translateDepthCoords} from '../../../helper';

export default {
  props: {
    startX: Number,
    startY: Number,
    totalDepth: Number
  },

  computed: {

    arrowCoords: {
      get() {
        let margin = 200;
        let distance = this.totalDepth - (margin*2);

        let transCoords = translateDepthCoords(distance/2);

        let coords = {
          x1: this.startX - transCoords.x,
          x2: this.startX + transCoords.x,
          y1: this.startY - transCoords.y,
          y2: this.startY + transCoords.y
        };

        return coords;
      }
    },

    arrowPreviewTextCoords: {
      get() {

        let horizontalDistance = 500;
        let depthDistance = 250;

        let startX = this.startX;
        let startY = this.startY;

        // Move down a little bit
        let transDepthCoords = translateDepthCoords( depthDistance );

        startX -= transDepthCoords.x;
        startY -= transDepthCoords.y;

        let transCoords = translateHorizontalCoords( horizontalDistance );

        let previewTextCoords = {
          x: startX + transCoords.x,
          y: startY + transCoords.y
        }

        return previewTextCoords;
      }
    },

    arrowPreviewText: {
      get() {
        let totalDepthMeters = (this.totalDepth/1000).toFixed(1).replace(/\./g, ',');
        return totalDepthMeters + " m";
      }
    },

    arrowLeftEndPoints: {
      get() {
        //572.39 1093.98 273.28 1093.98 422.82 834.91 572.39 1093.98
        let arrowCoords = this.arrowCoords;

        let arrowMargin = 180;
        let transCoords = translateHorizontalCoords( arrowMargin );

        let baseX = arrowCoords.x1 + transCoords.x;
        let baseY = arrowCoords.y1 + transCoords.y;

        let points = "";

        points += baseX+" "+baseY;
        points += " "+(baseX-300)+" "+baseY;
        points += " "+(baseX-150)+" "+(baseY-260);
        points += " "+baseX+" "+baseY;

        return points;
      }
    },

    arrowRightEndPoints: {
      get() {
        let arrowCoords = this.arrowCoords;

        let arrowMargin = 180;
        let transCoords = translateHorizontalCoords( arrowMargin );

        let baseX = arrowCoords.x2 - transCoords.x;
        let baseY = arrowCoords.y2 - transCoords.y;

        let points = "";

        points += baseX+" "+baseY;
        points += " "+(baseX+300)+" "+baseY;
        points += " "+(baseX+150)+" "+(baseY+260);
        points += " "+baseX+" "+baseY;

        return points;
      }
    },

    arrowLeftMarkerLineCoords: {
      get() {
        // x1="8.66" y1="923.22" x2="261.11" y2="1359.78"
        let distance = 400;
        let transCoords = translateHorizontalCoords( distance/2 );

        let depthCoords = translateDepthCoords(this.totalDepth/2);

        let baseX = this.startX - depthCoords.x;
        let baseY = this.startY - depthCoords.y;

        let coords = {
          x1: baseX - transCoords.x,
          y1: baseY - transCoords.y,
          x2: baseX + transCoords.x,
          y2: baseY + transCoords.y
        }

        return coords;
      }
    },

    arrowRightMarkerLineCoords: {
      get() {
        // TODO Add logic
        let distance = 400;
        let transCoords = translateHorizontalCoords( distance/2 );

        let depthCoords = translateDepthCoords(this.totalDepth/2);

        let baseX = this.startX + depthCoords.x;
        let baseY = this.startY + depthCoords.y;

        let coords = {
          x1: baseX - transCoords.x,
          y1: baseY - transCoords.y,
          x2: baseX + transCoords.x,
          y2: baseY + transCoords.y
        }

        return coords;
      }
    }
  },

  name: "ArrowDepth.vue",
}
</script>