/**
 *
 * @param distance
 * @returns {{x: number, y: number}}
 */
export function translateDepthCoords( distance )
{
	return translateCoords( distance, -30 );
}

/**
 *
 * @param distance
 * @returns {{x: number, y: number}}
 */
export function translateHorizontalCoords( distance )
{
	return translateCoords(distance, 30);
}

/**
 * Translates the distance so that one can take an existing coordinate and add the x, y value to it
 * to move it across a 2 dimensional direction. If one starts at x1,y1 and wants to draw a line that
 * has a certain length L across an angle A they could create the second point x2,y2 by calling
 * transCoords = translateCoords(L, A) and they can let x2=x1+transCoords.x and y2=y1+transCoords.y.
 *
 * @param distance
 * @param angle
 * @returns {{x: number, y: number}}
 */
export function translateCoords(distance, angle)
{
	let radians = angle * (Math.PI / 180);

	let xTransform = Math.round(Math.cos(radians) * distance);
	let yTransform = Math.round( Math.sin(radians) * distance );

	return {
		x: xTransform,
		y: yTransform
	};
}

/**
 * Shows an error message to the user as the name implies :)
 *
 * @param msg
 */
export function showErrorMessage( msg )
{
	console.log( "showErrorMessage('"+msg+"') started!" );

	alert( msg );
}
