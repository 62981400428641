<template>
    <g class="arm1000">
        <g class="singelarm" data-name="singelarm">
          <polygon class="arm-right-backside" data-name="arm-right-backside" points="952.32 3998.9 779.11 4098.9 779.11 4043.9 952.32 3943.9 952.32 3998.9" fill="#155431"/>
          <polygon class="arm-rightside arm-rightform" data-name="arm-rightside arm-rightform" points="1731.6 3553.96 1731.6 3693.96 1696.95 3713.96 831.08 4128.89 831.08 4073.89 1731.6 3553.96" fill="#19683f"/>
          <polygon class="topside" data-name="topside" points="1697.1 3573.89 831.08 4073.89 779.12 4043.89 1645.14 3543.89 1697.1 3573.89" fill="#1f9456"/>
        </g>
        <g class="doublearm" v-show="doubleBranch" data-name="doublearm">
          <polygon class="topside armtopform" data-name="topside armtopform" points="2762.31 2958.89 2710.35 2928.89 1893.14 3400.71 1893.14 3460.7 2762.31 2958.89" fill="#1f9456"/>
          <polygon class="arm-rightside darm-rightform" data-name="arm-rightside darm-rightform" points="1858.49 3620.7 1858.49 3480.7 2762.31 2958.89 2762.31 3013.89 1893.13 3600.7 1858.49 3620.7" fill="#19683f"/>
        </g>
    </g>
</template>
<script>
export default {
  name: "Arm1000-H.vue",

  computed: {
    doubleBranch: {
      get() {
        return this.$store.state.doubleBranch;
      }
    }
  }
};
</script>