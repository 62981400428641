<template>
  <g class="d-foot3220">
    <g class="fotTopWrapper-10" data-name="fotTopWrapper">
      <polygon class="fotTop-28" data-name="fotTop" points="3174.1 3076.94 376.84 4691.94 210.56 4595.94 3007.82 2980.94 3174.1 3076.94" fill="#a2d2ee"/>
      <polygon class="fotTop-29" data-name="fotTop" points="3132.7 3077.04 377.01 4668.04 251.95 4595.84 3007.65 3004.84 3132.7 3077.04" fill="#64a7d1"/>
      <polygon points="3007.65 3004.84 3007.65 3149.91 3132.7 3077.04 3007.65 3004.84" fill="#88c3e6"/>
    </g>
      <g class="fotRightWrapper-10" data-name="fotRightWrapper">
      <polygon class="fotTop-30" data-name="fotTop" points="3174.1 3268.94 376.84 4883.94 376.84 4691.94 3174.1 3076.94 3174.1 3268.94" fill="#64a7d1"/>
      <g class="bult-10" data-name="bult">
        <polygon class="boltBackground-5" data-name="boltBackground" points="1858.61 4028.44 1692.33 4124.44 1692.33 3932.44 1858.61 3836.44 1858.61 4028.44" fill="#64a7d1"/>
        <g class="boltsWrapper-10" data-name="boltsWrapper">
          <ellipse cx="2983.94" cy="3118.67" rx="12.86" ry="7.42" transform="translate(-2375.78 5029.27) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2983.94" cy="3022.67" rx="12.86" ry="7.42" transform="translate(-2292.64 4981.27) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2900.8" cy="3166.67" rx="12.86" ry="7.42" transform="translate(-2458.92 4981.27) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2900.8" cy="3070.67" rx="12.86" ry="7.42" transform="translate(-2375.78 4933.27) rotate(-60)" fill="#cfcccc"/>
        </g>
      </g>
    </g>
      <polygon class="front-10" data-name="front" points="376.84 4883.94 210.56 4787.94 210.56 4595.94 376.84 4691.94 376.84 4883.94" fill="#88c3e6"/>
    </g>
</template>
<script>
export default {
  name: "FootDouble3230.vue",
};
</script>