<template>
  <g class="strut1500">
        <g class="overstrut"
        v-show="postOpt >= 3500"
         data-name="overstrut">
          <g class="cross" data-name="cross">
            <line class="underline" data-name="underline" x1="459.77" y1="1618.9" x2="1693.94" y2="999.58" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" data-name="overline" x1="1697.09" y1="2317.05" x2="456.26" y2="307.76" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside-10" data-name="frontside" points="1732.04 1078.35 433 328.35 433 268.35 1732.04 1018.35 1732.04 1078.35" fill="#1b7742"/>
            <polygon class="topside-14" data-name="topside" points="1732.04 1018.35 433 268.35 467.64 248.35 1766.68 998.35 1732.04 1018.35" fill="#1f9456"/>
          </g>
        </g>
        <g class="understrut" data-name="understrut">
          <g class="cross" data-name="cross">
            <line class="underline" data-name="underline" x1="459.78" y1="2928.89" x2="1693.95" y2="2309.58" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" data-name="overline" x1="1697.1" y1="3627.04" x2="456.27" y2="1617.75" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.06 3698.34 433.02 2948.34 433.02 2888.34 1732.06 3638.34 1732.06 3698.34" fill="#1b7742"/>
            <polygon class="topside" data-name="topside" points="1732.06 3638.34 433.02 2888.34 467.66 2868.34 1766.7 3618.34 1732.06 3638.34" fill="#1f9456"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.05 2388.34 433.01 1638.34 433.01 1578.34 1732.05 2328.34 1732.05 2388.34" fill="#1b7742"/>
            <polygon class="topside-" data-name="topside" points="1732.05 2328.34 433.01 1578.34 467.65 1558.34 1766.69 2308.34 1732.05 2328.34" fill="#1f9456"/>
          </g>
        </g>
  </g>
</template>
<script>
export default {
  name: "Strut1500-H.vue",

  computed: {
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
  }
};
</script>