<template>
  <g id="arrowHeight">
    <polygon id="arrowHeightLowerPointer" :points="arrowLowerEndPoints"/>

    <line id="arrowHeightLine"
        :x1="arrowCoords.x1" :y1="arrowCoords.y1" :x2="arrowCoords.x2" :y2="arrowCoords.y2"
        fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="50"/>

    <text id="arrowHeightLineInfoText"
        :x="arrowPreviewTextCoords.x"
        :y="arrowPreviewTextCoords.y"
        font-size="300px"
        fill="black">{{arrowPreviewText}}</text>

    <polygon id="arrowHeightUpperPointer" :points="arrowHigherEndPoints"/>

    <line class="downline-arrow"
        :x1="arrowBottomMarkerLineCoords.x1"
        :y1="arrowBottomMarkerLineCoords.y1"
        :x2="arrowBottomMarkerLineCoords.x2"
        :y2="arrowBottomMarkerLineCoords.y2"
        fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"/>
    <line class="topline-arrow"
        :x1="arrowTopMarkerLinerCoords.x1"
        :y1="arrowTopMarkerLinerCoords.y1"
        :x2="arrowTopMarkerLinerCoords.x2"
        :y2="arrowTopMarkerLinerCoords.y2"
        fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="20"/>
  </g>
</template>
<script>
export default {
  props: {
    startX: Number,
    startY: Number,
    totalHeight: Number
  },

  computed: {

    arrowCoords: {
      get() {
        let margin = 150;

        let startY = this.startY - margin;
        let endY = (this.startY - this.totalHeight) + (margin);

        return {
          x1: this.startX,
          x2: this.startX,
          y1: startY,
          y2: endY
        };
      }
    },

    arrowPreviewTextCoords: {
      get() {
        let totalHeightMillis = this.totalHeight;
        let arrowCoords = this.arrowCoords;

        let startX = arrowCoords.x1;
        let startY = arrowCoords.y1;

        let arrowMargin = 250;

        let previewTextX = startX + arrowMargin;
        let previewTextY = startY - Math.round( totalHeightMillis / 2 ) - 50 + arrowMargin;

        return {
          x: previewTextX,
          y: previewTextY
        };
      }
    },

    arrowPreviewText: {
      get() {
        let totalHeightMillis = this.totalHeight;
        let totalHeightMeters = (totalHeightMillis/1000).toFixed(1).replace(/\./g, ',');
        let previewText = totalHeightMeters + " m";

        return previewText;
      }
    },

    arrowLowerEndPoints: {
      get() {
        let coords = this.arrowCoords;
        let arrowMargin = 150;

        let baseX = this.startX + arrowMargin;
        let baseY = coords.y1 - arrowMargin;

        let points = "";
        points += baseX+" "+baseY;
        points += " "+(baseX-150)+" "+(baseY+260);
        points += " "+(baseX-300)+" "+(baseY) ;
        points += " "+baseX+" "+baseY;

        return points;
      }
    },

    arrowHigherEndPoints: {
      get() {
        let arrowMargin = 150;

        let baseX = this.startX - arrowMargin;
        let baseY = (this.startY-this.totalHeight) + (arrowMargin*2);

        let points = "";
        points += baseX+" "+baseY;
        points += " "+(baseX+150)+" "+(baseY-260);
        points += " "+(baseX+300)+" "+(baseY) ;
        points += " "+baseX+" "+baseY;

        return points;
      }
    },

    arrowBottomMarkerLineCoords: {
      get() {
        let distance = 400;

        let baseX = this.startX;
        let baseY = this.startY;

        let coords = {
          x1: baseX-(distance/2),
          y1: baseY,
          x2: baseX+(distance/2),
          y2: baseY
        }

        return coords;
      }
    },

    arrowTopMarkerLinerCoords: {
      get() {
        let distance = 400;

        let arrowCoords = this.arrowCoords;

        let baseX = arrowCoords.x2;
        let baseY = (this.startY-this.totalHeight);

        let coords = {
          x1: baseX-(distance/2),
          y1: baseY,
          x2: baseX+(distance/2),
          y2: baseY
        }

        return coords;
      }
    },

  },

  name: "ArrowHeight.vue",
};
</script>