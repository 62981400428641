<template>

  <svg class="brucit-layers wrapperSvgNeg30 marginstrutMax2000" xmlns="http://www.w3.org/2000/svg" width="2346.94" height="3432.5" viewBox="200 -600 13000 10000">
    <!--orginal viewBox="0 0 2346.94 3432.5"-->

    <g
        v-for="postInfo in branchStandPosInfo"
        :key="postInfo.index"
        class="rightGroup postStandSector"
        v-bind:style="postInfo.style"
    >
      <!-- Manages first upright protector -->
      <template
          v-if="includeUprightProtector && postInfo.index === 0">
        <BrucitSignalLeft
        />
      </template>

      <!-- Manages stag/strut-->
      <template  v-if="postInfo.index > 0">
        <Strut1000 v-show="selectedStagSection.width == 1000" />
        <Strut1250 v-show="selectedStagSection.width == 1250" />
        <Strut1500 v-show="selectedStagSection.width == 1500" />
        <Strut2000 v-show="selectedStagSection.width == 2000" />
      </template>

      <!-- Manages the posts foot -->
      <template v-if="doubleBranch">
        <Foot1300 />
      </template>
      <template v-else>
        <Foot685 />
      </template>

      <!-- Manages the Post stand -->
      <template>
        <Post2000 v-show="postOpt == 2000" />
        <Post2500 v-show="postOpt == 2500" />
        <Post3000 v-show="postOpt == 3000" />
      </template>

      <!-- Manages the arm -->
      <template v-if="selectedArm.length == 400">
        <Arm400
            v-for="armPos in armPosInfo"
            :key="armPos.index"
            :doubleBranch="doubleBranch"
            v-bind:style ="armPos.stylePos"
        />
      </template>

      <template v-if="selectedArm.length == 500">
        <Arm500
            v-for="armPos in armPosInfo"
            :key="armPos.index"
            :doubleBranch="doubleBranch"
            v-bind:style ="armPos.stylePos"
        />
      </template>

      <template v-if="selectedArm.length == 600">
        <Arm600
            v-for="armPos in armPosInfo"
            :key="armPos.index"
            :doubleBranch="doubleBranch"
            v-bind:style ="armPos.stylePos"
        />
      </template>

      <!-- Manages second upright protector -->
      <template
          v-bind:style="uprightProtectorPosStyle"
          v-if="includeUprightProtector && ((postInfo.index+1) === branchStandPosInfo.length)">
        <BrucitSignalRight/>
      </template>
    </g>

    <ArrowLength
        :startX="arrowLengthPosBaseCoords.x"
        :startY="arrowLengthPosBaseCoords.y"
        :totalLength="totalLength"
    />

    <ArrowDepth
        :startX="arrowDepthPosBaseCoords.x"
        :startY="arrowDepthPosBaseCoords.y"
        :totalDepth="totalDepth"
    />

    <ArrowHeight
        :startX="arrowHeightPosBaseCoords.x"
        :startY="arrowHeightPosBaseCoords.y"
        :totalHeight="totalHeight"
    />
  </svg>

</template>
<script>

import Arm400 from "@/components/svg-parts/brucit/arms/Arm400-B.vue";
import Arm500 from "@/components/svg-parts/brucit/arms/Arm500-B.vue";
import Arm600 from "@/components/svg-parts/brucit/arms/Arm600-B.vue";

import Post2000 from "@/components/svg-parts/brucit/posts/Post2000-B.vue";
import Post2500 from "@/components/svg-parts/brucit/posts/Post2500-B.vue";
import Post3000 from "@/components/svg-parts/brucit/posts/Post3000-B.vue";

import Foot685 from "@/components/svg-parts/brucit/foots/Foot685-B.vue";
import Foot1300 from "@/components/svg-parts/brucit/foots/Foot1300-B.vue";

import Strut1000 from "@/components/svg-parts/brucit/struts/Strut1000-B.vue";
import Strut1250 from "@/components/svg-parts/brucit/struts/Strut1250-B.vue";
import Strut1500 from "@/components/svg-parts/brucit/struts/Strut1500-B.vue";
import Strut2000 from "@/components/svg-parts/brucit/struts/Strut2000-B.vue";

// Arrows
import ArrowHeight from "@/components/svg-parts/misc/ArrowHeight.vue";
import ArrowLength from "@/components/svg-parts/misc/ArrowLength.vue";
import ArrowDepth from "@/components/svg-parts/misc/ArrowDepth.vue";

// post protection
import BrucitSignalRight from "@/components/svg-parts/post-protection/BrucitSignalRight.vue";
import BrucitSignalLeft from "@/components/svg-parts/post-protection/BrucitSignalLeft.vue";
import {mapGetters} from "vuex";

export default {
  name: "BrucitCombinedParts.vue",
  components: {
    Arm400,
    Arm500,
    Arm600,

    Post2000,
    Post2500,
    Post3000,

    Foot685,
    Foot1300,

    Strut1000,
    Strut1250,
    Strut1500,
    Strut2000,
       // eslint-disable-next-line vue/no-unused-components
    BrucitSignalRight,
    // eslint-disable-next-line vue/no-unused-components
    BrucitSignalLeft,

    ArrowHeight,
    ArrowLength,
    ArrowDepth
  },

  computed: {
    selectedStagSection: {
      get() {
        return this.$store.state.selectedStagSection;
      },
    },
    selectedArm: {
      get() {
        return this.$store.state.selectedCarryingArm;
      }
    },

    selectedArmPlanes: {
      get() {
        return this.$store.state.selectedArmPlanes;
      }
    },
    doubleBranch: {
      get() {
        return this.$store.state.doubleBranch;
      }
    },
    totalHeight: {
      get() {
        return this.$store.state.totalHeight;
      }
    },
    totalLength: {
      get() {
        return this.$store.state.totalLength;
      }
    },
    totalDepth: {
      get() {
        return this.$store.state.totalDepth;
      }
    },
    includeUprightProtector: {
      get() {
        return this.$store.state.includeUprightProtectors;
      }
    },
    uprightProtectorPosStyle: {
      get() {
        let totalHeight = this.totalHeight;
        console.log("uprightProtectorPosStyle() totalHeight: " + totalHeight);
        return "translate: transform(0, "+totalHeight+" px)";
      }
    },
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
    footLength: {
      get() {
        return this.$store.state.postFootLength;
      }
    },
    ...mapGetters([
      "availableHeights",
      "armPosInfo",
      "selectedPostStand",
      "countBranchStands",
      "branchStandPosInfo",
      "arrowHeightPosBaseCoords",
      "arrowLengthPosBaseCoords",
      "arrowDepthPosBaseCoords"
    ]),
  }
};
</script>

<style scoped>

</style>
