<template>
   <g id="arm1500">
      <g id="armHolder">
        <polygon id="armholderRight-2" points="1720.71 3507.23 1654.9 3545.23 1654.9 3353.23 1720.71 3315.23 1720.71 3507.23" fill="#8b0606"/>
        <polygon id="armHolderFront-2" points="1654.9 3545.23 1526.72 3471.23 1526.72 3279.23 1654.9 3353.23 1654.9 3545.23" fill="#c12727"/>
      </g>
      <g id="singleArm">
        <polygon id="topArm-2" points="1648.86 3413.78 349.82 4163.78 234.64 4097.28 1533.68 3347.28 1648.86 3413.78" fill="#c44d4d"/>
        <polygon id="armRight-2" points="1648.86 3493.77 349.82 4243.77 349.82 4163.77 1648.86 3413.77 1648.86 3493.77" fill="#930c0c"/>
        <polygon id="blackBottomArm-2" points="349.82 4243.78 234.64 4177.28 234.64 4097.28 349.82 4163.78 349.82 4243.78" fill="#100"/>
        <polygon id="innerArm-2" points="234.64 4177.28 303.96 4137.24 234.64 4097.28 234.64 4177.28" fill="#8b0606"/>
      </g>
      <g id="armDouble" v-if="doubleBranch">
        <polygon id="armholderRight-3" points="1821.17 3449.93 1755.35 3487.93 1755.35 3295.93 1821.17 3257.93 1821.17 3449.93" fill="#8b0606"/>
        <polygon id="rightPath" points="1821.17 3394.29 1821.17 3314.24 3114.18 2567.77 3114.18 2647.77 1821.17 3394.29" fill="#930c0c"/>
        <polygon id="topPath" points="1821.17 3181.29 2998.99 2501.27 3114.18 2567.77 1821.17 3314.29 1821.17 3181.29" fill="#c44d4d"/>
      </g>
    </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean,
  },

  name: "Arm1500.vue",
};
</script>