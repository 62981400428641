<template>
  <g class="foot1230">
    <g class="fotRightWrapper-7" data-name="fotRightWrapper">
      <polygon class="fotTop-19" data-name="fotTop" points="1862.56 4025.91 797.34 4640.91 797.34 4448.91 1862.56 3833.91 1862.56 4025.91" fill="#64a7d1"/>
      <g class="bult-7" data-name="bult">
        <polygon class="boltBackground-2" data-name="boltBackground" points="1862.56 4025.91 1696.28 4121.91 1696.28 3929.91 1862.56 3833.91 1862.56 4025.91" fill="#64a7d1"/>
        <g class="boltsWrapper-7" data-name="boltsWrapper">
          <ellipse cx="2987.89" cy="3116.15" rx="12.86" ry="7.42" transform="translate(-2371.62 5031.43) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2987.89" cy="3020.15" rx="12.86" ry="7.42" transform="translate(-2288.48 4983.43) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2904.75" cy="3164.15" rx="12.86" ry="7.42" transform="translate(-2454.76 4983.43) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2904.75" cy="3068.15" rx="12.86" ry="7.42" transform="translate(-2371.62 4935.43) rotate(-60)" fill="#cfcccc"/>
        </g>
      </g>
    </g>
      <polygon class="front-7" data-name="front" points="797.35 4640.91 631.07 4544.91 631.07 4352.91 797.35 4448.91 797.35 4640.91" fill="#88c3e6"/>
    <g class="fotTopWrapper-7" data-name="fotTopWrapper">
      <polygon class="fotTop-20" data-name="fotTop" points="1862.56 3833.92 797.35 4448.92 631.07 4352.92 1696.29 3737.92 1862.56 3833.92" fill="#a2d2ee"/>
      <polygon class="fotTop-21" data-name="fotTop" points="1821.17 3834.02 797.53 4425.02 672.47 4352.81 1696.11 3761.82 1821.17 3834.02" fill="#64a7d1"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "Foot1230.vue",
};
</script>