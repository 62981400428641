<template>
  <g class="d-foot2230">
    <g class="fotRightWrapper-9" data-name="fotRightWrapper">
      <polygon class="fotTop-25" data-name="fotTop" points="2741.09 3518.94 809.85 4633.94 809.85 4441.94 2741.09 3326.94 2741.09 3518.94" fill="#64a7d1"/>
      <g class="bult-9" data-name="bult">
        <polygon class="boltBackground-4" data-name="boltBackground" points="1858.61 4028.44 1692.33 4124.44 1692.33 3932.44 1858.61 3836.44 1858.61 4028.44" fill="#64a7d1"/>
        <g class="boltsWrapper-9" data-name="boltsWrapper">
          <ellipse cx="2983.94" cy="3118.67" rx="12.86" ry="7.42" transform="translate(-2375.78 5029.27) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2983.94" cy="3022.67" rx="12.86" ry="7.42" transform="translate(-2292.65 4981.27) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2900.8" cy="3166.67" rx="12.86" ry="7.42" transform="translate(-2458.92 4981.27) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2900.8" cy="3070.67" rx="12.86" ry="7.42" transform="translate(-2375.78 4933.27) rotate(-60)" fill="#cfcccc"/>
        </g>
      </g>
    </g>
    <g class="fotTopWrapper-9" data-name="fotTopWrapper">
      <polygon class="fotTop-26" data-name="fotTop" points="2741.09 3326.94 809.85 4441.94 643.57 4345.94 2574.81 3230.94 2741.09 3326.94" fill="#a2d2ee"/>
      <polygon class="fotTop-27" data-name="fotTop" points="2699.69 3327.04 810.03 4418.04 684.97 4345.84 2574.64 3254.84 2699.69 3327.04" fill="#64a7d1"/>
      <polygon points="2574.64 3254.84 2574.64 3399.91 2699.69 3327.04 2574.64 3254.84" fill="#88c3e6"/>
    </g>
      <polygon class="front-9" data-name="front" points="809.85 4633.94 643.57 4537.94 643.57 4345.94 809.85 4441.94 809.85 4633.94" fill="#88c3e6"/>
  </g>
</template>
<script>
export default {
  name: "FootDouble2230.vue",
};
</script>