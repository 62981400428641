<template>
  <g class="foot-svg-wrapper">
    <g class="foot1300">
      <polygon class="rightside" points="2346.94 2782.49 1221.1 3432.49 1221.1 3372.49 2346.94 2722.49 2346.94 2782.49" fill="#365593"/>
      <polygon class="top" points="2346.94 2722.5 1221.11 3372.5 1169.14 3342.5 2294.98 2692.5 2346.94 2722.5" fill="#5070af"/>
      <g class="footfront">
        <polygon class="top" data-name="top" points="1221.11 3432.5 1169.14 3402.5 1169.14 3342.5 1221.11 3372.5 1221.11 3432.5" fill="#5070af"/>
        <polygon class="rightside" data-name="rightside" points="1169.14 3342.5 1169.14 3402.5 1221.11 3372.5 1169.14 3342.5" fill="#365593"/>
      </g>
      <polygon class="fotbuild-back rightside" data-name="fotbuild-back rightside" points="1840.33 3015 1814.34 2999.98 1814.33 3029.99 1840.33 3015" fill="#365593"/>
    </g>
    <g class="fotbuild">
      <polygon class="rightside" data-name="rightside" points="1814.34 3029.99 1779.69 3049.99 1779.69 3019.99 1814.34 2999.99 1814.34 3029.99" fill="#365593"/>
      <polygon class="rightside" data-name="rightside" points="1753.73 3065 1779.71 3050 1779.69 3020 1753.73 3065" fill="#365593"/>
      <polyline class="angle" points="1753.73 3065 1701.76 3035 1727.73 2990 1779.69 3020 1753.73 3065" fill="#6383b7"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "Foot1300-B.vue",
};
</script>