<template>
  <div class="sidebar">
    <div class="sideContainer">
      <div class="logoHolder">
        <img
          src="../assets/logo-kalls.svg"
          alt="Logotyp Källs"
        >
        <span id="logoUnderText">grenställsbyggare</span>
      </div>

      <div class="wrapperSidebarContent">
        <!-- BEGIN Brand Control -->
        <div class="typeOfGrenstallHolder">
          <b-field label="Välj grenställsmodell">
            <b-radio
              v-for="brandOpt in availableBrands"
              v-model="selectedBrand"
              :native-value="brandOpt"
              :key="brandOpt"
            >
              {{ brandOpt }}
            </b-radio>
          </b-field>
        </div>

        <div class="sideHolder holder backgroundPlate">
          <b-field label="Enkel eller dubbelsidigt">
            <b-radio
              v-model="doubleSidedBranchStand"
              :native-value="false"
              :disabled="!selectedBrand"
            >
              Enkelställ
            </b-radio>
            <b-radio
              v-model="doubleSidedBranchStand"
              :native-value="true"
              :disabled="!selectedBrand"
            >
              Dubbelställ
            </b-radio>

          </b-field>
        </div>

        <div class="lenghtHolder holder labelcontroller">
          <b-field
            label="Önskad längd, (meter)"
            class="textControll labelcontroller"
          >
            <div
              class="desired-length-slider slidergap"
              style="flex-direction: row; display: flex;"
            >
              <b-slider
                v-model="desiredSectionLength"
                size="is-large"
                :min="minAllowedSectionLength"
                :max="maxAllowedSectionLength"
                :disabled="!selectedBrand"
                :step="1"
                :tooltip="false"
                style="width: 100%"
              />
              <b-input
                v-model="desiredSectionLength"
                class="textControll"
                style="width: 4em; margin-left: 1em"
                readonly="true"
                numeric
              />
            </div>
          </b-field>
        </div>
        <div class="armHolder holder backgroundPlate">
          <b-field label="Bärarm - (vikt / längd)">

            <b-radio
              class="armRadio"
              v-for="armOpt in armOptions"
              :native-value="armOpt"
              :key="armOpt.capacity + armOpt.length"
              v-model="selectedArm"
            >
              {{ armOpt.capacity }} kg / {{ armOpt.length }} mm
            </b-radio>
          </b-field>
        </div>

        <b-field
          label="Antal armplan"
          class="textControll labelcontroller armLevels backgroundPlate"
        >
          <div
            class="arm-plane-slider slidergap"
            style="flex-direction: row; display: flex;"
          >
            <b-slider
              v-model="selectedArmPlanes"
              size="is-large"
              :min="minAllowedArmPlanes"
              :max="maxAllowedArmPlanes"
              :disabled="!selectedBrand"
              :step="1"
              :tooltip="false"
              style="width: 100%"
            />

            <b-input
              v-model="selectedArmPlanes"
              class="textControll"
              style="width: 4em; margin-left: 1em"
              readonly="true"
              numeric
            />
          </div>
        </b-field>

        <b-field
          id="select-stag-section"
          label="cc stolpe / staglängd"
          class="textControll holder labelcontroller"
        >
          <div
            class="arm-plane-slider slidergap"
            style="flex-direction: row; display: flex;"
          >
            <b-slider
              class="customslider slidertick"
              v-model="selectedStagIndex"
              :disabled="!selectedBrand"
              size="is-large"
              :min="0"
              :max="stagSectionOptions.length-1"
              aria-label="cc stolpe / staglängd"
              :tooltip="false"
            >

              <b-slider-tick
                class="b-sliderShowNot"
                v-for="stagOpt in stagSectionOptions"
                :value="stagOpt.index"
                :key="stagOpt.index"
              >
                {{ stagOpt.name }}
              </b-slider-tick>

            </b-slider>
            <div class="displayValueBox">
              <span v-if="selectedStagSection !== null">{{selectedStagSection.name}}</span>
            </div>
            <b-input
              v-model="selectedStagIndex"
              class="textControll inputsliderbox"
              style="width: 4em; margin-left: 1em"
              readonly="true"
              numeric
            />

          </div>
        </b-field>

        <div class="displaytotalLength displayValue holder">
          <p>Faktisk total längd:<span class="fatSpan"> {{totalLength / 1000}} meter</span></p>
        </div>
        <div class="showValueBox holder">
          <p>Faktisk total längd:</p>
          <span class="fatSpan valueBoxFat"> {{(totalLength/1000).toFixed(2).replace(/\./g, ',')}} meter</span>
        </div>

        <b-field
          id="select-post-height"
          label="Stolphöjd"
          class="textControll holder backgroundPlate"
        >
          <b-slider
            class="slidertick slidergapTick"
            v-model="selectedPostOptIndex"
            :disabled="!selectedBrand"
            size="is-large"
            :min="0"
            :max="postHeightOptions.length-1"
            aria-label="Stolphöjd"
            :tooltip="false"
          >
            <b-slider-tick
              v-for="postOpt in postHeightOptions"
              :key="postOpt.index"
              :value="postOpt.index"
            >
              {{ postOpt.name }}
            </b-slider-tick>
          </b-slider>
        </b-field>

        <div class="displaytotalLength displayValue holder">
          <p>Total maxvikt per sida:<span class="fatSpan"> {{totalCapacityPerSide}} kg</span></p>
        </div>

        <div class="optionalHolder holder">
          <b-field label="Tillval">
            <div class="protectionWrapper">
              <b-checkbox
                class="checkboxModals"
                v-model="includeUprightProtector"
                :disabled="!selectedBrand"
              >
                <div class="divspan">
                  <span>Stolpskydd x </span>
                  <span v-show="doubleSidedBranchStand">4</span>
                  <span v-show="!doubleSidedBranchStand">2</span>
                  <span> st </span>
                </div>
                <button
                  class="svgInfoBtn"
                  @click="toggleInfoModal('stolpskydd-signal')"
                >
                  <SvgDropDown />
                </button>
              </b-checkbox>

              <b-field class="bField">
                <b-collapse
                  id="collapseStolpskydd"
                  aria-id="collapseStolpskydd"
                  class="mobileInfoText"
                  animation="slide"
                  v-show="openStolpskyddInfo"
                >
                  <div class="collapseContent">
                    <StolpskyddSignal />
                  </div>
                </b-collapse>
              </b-field>
            </div>
            <div
              class="endstopWrapper"
              v-if="hasEndStop"
            >
              <b-checkbox
              class="checkboxModals"
                v-model="includeEndStop"
                :disabled="!selectedBrand || !hasEndStop"
              >
                <div class="divspan">
                  <span>Ändstopp x </span>
                  <span>{{quantityEndStop}}</span>
                  <span> st </span>
                </div>
                <button
                  class="svgInfoBtn"
                  @click="toggleInfoModal('andstopp')"
                >
                  <SvgDropDown />
                </button>
              </b-checkbox>
              <b-field class="bField">
                <b-collapse
                  id="collapseAndstopp"
                  aria-id="collapseAndstopp"
                  class="mobileInfoText"
                  animation="slide"
                  v-show="openAndstoppInfo"
                >
                  <div class="collapseContent">
                    <Andstopp />
                  </div>
                </b-collapse>
              </b-field>
            </div>
          </b-field>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapGetters } from "vuex";
import SvgDropDown from "@/components/svg-parts/SvgDropDown.vue";
import StolpskyddSignal from "./small-components/StolpskyddSignal.vue";
import Andstopp from "./small-components/Andstopp.vue";

export default {
  name: "Sidebar",
  components: {
    SvgDropDown,
    StolpskyddSignal,
    Andstopp
  },

  data() {
    return {
      openStolpskyddInfo: false,
      openAndstoppInfo: false,
    };
  },

  computed: {
    quantityEndStop: {
        get() {
          let armPlaneLevels = this.$store.state.dataArmPlanes;
          let branchCount = this.countBranchStands;
          let doubleBranch = this.$store.state.doubleBranch;
          let armQty = armPlaneLevels * branchCount *  ( doubleBranch ? 2 : 1 );
          return armQty;
        }
      
    },

    selectedBrand: {
      get() {
        return this.$store.state.selectedBrand;
      },
      set(value) {
        this.$store.commit("selectBrand", value);
      },
    },

    doubleSidedBranchStand: {
      get() {
        return this.$store.state.doubleBranch;
      },
      set(value) {
        this.$store.commit("setDoubleBranch", value);
      },
    },

    desiredSectionLength: {
      get() {
        return this.$store.state.desiredSectionLength;
      },
      set(value) {
        this.$store.commit("setDesiredSectionLength", value);
      },
    },

    minAllowedSectionLength: {
      get() {
        return this.$store.state.minAllowedSectionLength;
      },
    },

    maxAllowedSectionLength: {
      get() {
        return this.$store.state.maxAllowedSectionLength;
      },
    },

    selectedArm: {
      get() {
        return this.$store.state.selectedCarryingArm;
      },
      set(value) {
        this.$store.commit("setCarryingArm", value);
      },
    },

    selectedArmPlanes: {
      get() {
        return this.$store.state.dataArmPlanes;
      },
      set(value) {
        this.$store.commit("setArmPlanes", value);
      },
    },

    minAllowedArmPlanes: {
      get() {
        return this.$store.state.minAllowedArmPlanes;
      },
    },

    maxAllowedArmPlanes: {
      get() {
        return this.$store.state.maxAllowedArmPlanes;
      },
    },

    selectedStagSection: {
      get() {
        return this.$store.state.selectedStagSection;
      },
      set(index) {
        this.$store.commit("selectedStagSection", index);
      },
    },

    selectedStagIndex: {
      get() {
        return this.$store.state.selectedStagIndex;
      },
      set(value) {
        this.$store.commit("selectedStagSection", value);
      },
    },

    selectedPostOptIndex: {
      get() {
        return this.$store.state.selectedPostOptIndex;
      },
      set(index) {
        this.$store.commit("setSelectedPostOpt", index);
      },
    },

    selectedPostCapacity: {
      get() {
        return this.$store.state.selectedPostCapacity;
      },
    },

    includeUprightProtector: {
      get() {
        return this.$store.state.includeUprightProtectors;
      },
      set(value) {
        this.$store.commit("setUprightProtectors", value);
      },
    },

    includeEndStop: {
      get() {
        return this.$store.state.includeEndStop;
      },
      set(value) {
        this.$store.commit("setIncludeEndStop", value);
      },
    },

    totalLength: {
      get() {
        return this.$store.state.totalLength;
      },
    },

    totalCapacityPerSide: {
      get() {
        return this.$store.state.totalCapacityPerSide;
      },
    },

    ...mapGetters([
      "availableBrands",
      "armOptions",
      "stagSectionOptions",
      "totalLength",
      "postHeightOptions",
      "hasEndStop",
      "countBranchStands",
    ]),
  },
  methods: {
    toggleInfoModal(element) {
      switch (element) {
        case "stolpskydd-signal":
          this.openStolpskyddInfo = !this.openStolpskyddInfo;
          break;
        case "andstopp":
          this.openAndstoppInfo = !this.openAndstoppInfo;
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
