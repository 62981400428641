<template>
<g class="post2964">
      <g class="topPost">
        <g class="topPostRotate">
          <polygon class="top-post-inner" points="1654.89 1158 1526.72 1084 1692.99 988 1821.16 1062 1654.89 1158" fill="#a2d2ee"/>
          <polygon class="top-post-outer" points="1653.16 1135 1566.55 1085 1694.72 1011 1781.33 1061 1653.16 1135" fill="#64a7d1"/>
        </g>
        <polygon class="topPost-front" points="1694.72 1011 1694.72 1111 1781.33 1061 1694.72 1011" fill="#88c3e6"/>
      </g>
      <g class="frontParts">
        <polygon class="frontTriangle" points="1526.72 3856.07 1526.72 4004.51 1654.89 3929.64 1526.72 3856.07" fill="#88c3e6"/>
        <polygon class="frontPost" points="1654.89 3930.14 1526.72 3856.14 1526.72 1084.14 1654.89 1158.14 1654.89 3930.14" fill="#88c3e6"/>
      </g>
      <g class="rightSide">
        <polygon class="rightSideBottom" points="1821.16 3834 1654.89 3930 1654.89 3530 1821.16 3434 1821.16 3834" fill="#64a7d1"/>
        <g class="holeSection">
          <g>
            <polygon points="1821.16 3434 1654.89 3530 1654.89 3458.12 1821.16 3362.12 1821.16 3434" fill="#64a7d1"/>
            <polygon points="1699.92 3500.22 1675.7 3514.2 1675.67 3488.35 1699.9 3474.38 1699.92 3500.22" fill="#010015"/>
            <polygon points="1800.36 3442.22 1776.13 3456.22 1776.16 3430.35 1800.38 3416.35 1800.36 3442.22" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 3362.12 1654.89 3458.12 1654.89 3386.24 1821.16 3290.24 1821.16 3362.12" fill="#64a7d1"/>
            <polygon points="1699.92 3428.34 1675.7 3442.32 1675.67 3416.47 1699.9 3402.5 1699.92 3428.34" fill="#010015"/>
            <polygon points="1800.36 3370.34 1776.13 3384.34 1776.16 3358.47 1800.38 3344.47 1800.36 3370.34" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 3290.24 1654.89 3386.24 1654.89 3314.36 1821.16 3218.36 1821.16 3290.24" fill="#64a7d1"/>
            <polygon points="1699.92 3356.47 1675.7 3370.44 1675.67 3344.59 1699.9 3330.62 1699.92 3356.47" fill="#010015"/>
            <polygon points="1800.36 3298.47 1776.13 3312.47 1776.16 3286.59 1800.38 3272.59 1800.36 3298.47" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 3218.36 1654.89 3314.36 1654.89 3242.49 1821.16 3146.49 1821.16 3218.36" fill="#64a7d1"/>
            <polygon points="1699.92 3284.59 1675.7 3298.56 1675.67 3272.71 1699.9 3258.74 1699.92 3284.59" fill="#010015"/>
            <polygon points="1800.36 3226.59 1776.13 3240.59 1776.16 3214.71 1800.38 3200.71 1800.36 3226.59" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 3146.49 1654.89 3242.49 1654.89 3170.61 1821.16 3074.61 1821.16 3146.49" fill="#64a7d1"/>
            <polygon points="1699.92 3212.71 1675.7 3226.68 1675.67 3200.83 1699.9 3186.86 1699.92 3212.71" fill="#010015"/>
            <polygon points="1800.36 3154.71 1776.13 3168.71 1776.16 3142.83 1800.38 3128.83 1800.36 3154.71" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 3074.61 1654.89 3170.61 1654.89 3098.73 1821.16 3002.73 1821.16 3074.61" fill="#64a7d1"/>
            <polygon points="1699.92 3140.83 1675.7 3154.8 1675.67 3128.95 1699.9 3114.98 1699.92 3140.83" fill="#010015"/>
            <polygon points="1800.36 3082.83 1776.13 3096.83 1776.16 3070.95 1800.38 3056.95 1800.36 3082.83" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 3002.73 1654.89 3098.73 1654.89 3026.85 1821.16 2930.85 1821.16 3002.73" fill="#64a7d1"/>
            <polygon points="1699.92 3068.95 1675.7 3082.92 1675.67 3057.07 1699.9 3043.1 1699.92 3068.95" fill="#010015"/>
            <polygon points="1800.36 3010.95 1776.13 3024.95 1776.16 2999.07 1800.38 2985.07 1800.36 3010.95" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2930.85 1654.89 3026.85 1654.89 2954.97 1821.16 2858.97 1821.16 2930.85" fill="#64a7d1"/>
            <polygon points="1699.92 2997.07 1675.7 3011.04 1675.67 2985.2 1699.9 2971.22 1699.92 2997.07" fill="#010015"/>
            <polygon points="1800.36 2939.07 1776.13 2953.07 1776.16 2927.2 1800.38 2913.2 1800.36 2939.07" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2858.97 1654.89 2954.97 1654.89 2883.09 1821.16 2787.09 1821.16 2858.97" fill="#64a7d1"/>
            <polygon points="1699.92 2925.19 1675.7 2939.16 1675.67 2913.32 1699.9 2899.34 1699.92 2925.19" fill="#010015"/>
            <polygon points="1800.36 2867.19 1776.13 2881.19 1776.16 2855.32 1800.38 2841.32 1800.36 2867.19" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2787.09 1654.89 2883.09 1654.89 2811.21 1821.16 2715.21 1821.16 2787.09" fill="#64a7d1"/>
            <polygon points="1699.92 2853.31 1675.7 2867.29 1675.67 2841.44 1699.9 2827.47 1699.92 2853.31" fill="#010015"/>
            <polygon points="1800.36 2795.31 1776.13 2809.31 1776.16 2783.44 1800.38 2769.44 1800.36 2795.31" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2715.21 1654.89 2811.21 1654.89 2739.34 1821.16 2643.34 1821.16 2715.21" fill="#64a7d1"/>
            <polygon points="1699.92 2781.44 1675.7 2795.41 1675.67 2769.56 1699.9 2755.59 1699.92 2781.44" fill="#010015"/>
            <polygon points="1800.36 2723.43 1776.13 2737.44 1776.16 2711.56 1800.38 2697.56 1800.36 2723.43" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2643.34 1654.89 2739.34 1654.89 2667.46 1821.16 2571.46 1821.16 2643.34" fill="#64a7d1"/>
            <polygon points="1699.92 2709.56 1675.7 2723.53 1675.67 2697.68 1699.9 2683.71 1699.92 2709.56" fill="#010015"/>
            <polygon points="1800.36 2651.56 1776.13 2665.56 1776.16 2639.68 1800.38 2625.68 1800.36 2651.56" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2571.46 1654.89 2667.46 1654.89 2595.58 1821.16 2499.58 1821.16 2571.46" fill="#64a7d1"/>
            <polygon points="1699.92 2637.68 1675.7 2651.65 1675.67 2625.8 1699.9 2611.83 1699.92 2637.68" fill="#010015"/>
            <polygon points="1800.36 2579.68 1776.13 2593.68 1776.16 2567.8 1800.38 2553.8 1800.36 2579.68" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2499.58 1654.89 2595.58 1654.89 2523.7 1821.16 2427.7 1821.16 2499.58" fill="#64a7d1"/>
            <polygon points="1699.92 2565.8 1675.7 2579.77 1675.67 2553.92 1699.9 2539.95 1699.92 2565.8" fill="#010015"/>
            <polygon points="1800.36 2507.8 1776.13 2521.8 1776.16 2495.92 1800.38 2481.92 1800.36 2507.8" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2427.7 1654.89 2523.7 1654.89 2451.82 1821.16 2355.82 1821.16 2427.7" fill="#64a7d1"/>
            <polygon points="1699.92 2493.92 1675.7 2507.89 1675.67 2482.04 1699.9 2468.07 1699.92 2493.92" fill="#010015"/>
            <polygon points="1800.36 2435.92 1776.13 2449.92 1776.16 2424.05 1800.38 2410.04 1800.36 2435.92" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2355.82 1654.89 2451.82 1654.89 2379.94 1821.16 2283.94 1821.16 2355.82" fill="#64a7d1"/>
            <polygon points="1699.92 2422.04 1675.7 2436.01 1675.67 2410.17 1699.9 2396.19 1699.92 2422.04" fill="#010015"/>
            <polygon points="1800.36 2364.04 1776.13 2378.04 1776.16 2352.17 1800.38 2338.16 1800.36 2364.04" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2283.94 1654.89 2379.94 1654.89 2308.06 1821.16 2212.06 1821.16 2283.94" fill="#64a7d1"/>
            <polygon points="1699.92 2350.16 1675.7 2364.14 1675.67 2338.29 1699.9 2324.32 1699.92 2350.16" fill="#010015"/>
            <polygon points="1800.36 2292.16 1776.13 2306.16 1776.16 2280.29 1800.38 2266.29 1800.36 2292.16" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2212.06 1654.89 2308.06 1654.89 2236.18 1821.16 2140.18 1821.16 2212.06" fill="#64a7d1"/>
            <polygon points="1699.92 2278.28 1675.7 2292.26 1675.67 2266.41 1699.9 2252.44 1699.92 2278.28" fill="#010015"/>
            <polygon points="1800.36 2220.28 1776.13 2234.28 1776.16 2208.41 1800.38 2194.41 1800.36 2220.28" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2140.18 1654.89 2236.18 1654.89 2164.3 1821.16 2068.3 1821.16 2140.18" fill="#64a7d1"/>
            <polygon points="1699.92 2206.41 1675.7 2220.38 1675.67 2194.53 1699.9 2180.56 1699.92 2206.41" fill="#010015"/>
            <polygon points="1800.36 2148.41 1776.13 2162.41 1776.16 2136.53 1800.38 2122.53 1800.36 2148.41" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 2068.3 1654.89 2164.3 1654.89 2092.43 1821.16 1996.43 1821.16 2068.3" fill="#64a7d1"/>
            <polygon points="1699.92 2134.53 1675.7 2148.5 1675.67 2122.65 1699.9 2108.68 1699.92 2134.53" fill="#010015"/>
            <polygon points="1800.36 2076.53 1776.13 2090.53 1776.16 2064.65 1800.38 2050.65 1800.36 2076.53" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1996.43 1654.89 2092.43 1654.89 2020.55 1821.16 1924.55 1821.16 1996.43" fill="#64a7d1"/>
            <polygon points="1699.92 2062.65 1675.7 2076.62 1675.67 2050.77 1699.9 2036.8 1699.92 2062.65" fill="#010015"/>
            <polygon points="1800.36 2004.65 1776.13 2018.65 1776.16 1992.77 1800.38 1978.77 1800.36 2004.65" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1924.55 1654.89 2020.55 1654.89 1948.67 1821.16 1852.67 1821.16 1924.55" fill="#64a7d1"/>
            <polygon points="1699.92 1990.77 1675.7 2004.74 1675.67 1978.89 1699.9 1964.92 1699.92 1990.77" fill="#010015"/>
            <polygon points="1800.36 1932.77 1776.13 1946.77 1776.16 1920.89 1800.38 1906.89 1800.36 1932.77" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1852.67 1654.89 1948.67 1654.89 1876.79 1821.16 1780.79 1821.16 1852.67" fill="#64a7d1"/>
            <polygon points="1699.92 1918.89 1675.7 1932.86 1675.67 1907.01 1699.9 1893.04 1699.92 1918.89" fill="#010015"/>
            <polygon points="1800.36 1860.89 1776.13 1874.89 1776.16 1849.01 1800.38 1835.01 1800.36 1860.89" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1780.79 1654.89 1876.79 1654.89 1804.91 1821.16 1708.91 1821.16 1780.79" fill="#64a7d1"/>
            <polygon points="1699.92 1847.01 1675.7 1860.98 1675.67 1835.13 1699.9 1821.16 1699.92 1847.01" fill="#010015"/>
            <polygon points="1800.36 1789.01 1776.13 1803.01 1776.16 1777.14 1800.38 1763.13 1800.36 1789.01" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1708.91 1654.89 1804.91 1654.89 1733.03 1821.16 1637.03 1821.16 1708.91" fill="#64a7d1"/>
            <polygon points="1699.92 1775.13 1675.7 1789.11 1675.67 1763.26 1699.9 1749.28 1699.92 1775.13" fill="#010015"/>
            <polygon points="1800.36 1717.13 1776.13 1731.13 1776.16 1705.26 1800.38 1691.26 1800.36 1717.13" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1637.03 1654.89 1733.03 1654.89 1661.15 1821.16 1565.15 1821.16 1637.03" fill="#64a7d1"/>
            <polygon points="1699.92 1703.25 1675.7 1717.23 1675.67 1691.38 1699.9 1677.41 1699.92 1703.25" fill="#010015"/>
            <polygon points="1800.36 1645.25 1776.13 1659.25 1776.16 1633.38 1800.38 1619.38 1800.36 1645.25" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1565.15 1654.89 1661.15 1654.89 1589.27 1821.16 1493.27 1821.16 1565.15" fill="#64a7d1"/>
            <polygon points="1699.92 1631.38 1675.7 1645.35 1675.67 1619.5 1699.9 1605.53 1699.92 1631.38" fill="#010015"/>
            <polygon points="1800.36 1573.37 1776.13 1587.38 1776.16 1561.5 1800.38 1547.5 1800.36 1573.37" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1493.27 1654.89 1589.27 1654.89 1517.4 1821.16 1421.39 1821.16 1493.27" fill="#64a7d1"/>
            <polygon points="1699.92 1559.5 1675.7 1573.47 1675.67 1547.62 1699.9 1533.65 1699.92 1559.5" fill="#010015"/>
            <polygon points="1800.36 1501.5 1776.13 1515.5 1776.16 1489.62 1800.38 1475.62 1800.36 1501.5" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1421.39 1654.89 1517.39 1654.89 1445.52 1821.16 1349.52 1821.16 1421.39" fill="#64a7d1"/>
            <polygon points="1699.92 1487.62 1675.7 1501.59 1675.67 1475.74 1699.9 1461.77 1699.92 1487.62" fill="#010015"/>
            <polygon points="1800.36 1429.62 1776.13 1443.62 1776.16 1417.74 1800.38 1403.74 1800.36 1429.62" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1349.52 1654.89 1445.52 1654.89 1373.64 1821.16 1277.64 1821.16 1349.52" fill="#64a7d1"/>
            <polygon points="1699.92 1415.74 1675.7 1429.71 1675.67 1403.86 1699.9 1389.89 1699.92 1415.74" fill="#010015"/>
            <polygon points="1800.36 1357.74 1776.13 1371.74 1776.16 1345.86 1800.38 1331.86 1800.36 1357.74" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1277.64 1654.89 1373.64 1654.89 1301.76 1821.16 1205.76 1821.16 1277.64" fill="#64a7d1"/>
            <polygon points="1699.92 1343.86 1675.7 1357.83 1675.67 1331.98 1699.9 1318.01 1699.92 1343.86" fill="#010015"/>
            <polygon points="1800.36 1285.86 1776.13 1299.86 1776.16 1273.98 1800.38 1259.98 1800.36 1285.86" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1205.76 1654.89 1301.76 1654.89 1229.88 1821.16 1133.88 1821.16 1205.76" fill="#64a7d1"/>
            <polygon points="1699.92 1271.98 1675.7 1285.95 1675.67 1260.11 1699.9 1246.13 1699.92 1271.98" fill="#010015"/>
            <polygon points="1800.36 1213.98 1776.13 1227.98 1776.16 1202.11 1800.38 1188.11 1800.36 1213.98" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.16 1133.88 1654.89 1229.88 1654.89 1158 1821.16 1062 1821.16 1133.88" fill="#64a7d1"/>
            <polygon points="1699.92 1200.1 1675.7 1214.07 1675.67 1188.23 1699.9 1174.25 1699.92 1200.1" fill="#010015"/>
            <polygon points="1800.36 1142.1 1776.13 1156.1 1776.16 1130.23 1800.38 1116.23 1800.36 1142.1" fill="#010015"/>
          </g>
        </g>
      </g>
    </g>
</template>
<script>
export default {
  name: "Post2964.vue",
};
</script>