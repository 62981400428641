<template>
<div class="showValueOverWrapper">
  <div class="showValueWrapper borderbottom-svw">
      <h3>Mått</h3>
      <div class="valueHolderFlex">
    <div class="boxValue">
      <p>Faktisk total längd:</p>
      <div class="boxSignWrapper">
        <div class="boxSign"> <span>{{(totalLength/1000).toFixed(2).replace(/\./g, ',')}} m</span></div>
      </div>
    </div>
    <div class="boxValue valueBoxLeftMargin">
      <p>Djup:</p>
      <div class="boxSignWrapper">
        <div class="boxSign"> <span>{{(totalDepth/1000).toFixed(2).replace(/\./g, ',')}} m</span></div>
      </div>
    </div>
    </div>
  </div>
    <div class="showValueWrapper borderbottom">
      <h3>Total maxvikt</h3>
      <div class="valueHolderFlex">
    <div class="boxValue">
      <p>Per sida:</p>
      <div class="boxSignWrapper">
        <div class="boxSign"> <span>{{(totalCapacityPerSide/1000).toFixed(1).replace(/\./g, ',')}} ton</span></div>
      </div>
    </div>
    <div class="boxValue valueBoxLeftMargin">
      <p>Per armplan:</p>
      <div class="boxSignWrapper">
        <div class="boxSign"> <span>{{totalCapacityPerArmPlane}} kg</span></div>
      </div>
    </div>
    </div>
  </div>
</div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: "ShowValues.vue",

  computed: {
    totalLength: {
      get() {
        return this.$store.state.totalLength;
      },
    },
    totalDepth: {
      get() {
        return this.$store.state.totalDepth;
      }
    },
    totalCapacityPerSide: {
      get() {
        return this.$store.state.totalCapacityPerSide;
      },
    },
    totalCapacityPerArmPlane: {
      get() {
        return this.$store.state.totalCapacityPerArmPlane;
      }
    }
  },

  ...mapGetters([
    "availableHeights",
    "armPosInfo",
    "selectedPostStand",
    "countBranchStands",
    "branchStandPosInfo",
    "arrowHeightPosBaseCoords",
    "arrowLengthPosBaseCoords",
    "arrowDepthPosBaseCoords"
  ]),

};

</script>

<style scoped>
</style>
