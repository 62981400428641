<template>
<div class="graphicAreaOverWrapper">
  <section class="graphic">
    <div class="hideWrapper">
    <ModelHeading/>
      <ShowValues/>
      </div>
    <div class="containerSvgRoot30">
      <div class="holderGraf">
        <Spetsglans
          v-if="selectedBrand == 'Spetsglans'"
        ></Spetsglans>
        <Brucit
            v-if="selectedBrand == 'Brucit'"
        ></Brucit>
        <Hematit
            v-if="selectedBrand == 'Hematit'"
        ></Hematit>
      </div>
    </div>
    <!-- Marginblocker adds margin to right, helps when rotate, so you "dont simmar ur bild" -->
    <div class="marginBlocker"></div>

  </section>
  <div class="mobileShowValues">
     <ShowValues/>
  </div>
</div>
</template>

<script>

import { mapGetters } from "vuex";

import Spetsglans from "@/components/svg-parts/spetsglans/SpetsglansCombinedParts.vue";
import Brucit from "@/components/svg-parts/brucit/BrucitCombinedParts.vue";
import Hematit from "@/components/svg-parts/hematit/HematitCombinedParts.vue";
import ShowValues from "@/components/ShowValues.vue";
import ModelHeading from "@/components/small-components/ModelHeading.vue";

export default {
  name: "graphicArea.vue",
  components: {
    Spetsglans,
    Brucit,
    Hematit,
    ShowValues,
    ModelHeading
  },

  mounted() {
    console.log("mounted started! Now dispatching requestData(...)");

    this.$store.dispatch("requestData").then(() => {
      // One could add startup logic here if so desired :)
    });
  },

  computed: {
    selectedBrand: {
      get()
      {
        return this.$store.state.selectedBrand;
      }
    },

    ...mapGetters([
      "availableHeights",
      "countBranchStands"
    ]),
  }
};
</script>

<style scoped>
</style>
