<template>
  <g class="foot1830">
    <g class="fotTopWrapper-6" data-name="fotTopWrapper">
      <polygon class="fotTop-16" data-name="fotTop" points="1862.49 3833.91 277.66 4748.91 111.39 4652.91 1696.21 3737.91 1862.49 3833.91" fill="#a2d2ee"/>
      <polygon class="fotTop-17" data-name="fotTop" points="1821.09 3834.01 277.84 4725.01 152.78 4652.81 1696.04 3761.81 1821.09 3834.01" fill="#64a7d1"/>
    </g>
    <polygon class="front-6" data-name="front" points="277.65 4940.91 111.37 4844.91 111.37 4652.91 277.65 4748.91 277.65 4940.91" fill="#88c3e6"/>
    <g class="fotRightWrapper-6" data-name="fotRightWrapper">
      <polygon class="fotTop-18" data-name="fotTop" points="1862.59 4025.94 277.76 4940.94 277.76 4748.94 1862.59 3833.94 1862.59 4025.94" fill="#64a7d1"/>
      <g class="bult-6" data-name="bult">
        <polygon class="boltBackground" points="1862.35 4026.08 1696.07 4122.08 1696.07 3930.08 1862.35 3834.08 1862.35 4026.08" fill="#64a7d1"/>
        <g class="boltsWrapper-6" data-name="boltsWrapper">
          <ellipse cx="2987.68" cy="3116.32" rx="12.86" ry="7.42" transform="translate(-2371.87 5031.33) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2987.68" cy="3020.32" rx="12.86" ry="7.42" transform="translate(-2288.73 4983.33) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2904.54" cy="3164.32" rx="12.86" ry="7.42" transform="translate(-2455.01 4983.33) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2904.54" cy="3068.32" rx="12.86" ry="7.42" transform="translate(-2371.87 4935.33) rotate(-60)" fill="#cfcccc"/>
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  name: "Foot1830.vue",
};
</script>