import { render, staticRenderFns } from "./Foot2225-H.vue?vue&type=template&id=04b9c2f9"
import script from "./Foot2225-H.vue?vue&type=script&lang=js"
export * from "./Foot2225-H.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports