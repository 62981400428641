<template>
    <g class="post3000">
      <g class="rightsidehole" data-name="rightsidehole">
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 3000 1779.68 3020 1779.68 2913.93 1814.32 2893.93 1814.32 3000" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,3502c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,3502Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2893.93 1779.68 2913.93 1779.68 2807.86 1814.32 2787.86 1814.32 2893.93" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,3395.94c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,3395.94Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2787.86 1779.68 2807.86 1779.68 2701.79 1814.32 2681.79 1814.32 2787.86" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,3289.87c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.26c2-1.14,3.58-.19,3.58,2.12A8,8,0,0,1,2905,3289.87Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2681.79 1779.68 2701.79 1779.68 2595.72 1814.32 2575.72 1814.32 2681.79" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,3183.8c-2,1.14-3.59.19-3.59-2.12a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.19,3.58,2.11A8,8,0,0,1,2905,3183.8Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2575.72 1779.68 2595.72 1779.68 2489.64 1814.32 2469.64 1814.32 2575.72" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,3077.72c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,3077.72Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2469.64 1779.68 2489.64 1779.68 2383.57 1814.32 2363.57 1814.32 2469.64" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2971.65c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2971.65Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2363.57 1779.68 2383.57 1779.68 2277.5 1814.32 2257.5 1814.32 2363.57" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2865.58c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2865.58Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2257.5 1779.68 2277.5 1779.68 2171.43 1814.32 2151.43 1814.32 2257.5" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2759.51c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2759.51Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2151.43 1779.68 2171.43 1779.68 2065.36 1814.32 2045.36 1814.32 2151.43" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2653.44c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2653.44Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 2045.36 1779.68 2065.36 1779.68 1959.29 1814.32 1939.29 1814.32 2045.36" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2547.37c-2,1.14-3.59.2-3.59-2.11A8,8,0,0,1,2905,2539c2-1.14,3.58-.19,3.58,2.12A8,8,0,0,1,2905,2547.37Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1939.29 1779.68 1959.29 1779.68 1853.22 1814.32 1833.22 1814.32 1939.29" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2441.3c-2,1.14-3.59.19-3.59-2.12a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.19,3.58,2.11A8,8,0,0,1,2905,2441.3Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1833.22 1779.68 1853.22 1779.68 1747.14 1814.32 1727.14 1814.32 1833.22" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2335.22c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2335.22Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1727.14 1779.68 1747.14 1779.68 1641.07 1814.32 1621.07 1814.32 1727.14" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2229.15c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2229.15Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1621.07 1779.68 1641.07 1779.68 1535 1814.32 1515 1814.32 1621.07" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2123.08c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2123.08Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1515 1779.68 1535 1779.68 1428.93 1814.32 1408.93 1814.32 1515" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,2017c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,2017Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1408.93 1779.68 1428.93 1779.68 1322.86 1814.32 1302.86 1814.32 1408.93" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1910.94c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,1910.94Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1302.86 1779.68 1322.86 1779.68 1216.79 1814.32 1196.79 1814.32 1302.86" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1804.87c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.26c2-1.14,3.58-.19,3.58,2.12A8,8,0,0,1,2905,1804.87Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1196.79 1779.68 1216.79 1779.68 1110.72 1814.32 1090.72 1814.32 1196.79" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1698.8c-2,1.14-3.59.19-3.59-2.12a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.19,3.58,2.11A8,8,0,0,1,2905,1698.8Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 1090.72 1779.68 1110.72 1779.68 1004.64 1814.32 984.64 1814.32 1090.72" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1592.72c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,1592.72Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 984.64 1779.68 1004.64 1779.68 898.57 1814.32 878.57 1814.32 984.64" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1486.65c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,1486.65Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 878.57 1779.68 898.57 1779.68 792.5 1814.32 772.5 1814.32 878.57" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1380.58c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,1380.58Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 772.5 1779.68 792.5 1779.68 686.43 1814.32 666.43 1814.32 772.5" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1274.51c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,1274.51Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 666.43 1779.68 686.43 1779.68 580.36 1814.32 560.36 1814.32 666.43" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1168.44c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.15,3.58-.2,3.58,2.11A8,8,0,0,1,2905,1168.44Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 560.36 1779.68 580.36 1779.68 474.29 1814.32 454.29 1814.32 560.36" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,1062.37c-2,1.14-3.59.2-3.59-2.11A8,8,0,0,1,2905,1054c2-1.14,3.58-.19,3.58,2.12A8,8,0,0,1,2905,1062.37Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 454.29 1779.68 474.29 1779.68 368.22 1814.32 348.22 1814.32 454.29" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,956.3c-2,1.14-3.59.19-3.59-2.12a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.19,3.58,2.11A8,8,0,0,1,2905,956.3Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 348.22 1779.68 368.22 1779.68 262.14 1814.32 242.14 1814.32 348.22" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,850.22c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,850.22Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 242.14 1779.68 262.14 1779.68 156.07 1814.32 136.07 1814.32 242.14" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,744.15c-2,1.15-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,744.15Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.32 136.07 1779.68 156.07 1779.68 50 1814.32 30 1814.32 136.07" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905,638.08c-2,1.14-3.59.2-3.59-2.11a8,8,0,0,1,3.59-6.25c2-1.14,3.58-.2,3.58,2.11A8,8,0,0,1,2905,638.08Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
      </g>
      <g class="frontsidehole" data-name="frontsidehole">
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 3020 1727.71 2990 1727.71 2883.93 1779.67 2913.93 1779.67 3020" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="3492.83" rx="2.94" ry="5.1" transform="translate(-2456.83 1320.77) rotate(-29.49)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2913.93 1727.71 2883.93 1727.71 2777.86 1779.67 2807.86 1779.67 2913.93" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="3386.76" rx="2.94" ry="5.1" transform="translate(-2404.61 1307.02) rotate(-29.49)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2807.86 1727.71 2777.86 1727.71 2671.78 1779.67 2701.78 1779.67 2807.86" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,3282.75c0,2.31-1.61,3.26-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.25,3.58-2.11A8,8,0,0,1,2865.31,3282.75Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2701.78 1727.71 2671.78 1727.71 2565.71 1779.67 2595.71 1779.67 2701.78" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,3176.68c0,2.31-1.61,3.26-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.25,3.58-2.11A8,8,0,0,1,2865.31,3176.68Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2595.71 1727.71 2565.71 1727.71 2459.64 1779.67 2489.64 1779.67 2595.71" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="3068.54" rx="2.94" ry="5.1" transform="translate(-2248 1265.98) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2489.64 1727.71 2459.64 1727.71 2353.57 1779.67 2383.57 1779.67 2489.64" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,2964.54c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.11A8,8,0,0,1,2865.31,2964.54Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2383.57 1727.71 2353.57 1727.71 2247.5 1779.67 2277.5 1779.67 2383.57" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="2856.4" rx="2.94" ry="5.1" transform="translate(-2143.54 1238.48) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2277.5 1727.71 2247.5 1727.71 2141.43 1779.67 2171.43 1779.67 2277.5" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,2752.4c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.12A8,8,0,0,1,2865.31,2752.4Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2171.43 1727.71 2141.43 1727.71 2035.36 1779.67 2065.36 1779.67 2171.43" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,2646.32c0,2.31-1.61,3.26-3.59,2.12a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.12A8,8,0,0,1,2865.31,2646.32Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 2065.36 1727.71 2035.36 1727.71 1929.29 1779.67 1959.29 1779.67 2065.36" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="2538.18" rx="2.94" ry="5.1" transform="translate(-1986.86 1197.23) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1959.29 1727.71 1929.29 1727.71 1823.21 1779.67 1853.21 1779.67 1959.29" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,2434.18c0,2.31-1.61,3.26-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.25,3.58-2.11A8,8,0,0,1,2865.31,2434.18Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1853.21 1727.71 1823.21 1727.71 1717.14 1779.67 1747.14 1779.67 1853.21" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="2326.04" rx="2.94" ry="5.1" transform="translate(-1882.4 1169.73) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1747.14 1727.71 1717.14 1727.71 1611.07 1779.67 1641.07 1779.67 1747.14" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,2222c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.11A8,8,0,0,1,2865.31,2222Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1641.07 1727.71 1611.07 1727.71 1505 1779.67 1535 1779.67 1641.07" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="2113.9" rx="2.94" ry="5.1" transform="translate(-1777.95 1142.23) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1535 1727.71 1505 1727.71 1398.93 1779.67 1428.93 1779.67 1535" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,2009.9c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.12A8,8,0,0,1,2865.31,2009.9Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1428.93 1727.71 1398.93 1727.71 1292.86 1779.67 1322.86 1779.67 1428.93" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,1903.82c0,2.31-1.61,3.26-3.59,2.12a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.12A8,8,0,0,1,2865.31,1903.82Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1322.86 1727.71 1292.86 1727.71 1186.79 1779.67 1216.79 1779.67 1322.86" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="1795.68" rx="2.94" ry="5.1" transform="translate(-1621.26 1100.99) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1216.79 1727.71 1186.79 1727.71 1080.71 1779.67 1110.71 1779.67 1216.79" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,1691.68c0,2.31-1.61,3.26-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.25,3.58-2.11A8,8,0,0,1,2865.31,1691.68Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1110.71 1727.71 1080.71 1727.71 974.64 1779.67 1004.64 1779.67 1110.71" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="1583.54" rx="2.94" ry="5.1" transform="translate(-1516.8 1073.49) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 1004.64 1727.71 974.64 1727.71 868.57 1779.67 898.57 1779.67 1004.64" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,1479.54c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.11A8,8,0,0,1,2865.31,1479.54Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 898.57 1727.71 868.57 1727.71 762.5 1779.67 792.5 1779.67 898.57" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="1371.4" rx="2.94" ry="5.1" transform="translate(-1412.35 1045.99) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 792.5 1727.71 762.5 1727.71 656.43 1779.67 686.43 1779.67 792.5" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,1267.4c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.12A8,8,0,0,1,2865.31,1267.4Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 686.43 1727.71 656.43 1727.71 550.36 1779.67 580.36 1779.67 686.43" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,1161.32c0,2.31-1.61,3.26-3.59,2.12a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.12A8,8,0,0,1,2865.31,1161.32Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 580.36 1727.71 550.36 1727.71 444.29 1779.67 474.29 1779.67 580.36" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="1053.18" rx="2.94" ry="5.1" transform="translate(-1255.66 1004.74) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 474.29 1727.71 444.29 1727.71 338.21 1779.67 368.21 1779.67 474.29" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="947.11" rx="2.94" ry="5.1" transform="translate(-1203.45 990.91) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 368.21 1727.71 338.21 1727.71 232.14 1779.67 262.14 1779.67 368.21" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="841.04" rx="2.94" ry="5.1" transform="translate(-1151.22 977.17) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 262.14 1727.71 232.14 1727.71 126.07 1779.67 156.07 1779.67 262.14" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.72" cy="734.97" rx="2.94" ry="5.1" transform="translate(-1098.99 963.42) rotate(-29.5)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.67 156.07 1727.71 126.07 1727.71 20 1779.67 50 1779.67 156.07" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.31,631c0,2.31-1.61,3.25-3.59,2.11a8,8,0,0,1-3.58-6.25c0-2.31,1.6-3.26,3.58-2.11A8,8,0,0,1,2865.31,631Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
      </g>
      <g class="top" data-name="top">
        <polygon class="top-post" data-name="top-post" points="1779.67 50 1727.71 20 1762.35 0 1814.31 30 1779.67 50" fill="#5070af"/>
        <polygon class="inner-top-post" data-name="inner-top-post" points="1779.67 45 1736.37 20 1762.35 5 1805.65 30 1779.67 45" fill="#5e7cb7"/>
        <polygon class="rightside" data-name="rightside" points="1762.35 5 1762.35 35.04 1736.37 20 1762.35 5" fill="#365593"/>
      </g>
    </g>
</template>
<script>
export default {
  name: "Post3000-B.vue",
};
</script>