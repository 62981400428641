<template>
  <g class="strut1200">
    <polygon class="strut-top" points="1526.72 2937.77 653.77 2433.77 667.14 2426.05 1540.09 2930.05 1526.72 2937.77" fill="#7db0c9"/>
    <polygon class="cross-3" data-name="cross" points="1522.38 1929.86 665.16 2493.9 658.1 2465.34 1515.33 1901.29 1522.38 1929.86" fill="#7db0c9"/>
    <polygon class="cross-4" data-name="cross" points="664.61 1411.98 1522.91 2962.74 1515.88 2983.21 657.58 1432.45 664.61 1411.98" fill="#7db0c9"/>
    <ellipse class="centerNut-2" data-name="centerNut" cx="2257.15" cy="1311.83" rx="4.51" ry="10.26" transform="translate(-1341.67 1248.18) rotate(-8.84)" fill="#c7d0d3"/>
    <polygon class="bottomBeam-2" data-name="bottomBeam" points="1526.72 3017.77 653.77 2513.77 653.77 2433.77 1526.72 2937.77 1526.72 3017.77" fill="#88c3e6"/>
    <polygon class="topBeam-2" data-name="topBeam" points="1526.72 1977.77 653.77 1473.77 653.77 1393.77 1526.72 1897.77 1526.72 1977.77" fill="#88c3e6"/>
    <polygon class="strut-top-2" data-name="strut-top" points="1526.72 1897.77 653.77 1393.77 667.14 1386.05 1540.09 1890.05 1526.72 1897.77" fill="#7db0c9"/>
  </g>
</template>
<script>
export default {
  name: "Strut1200.vue",
};
</script>