<template>
  <g class="post3000">
        <polygon class="main-rightside" data-name="main-rightside" points="1693.95 1256.98 1893.14 1141.98 1893.14 3871.98 1693.95 3986.98 1693.95 1256.98" fill="#0b502e"/>
        <polygon class="frontside" data-name="frontside" points="1649.78 1231.48 1693.95 1256.98 1693.95 3986.98 1649.78 3961.48 1649.78 1231.48" fill="#1b7742"/>
        <polygon class="frontside_downtriangle" data-name="frontside downtriangle" points="1649.78 3961.48 1649.78 4012.48 1693.95 3986.98 1649.78 3961.48" fill="#1b7742"/>
        <g class="toplock-layer-21mm" data-name="toplock-layer-21mm">
          <polygon class="innertop" data-name="innertop" points="1893.13 1141.98 1693.95 1256.98 1649.78 1231.48 1848.97 1116.48 1893.13 1141.98" fill="#1f9456"/>
          <polygon class="innertop" data-name="innertop" points="1862.82 1144.48 1698.28 1239.48 1680.09 1228.98 1844.64 1133.98 1862.82 1144.48" fill="#083a1f"/>
          <polygon class="frontside" data-name="frontside" points="1844.64 1133.98 1844.64 1154.98 1862.82 1144.48 1844.64 1133.98" fill="#1b7742"/>
        </g>
    </g>
</template>
<script>
export default {
  name: "Post3000-H.vue",
};
</script>