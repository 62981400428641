<template>
  <g class="leftside-signal230mm">
    <g class="single-signallag230mm" data-name="single-signallag230mm">
      <g class="bar1220mm" data-name="bar1220mm">
        <g class="platewithcylinder-3" data-name="platewithcylinder">
          <polygon points="1265.2 3997.97 1113.7 4085.47 962.1 3997.97 1113.7 3910.47 1265.2 3997.97" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M744.3,3997.37v-89.5H878.1v89.9S744.1,3997.17,744.3,3997.37Z" transform="translate(302.1)" fill="#f9c138"/>
            <ellipse cx="1113.3" cy="3997.97" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1113.3" cy="3909.07" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="303.1 4552.97 151.5 4640.47 0 4552.97 151.5 4465.47 303.1 4552.97" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M-216.9,4552.37v-89.5H-84.1v89.9S-217.1,4552.17-216.9,4552.37Z" transform="translate(302.1)" fill="#f9c138"/>
            <ellipse cx="152" cy="4552.97" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="151.5" cy="4464.07" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" data-name="bar" d="M-83.9,4498h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2V4498Z" transform="translate(302.1)" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" data-name="blackmark" points="413.6 4262.77 443.7 4217.67 409.5 4150.67 357.5 4202.67 406.8 4264.17"/>
      <polyline class="blackmark" data-name="blackmark" points="867.5 4005.77 897.5 3960.67 863.4 3893.67 811.4 3945.57 860.6 4007.17"/>
      <polygon class="rightside-signal" data-name="rightside-signal" points="303.1 4562.97 151.5 4650.47 151.5 4640.47 303.1 4552.97 303.1 4562.97" fill="#db8914"/>
      <polygon class="frontside-signal" data-name="frontside-signal" points="151.5 4650.37 0 4562.87 0 4552.87 151.5 4640.37 151.5 4650.37" fill="#e8932e"/>
      <polygon class="frontside-signal-signal" data-name="frontside-signal" points="1113.5 4095.57 962 4008.07 962 3998.07 1113.5 4085.57 1113.5 4095.57" fill="#e8932e"/>
      <polygon class="rightside-signal" data-name="rightside-signal" points="1265.1 4007.97 1113.6 4095.47 1113.6 4085.47 1265.1 3997.97 1265.1 4007.97" fill="#db8914"/>
    </g>
      <g v-if="doubleBranch" class="double-signallag230mm">
      <polygon points="2811.5 3105.47 2659.9 3192.97 2508.4 3105.47 2659.9 3017.97 2811.5 3105.47" fill="#fba61e"/>
      <g class="topcylinder" data-name="topcylinder">
        <path d="M2290.6,3104.87v-89.5h133.8v89.9S2290.4,3104.67,2290.6,3104.87Z" transform="translate(302.1)" fill="#f9c138"/>
        <ellipse cx="2659.6" cy="3105.47" rx="67.1" ry="38.9" fill="#f9c138"/>
        <ellipse cx="2659.6" cy="3016.57" rx="67.1" ry="38.9" fill="#f9c138"/>
      </g>
      <polygon points="1849.4 3660.47 1697.8 3747.97 1546.3 3660.47 1697.8 3572.97 1849.4 3660.47" fill="#fba61e"/>
      <g class="topcylinder" data-name="topcylinder">
        <path d="M1329.4,3659.87v-89.5h132.8v89.9S1329.2,3659.67,1329.4,3659.87Z" transform="translate(302.1)" fill="#f9c138"/>
        <ellipse cx="1698.3" cy="3660.47" rx="66.5" ry="38.9" fill="#f9c138"/>
        <ellipse cx="1697.8" cy="3571.57" rx="66.6" ry="38.9" fill="#f9c138"/>
      </g>
      <path class="bar" data-name="bar" d="M1462.4,3605.47h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2v105.4Z" transform="translate(302.1)" fill="#f9c138"/>
      <polyline class="blackmark" data-name="blackmark" points="1959.9 3370.27 1990 3325.17 1955.8 3258.17 1903.8 3310.17 1953 3371.67"/>
      <polyline class="blackmark" data-name="blackmark" points="2413.8 3113.27 2443.8 3068.17 2409.7 3001.17 2357.7 3053.07 2406.9 3114.67"/>
      <polygon class="rightside-signal" data-name="rightside-signal" points="1849.4 3670.47 1697.8 3757.97 1697.8 3747.97 1849.4 3660.47 1849.4 3670.47" fill="#db8914"/>
      <polygon class="frontside-signal" data-name="frontside-signal" points="1697.8 3757.87 1546.3 3670.37 1546.3 3660.37 1697.8 3747.87 1697.8 3757.87" fill="#e8932e"/>
      <polygon class="frontside-signal" data-name="frontside-signal" points="2659.8 3203.07 2508.3 3115.57 2508.3 3105.57 2659.8 3193.07 2659.8 3203.07" fill="#e8932e"/>
      <polygon class="rightside-signal" data-name="rightside-signal" points="2811.4 3115.47 2659.9 3202.97 2659.9 3192.97 2811.4 3105.47 2811.4 3115.47" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean
  },

  name: "SpetsSignalLeft1220.vue",
};
</script>