<template>
 <g class="post2000">
      <g class="top" data-name="top">
        <polygon class="top-post" points="1779.69 1050.01 1727.73 1020.01 1762.37 1000.01 1814.33 1030.01 1779.69 1050.01" fill="#5070af"/>
        <polygon class="inner-top-post" points="1779.69 1045.01 1736.39 1020.01 1762.37 1005.01 1805.67 1030.01 1779.69 1045.01" fill="#5e7cb7"/>
        <polygon class="rightside" data-name="rightside" points="1762.37 1005.01 1762.37 1035.05 1736.39 1020.01 1762.37 1005.01" fill="#365593"/>
      </g>
      <g class="frontsidehole">
        <g>
          <polygon class="frontside" points="1727.73 1020 1779.69 1050 1779.69 1153.68 1727.73 1123.68 1727.73 1020" fill="#5e7cb7"/>
          <path class="fronthole" d="M2858.16,1625.63c0-2.25,1.61-3.16,3.59-2a7.86,7.86,0,0,1,3.58,6.15c0,2.26-1.6,3.16-3.58,2A7.88,7.88,0,0,1,2858.16,1625.63Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1123.68 1779.69 1153.68 1779.69 1257.37 1727.73 1227.37 1727.73 1123.68" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2858.16,1729.32c0-2.26,1.61-3.16,3.59-2a7.87,7.87,0,0,1,3.58,6.16c0,2.26-1.6,3.16-3.58,2A7.89,7.89,0,0,1,2858.16,1729.32Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1227.37 1779.69 1257.37 1779.69 1361.05 1727.73 1331.05 1727.73 1227.37" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2858.16,1833c0-2.25,1.61-3.16,3.59-2a7.85,7.85,0,0,1,3.58,6.15c0,2.26-1.6,3.16-3.58,2A7.88,7.88,0,0,1,2858.16,1833Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1331.05 1779.69 1361.05 1779.69 1464.74 1727.73 1434.74 1727.73 1331.05" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="1938.76" rx="2.91" ry="5.04" transform="translate(-1696.51 1187.4) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1434.74 1779.69 1464.74 1779.69 1568.42 1727.73 1538.42 1727.73 1434.74" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2042.44" rx="2.91" ry="5.04" transform="translate(-1749.33 1201.86) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1538.42 1779.69 1568.42 1779.69 1672.1 1727.73 1642.1 1727.73 1538.42" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2146.13" rx="2.91" ry="5.04" transform="translate(-1802.15 1216.32) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon points="1727.73 1642.1 1779.69 1672.1 1779.69 1775.79 1727.73 1745.79 1727.73 1642.1" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2249.81" rx="2.91" ry="5.04" transform="translate(-1854.97 1230.78) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1745.79 1779.69 1775.79 1779.69 1879.47 1727.73 1849.47 1727.73 1745.79" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2353.49" rx="2.91" ry="5.04" transform="translate(-1907.79 1245.24) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1849.47 1779.69 1879.47 1779.69 1983.16 1727.73 1953.16 1727.73 1849.47" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2457.18" rx="2.91" ry="5.04" transform="translate(-1960.61 1259.71) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 1953.16 1779.69 1983.16 1779.69 2086.84 1727.73 2056.84 1727.73 1953.16" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2560.86" rx="2.91" ry="5.04" transform="translate(-2013.43 1274.17) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2056.84 1779.69 2086.84 1779.69 2190.53 1727.73 2160.53 1727.73 2056.84" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2664.55" rx="2.91" ry="5.04" transform="translate(-2066.25 1288.63) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2160.53 1779.69 2190.53 1779.69 2294.21 1727.73 2264.21 1727.73 2160.53" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2768.23" rx="2.91" ry="5.04" transform="translate(-2119.07 1303.09) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2264.21 1779.69 2294.21 1779.69 2397.89 1727.73 2367.89 1727.73 2264.21" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2858.16,2869.85c0-2.26,1.61-3.17,3.59-2a7.86,7.86,0,0,1,3.58,6.15c0,2.26-1.6,3.17-3.58,2A7.87,7.87,0,0,1,2858.16,2869.85Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2367.89 1779.69 2397.89 1779.69 2501.58 1727.73 2471.58 1727.73 2367.89" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="2975.6" rx="2.91" ry="5.04" transform="matrix(0.86, -0.51, 0.51, 0.86, -2224.7, 1332.02)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2471.58 1779.69 2501.58 1779.69 2605.26 1727.73 2575.26 1727.73 2471.58" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="3079.28" rx="2.91" ry="5.04" transform="translate(-2277.52 1346.48) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2575.26 1779.69 2605.26 1779.69 2708.95 1727.73 2678.95 1727.73 2575.26" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="3182.97" rx="2.91" ry="5.04" transform="translate(-2330.34 1360.94) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2678.95 1779.69 2708.95 1779.69 2812.63 1727.73 2782.63 1727.73 2678.95" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="3286.65" rx="2.91" ry="5.04" transform="translate(-2383.16 1375.4) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2782.63 1779.69 2812.63 1779.69 2916.32 1727.73 2886.32 1727.73 2782.63" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="3390.34" rx="2.91" ry="5.04" transform="translate(-2435.98 1389.87) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1727.73 2886.32 1779.69 2916.32 1779.69 3020 1727.73 2990 1727.73 2886.32" fill="#5e7cb7"/>
          <ellipse class="fronthole" data-name="fronthole" cx="2861.75" cy="3494.02" rx="2.91" ry="5.04" transform="translate(-2488.8 1404.33) rotate(-30.63)" fill="#f9f9f9"/>
        </g>
      </g>
      <g class="rightsidehole">
        <g>
          <polygon points="1779.69 1049.98 1814.34 1029.98 1814.34 1133.66 1779.69 1153.66 1779.69 1049.98" fill="#365593"/>
          <path d="M2905.05,1628.6c2-1.15,3.58-.24,3.58,2a7.86,7.86,0,0,1-3.58,6.15c-2,1.15-3.59.24-3.59-2A7.87,7.87,0,0,1,2905.05,1628.6Z" transform="translate(-1108.04 -540.86)" fill="#070707"/>
        </g>
        <g>
          <polygon points="1779.69 1153.66 1814.34 1133.66 1814.34 1237.35 1779.69 1257.35 1779.69 1153.66" fill="#365593"/>
          <path d="M2905.05,1732.28c2-1.14,3.58-.24,3.58,2a7.88,7.88,0,0,1-3.58,6.16c-2,1.14-3.59.24-3.59-2A7.89,7.89,0,0,1,2905.05,1732.28Z" transform="translate(-1108.04 -540.86)" fill="#070707"/>
        </g>
        <g>
          <polygon points="1779.69 1257.35 1814.34 1237.35 1814.34 1341.03 1779.69 1361.03 1779.69 1257.35" fill="#365593"/>
          <path d="M2905.05,1836c2-1.15,3.58-.24,3.58,2a7.87,7.87,0,0,1-3.58,6.16c-2,1.14-3.59.24-3.59-2A7.87,7.87,0,0,1,2905.05,1836Z" transform="translate(-1108.04 -540.86)" fill="#070707"/>
        </g>
        <g>
          <polygon points="1779.69 1361.03 1814.34 1341.03 1814.34 1444.72 1779.69 1464.72 1779.69 1361.03" fill="#365593"/>
          <ellipse cx="2905.05" cy="1943.74" rx="5.04" ry="2.91" transform="translate(-1355.49 2912.55) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon points="1779.69 1464.72 1814.34 1444.72 1814.34 1548.4 1779.69 1568.4 1779.69 1464.72" fill="#365593"/>
          <ellipse cx="2905.05" cy="2047.42" rx="5.04" ry="2.91" transform="translate(-1444.72 2963.42) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon points="1779.69 1568.4 1814.34 1548.4 1814.34 1652.09 1779.69 1672.09 1779.69 1568.4" fill="#365593"/>
          <ellipse cx="2905.05" cy="2151.11" rx="5.04" ry="2.91" transform="translate(-1533.94 3014.28) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon points="1779.69 1672.09 1814.34 1652.09 1814.34 1755.77 1779.69 1775.77 1779.69 1672.09" fill="#365593"/>
          <ellipse cx="2905.05" cy="2254.79" rx="5.04" ry="2.91" transform="matrix(0.51, -0.86, 0.86, 0.51, -1623.16, 3065.15)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 1775.77 1814.34 1755.77 1814.34 1859.45 1779.69 1879.45 1779.69 1775.77" fill="#365593"/>
          <ellipse class="righthole" cx="2905.05" cy="2358.47" rx="5.04" ry="2.91" transform="translate(-1712.38 3116.01) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 1879.45 1814.34 1859.45 1814.34 1963.14 1779.69 1983.14 1779.69 1879.45" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2462.16" rx="5.04" ry="2.91" transform="translate(-1801.61 3166.88) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 1983.14 1814.34 1963.14 1814.34 2066.82 1779.69 2086.82 1779.69 1983.14" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2565.84" rx="5.04" ry="2.91" transform="translate(-1890.83 3217.74) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2086.82 1814.34 2066.82 1814.34 2170.51 1779.69 2190.51 1779.69 2086.82" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,2665.44c2-1.14,3.58-.24,3.58,2a7.85,7.85,0,0,1-3.58,6.15c-2,1.15-3.59.24-3.59-2A7.88,7.88,0,0,1,2905.05,2665.44Z" transform="translate(-1108.04 -540.86)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2190.51 1814.34 2170.51 1814.34 2274.19 1779.69 2294.19 1779.69 2190.51" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2773.21" rx="5.04" ry="2.91" transform="translate(-2069.27 3319.48) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2294.19 1814.34 2274.19 1814.34 2377.88 1779.69 2397.88 1779.69 2294.19" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,2872.81c2-1.14,3.58-.24,3.58,2a7.86,7.86,0,0,1-3.58,6.15c-2,1.15-3.59.24-3.59-2A7.88,7.88,0,0,1,2905.05,2872.81Z" transform="translate(-1108.04 -540.86)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2397.88 1814.34 2377.88 1814.34 2481.56 1779.69 2501.56 1779.69 2397.88" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2980.58" rx="5.04" ry="2.91" transform="matrix(0.51, -0.86, 0.86, 0.51, -2247.72, 3421.21)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2501.56 1814.34 2481.56 1814.34 2585.24 1779.69 2605.24 1779.69 2501.56" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3084.26" rx="5.04" ry="2.91" transform="translate(-2336.94 3472.07) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2605.24 1814.34 2585.24 1814.34 2688.93 1779.69 2708.93 1779.69 2605.24" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3187.95" rx="5.04" ry="2.91" transform="translate(-2426.16 3522.94) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2708.93 1814.34 2688.93 1814.34 2792.61 1779.69 2812.61 1779.69 2708.93" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3291.63" rx="5.04" ry="2.91" transform="translate(-2515.38 3573.8) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2812.61 1814.34 2792.61 1814.34 2896.3 1779.69 2916.3 1779.69 2812.61" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3395.32" rx="5.04" ry="2.91" transform="translate(-2604.61 3624.67) rotate(-59.37)" fill="#070707"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1779.69 2916.3 1814.34 2896.3 1814.34 2999.98 1779.69 3019.98 1779.69 2916.3" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3499" rx="5.04" ry="2.91" transform="translate(-2693.83 3675.54) rotate(-59.37)" fill="#070707"/>
        </g>
      </g>
    </g>
</template>
<script>
export default {
  name: "Post2000-B.vue",
};
</script>