<template>
 <g id="arm600">
    <g id="armDouble" v-if="doubleBranch">
      <polygon id="armholderRight-6" points="1821.16 3448.85 1755.35 3486.85 1755.35 3294.85 1821.16 3256.85 1821.16 3448.85" fill="#8b0606"/>
      <polygon id="rightPath-3" points="1821.16 3388.39 1821.16 3308.39 2334.46 3012.04 2334.46 3092.04 1821.16 3388.39" fill="#8b0606"/>
      <polygon id="topPath-3" points="1821.16 3308.39 1821.16 3175.39 2219.28 2945.54 2334.46 3012.04 1821.16 3308.39" fill="#c44d4d"/>
    </g>
    <g id="armHolder">
      <polygon id="armholderRight-7" points="1720.71 3507.22 1654.89 3545.22 1654.89 3353.22 1720.71 3315.22 1720.71 3507.22" fill="#8b0606"/>
      <polygon id="armHolderFront-4" points="1654.89 3545.23 1526.72 3471.23 1526.72 3279.23 1654.89 3353.23 1654.89 3545.23" fill="#c12727"/>
    </g>
    <g id="singleArm">
      <polygon id="topArm-4" points="1648.58 3408.05 1128.97 3708.05 1013.79 3641.55 1533.4 3341.55 1648.58 3408.05" fill="#c44d4d"/>
      <polygon id="armRight-4" points="1648.57 3488.05 1128.96 3788.05 1128.96 3708.05 1648.57 3408.05 1648.57 3488.05" fill="#8b0606"/>
      <polygon id="blackBottomArm-4" points="1128.96 3788.05 1013.77 3721.55 1013.77 3641.55 1128.96 3708.05 1128.96 3788.05" fill="#100"/>
      <polygon id="innerArm-4" points="1013.77 3721.6 1083.1 3681.57 1013.77 3641.6 1013.77 3721.6" fill="#8b0606"/>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean,
  },

  name: "Arm600.vue",
};
</script>