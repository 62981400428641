<template>
  <g class="foot830">
    <g class="fotRightWrapper">
      <polygon class="fotTop" points="1862.48 4025.97 1143.68 4440.98 1143.68 4248.98 1862.48 3833.97 1862.48 4025.97" fill="#64a7d1"/>
      <g class="bult">
        <g class="boltsWrapper">
          <ellipse cx="2987.81" cy="3116.21" rx="12.86" ry="7.42" transform="translate(-2371.71 5031.39) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2987.81" cy="3020.21" rx="12.86" ry="7.42" transform="translate(-2288.57 4983.39) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2904.67" cy="3164.21" rx="12.86" ry="7.42" transform="translate(-2454.85 4983.39) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2904.67" cy="3068.21" rx="12.86" ry="7.42" transform="translate(-2371.71 4935.39) rotate(-60)" fill="#cfcccc"/>
        </g>
      </g>
    </g>
    <g class="fotTopWrapper">
      <polygon class="fotTop-2" data-name="fotTop" points="1862.48 3833.97 1143.68 4248.97 977.4 4152.97 1696.2 3737.97 1862.48 3833.97" fill="#a2d2ee"/>
      <polygon class="fotTop-3" data-name="fotTop" points="1821.08 3834.08 1143.85 4225.08 1018.79 4152.87 1696.02 3761.87 1821.08 3834.08" fill="#64a7d1"/>
    </g>
      <polygon class="front" points="1143.68 4440.98 977.4 4344.98 977.4 4152.98 1143.68 4248.98 1143.68 4440.98" fill="#88c3e6"/>
  </g>
</template>
<script>
export default {
  name: "Foot830.vue",
};
</script>