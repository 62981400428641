<template>
    <g class="post4000">
        <polygon class="frontside_downtriangle" data-name="frontside downtriangle" points="1649.78 3961.48 1649.78 4012.48 1693.95 3986.98 1649.78 3961.48" fill="#1b7742"/>
        <g class="toplock-layer-21mm" data-name="toplock-layer-21mm">
          <polygon class="innertop" data-name="innertop" points="1893.13 141.98 1693.95 256.98 1649.78 231.48 1848.97 116.48 1893.13 141.98" fill="#1f9456"/>
          <polygon class="innertop" data-name="innertop" points="1862.82 144.48 1698.28 239.48 1680.09 228.98 1844.64 133.98 1862.82 144.48" fill="#083a1f"/>
          <polygon class="frontside" data-name="frontside" points="1844.64 133.98 1844.64 154.98 1862.82 144.48 1844.64 133.98" fill="#1b7742"/>
        </g>
        <polygon class="main-rightside" data-name="main-rightside" points="1693.95 256.98 1893.13 141.98 1893.13 3871.98 1693.95 3986.98 1693.95 256.98" fill="#0b502e"/>
        <polygon class="frontside" data-name="frontside" points="1649.78 231.48 1693.95 256.98 1693.95 3986.98 1649.78 3961.48 1649.78 231.48" fill="#1b7742"/>
    </g>
</template>
<script>
export default {
  name: "Post4000-H.vue",
};
</script>