<template>
    <g class="strut1000">
        <g class="h1250">
          <g class="overstrut-1250"
              v-show="postOpt >= 3500">
            <g class="cross">
              <line class="underline" x1="883.84" y1="1856.34" x2="1708.4" y2="1015.78" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
              <line class="overline" x1="1694.8" y1="2320.93" x2="891.1" y2="553.13" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            </g>
            <g class="strut">
              <polygon class="frontside" points="1732.54 1078.33 866.51 578.33 866.51 518.33 1732.54 1018.33 1732.54 1078.33" fill="#1b7742"/>
              <polygon class="topside" data-name="topside" points="1732.54 1018.33 866.51 518.33 901.15 498.33 1767.18 998.33 1732.54 1018.33" fill="#1f9456"/>
            </g>
          </g>
          <g class="understrut-1250">
            <g class="cross" data-name="cross">
              <line class="underline" data-name="underline" x1="883.38" y1="3166.34" x2="1707.94" y2="2325.78" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
              <line class="overline" data-name="overline" x1="1694.34" y1="3630.93" x2="890.64" y2="1863.13" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            </g>
            <g class="strut" data-name="strut">
              <polygon class="frontside" data-name="frontside" points="1732.08 2388.33 866.05 1888.33 866.05 1828.33 1732.08 2328.33 1732.08 2388.33" fill="#1b7742"/>
              <polygon class="topside" data-name="topside" points="1732.08 2328.33 866.05 1828.33 900.69 1808.33 1766.72 2308.33 1732.08 2328.33" fill="#1f9456"/>
            </g>
            <g class="strut" data-name="strut">
              <polygon class="frontside" data-name="frontside" points="1731.79 3698.33 865.76 3198.33 865.76 3138.33 1731.79 3638.33 1731.79 3698.33" fill="#1b7742"/>
              <polygon class="topside" data-name="topside" points="1731.79 3638.33 865.76 3138.33 900.4 3118.33 1766.43 3618.33 1731.79 3638.33" fill="#1f9456"/>
            </g>
          </g>
        </g>
    </g>
</template>
<script>
export default {
  name: "Strut1000-H.vue",

  computed: {
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
  }
};
</script>