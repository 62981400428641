
<template>
  <div class="dropDownInfoWrapper">
      <img class="smallAddonImg" src="../../assets/Stolpskydd-singnal-230.jpg" alt="stolpskydd signal låg 230mm">
    <p>Skydda maskiner och byggnader från att bli påkörda. Spärra av eller markera.</p><p> Skyddet är gulmålade med svart markering för att synas så bra som möjligt.</p>
  </div>
</template>
<script>
export default {
  name: "StolpskyddSignal.vue",
};
</script>