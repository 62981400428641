<template>
  <g class="d-foot1430">
    <g class="fotRightWrapper-8" data-name="fotRightWrapper">
      <polygon class="fotTop-22" data-name="fotTop" points="2394.68 3718.95 1156.27 4433.95 1156.27 4241.95 2394.68 3526.95 2394.68 3718.95" fill="#64a7d1"/>
      <g class="bult-8" data-name="bult">
        <polygon class="boltBackground-3" data-name="boltBackground" points="1858.62 4028.45 1692.34 4124.45 1692.34 3932.45 1858.62 3836.45 1858.62 4028.45" fill="#64a7d1"/>
        <g class="boltsWrapper-8" data-name="boltsWrapper">
          <ellipse cx="2983.95" cy="3118.68" rx="12.86" ry="7.42" transform="translate(-2375.78 5029.28) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2983.95" cy="3022.68" rx="12.86" ry="7.42" transform="translate(-2292.65 4981.28) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2900.81" cy="3166.68" rx="12.86" ry="7.42" transform="translate(-2458.92 4981.28) rotate(-60)" fill="#cfcccc"/>
          <ellipse cx="2900.81" cy="3070.68" rx="12.86" ry="7.42" transform="translate(-2375.78 4933.28) rotate(-60)" fill="#cfcccc"/>
        </g>
      </g>
    </g>
      <g class="fotTopWrapper-8" data-name="fotTopWrapper">
        <polygon class="fotTop-23" data-name="fotTop" points="2394.68 3526.94 1156.26 4241.94 989.98 4145.94 2228.4 3430.94 2394.68 3526.94" fill="#a2d2ee"/>
        <polygon class="fotTop-24" data-name="fotTop" points="2353.28 3527.04 1156.43 4218.04 1031.38 4145.84 2228.22 3454.84 2353.28 3527.04" fill="#64a7d1"/>
      </g>
      <polygon class="front-8" data-name="front" points="1156.27 4433.95 989.99 4337.95 989.99 4145.95 1156.27 4241.95 1156.27 4433.95" fill="#88c3e6"/>
      <polygon points="2228.2 3454.84 2228.2 3599.91 2353.26 3527.04 2228.2 3454.84" fill="#88c3e6"/>
  </g>
</template>
<script>
export default {
  name: "FootDouble1430.vue",
};
</script>