<template>
    <g class="strut1250">
        <g class="understrutbar" data-name="understrutbar">
            <polygon class="topstrut1250 topside" data-name="topstrut1250 topside" points="1727.74 2923.16 645.21 2298.16 671.19 2283.16 1753.73 2908.16 1727.74 2923.16" fill="#5070af"/>
            <polygon class="frontstrut1250 frontside" data-name="frontstrut1250 frontside" points="1727.74 2953.16 645.21 2328.16 645.21 2298.16 1727.74 2923.16 1727.74 2953.16" fill="#5e7cb7"/>
        </g>
        <g class="strutunderbox" data-name="strutunderbox">
            <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="653.19 2298.58 648.86 2296.08 648.86 2166.08 653.19 2168.58 653.19 2298.58" fill="#5e7cb7"/>
            <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="670.51 2288.57 653.19 2298.57 653.19 2168.57 670.51 2158.57 670.51 2288.57" fill="#365593"/>
            <polygon class="topstag" data-name="topstag" points="670.51 2158.58 653.19 2168.58 648.86 2166.08 666.18 2156.08 670.51 2158.58" fill="#5070af"/>
        </g>
        <g class="strutupperbox" data-name="strutupperbox">
            <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="652.04 579.46 647.71 576.96 647.71 446.96 652.04 449.46 652.04 579.46" fill="#5e7cb7"/>
            <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="669.36 569.45 652.04 579.45 652.04 449.45 669.36 439.45 669.36 569.45" fill="#365593"/>
            <polygon class="topstag" data-name="topstag" points="669.36 439.46 652.04 449.46 647.71 446.96 665.03 436.96 669.36 439.46" fill="#5070af"/>
        </g>
        <g class="overstrutbar" data-name="overstrutbar">
            <polygon class="topstrut1250 top" data-name="topstrut1250 topside" points="1730.24 1052 647.71 427 673.69 412 1756.22 1037 1730.24 1052" fill="#5070af"/>
            <polygon class="frontstrut1250 frontside" data-name="frontstrut1250 frontside" points="1730.24 1082 647.71 457 647.71 427 1730.24 1052 1730.24 1082" fill="#5e7cb7"/>
        </g>
        <line class="downline" data-name="downline" x1="1797.39" y1="1195.51" x2="659.88" y2="2190.9" fill="none" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
        <ellipse class="rightbolt" data-name="rightbolt" cx="1772.69" cy="2727.58" rx="7.57" ry="4.37" transform="translate(-2585.52 2322.68) rotate(-59.37)" fill="#d6dde8"/>
        <line class="upperline" data-name="upperline" x1="659.9" y1="531.96" x2="1797.01" y2="2854.45" fill="#5070af" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
        <ellipse class="rightbolt" data-name="rightbolt" cx="1771.95" cy="1080.1" rx="7.57" ry="4.37" transform="translate(-1168.2 1513.82) rotate(-59.37)" fill="#d6dde8"/>
    </g>
</template>
<script>
export default {
  name: "Strut1250-B.vue",
};
</script>