<template>
    <g class="strut1250">
        <g class="overstrut"
        v-show="postOpt >= 3500" data-name="overstrut">
          <g class="cross" data-name="cross">
            <line class="underline" data-name="underline" x1="668.77" y1="1742.08" x2="1676.06" y2="1004.49" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" data-name="overline" x1="1688.05" y1="2310.71" x2="679.33" y2="434.54" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside-7" data-name="frontside" points="1732.1 1078.35 649.57 453.35 649.57 393.35 1732.1 1018.35 1732.1 1078.35" fill="#1b7742"/>
            <polygon class="topside-11" data-name="topside" points="1732.1 1018.35 649.57 393.35 684.21 373.35 1766.74 998.35 1732.1 1018.35" fill="#1f9456"/>
          </g>
        </g>
        <g class="understrut" data-name="understrut">
          <g class="cross" data-name="cross">
            <line class="underline" data-name="underline" x1="668.75" y1="3052.07" x2="1676.04" y2="2314.48" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" data-name="overline" x1="1688.03" y1="3620.7" x2="679.31" y2="1744.53" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.08 3698.34 649.55 3073.34 649.55 3013.34 1732.08 3638.34 1732.08 3698.34" fill="#1b7742"/>
            <polygon class="topside" data-name="topside" points="1732.08 3638.34 649.55 3013.34 684.19 2993.34 1766.72 3618.34 1732.08 3638.34" fill="#1f9456"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.08 2388.34 649.55 1763.34 649.55 1703.34 1732.08 2328.34 1732.08 2388.34" fill="#1b7742"/>
            <polygon class="topside" data-name="topside" points="1732.08 2328.34 649.55 1703.34 684.19 1683.34 1766.72 2308.34 1732.08 2328.34" fill="#1f9456"/>
          </g>
        </g>
      </g>
</template>
<script>
export default {
  name: "Strut1250-H.vue",

  computed: {
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
  }
};
</script>