<template>
<g class="foot-svg-wrapper">
  <g class="foot685">
      <polygon class="top" data-name="top" points="1814.33 3029.99 1221.11 3372.49 1169.14 3342.49 1762.37 2999.99 1814.33 3029.99" fill="#5070af"/>
      <g class="footfront" data-name="footfront">
        <polygon id="footright685" points="1221.11 3372.5 1814.34 3030 1814.34 3090 1221.11 3432.5 1221.11 3372.5" fill="#365593"/>
        <polygon class="top" data-name="top" points="1221.11 3432.5 1169.14 3402.5 1169.14 3342.5 1221.11 3372.5 1221.11 3432.5" fill="#5070af"/>
        <polygon class="rightside" data-name="rightside" points="1169.14 3342.5 1169.14 3402.5 1221.11 3372.5 1169.14 3342.5" fill="#365593"/>
      </g>
    </g>
    <g class="fotbuild">
      <polygon class="rightside" data-name="rightside" points="1814.34 3029.99 1779.69 3049.99 1779.69 3019.99 1814.34 2999.99 1814.34 3029.99" fill="#365593"/>
      <polygon class="rightside" data-name="rightside" points="1753.73 3065 1779.71 3050 1779.69 3020 1753.73 3065" fill="#365593"/>
      <polyline class="angle" points="1753.73 3065 1701.76 3035 1727.73 2990 1779.69 3020 1753.73 3065" fill="#6383b7"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "Foot685-B.vue",
};
</script>