<template>
<g class="post2500">
      <g class="rightsidehole-2" data-name="rightsidehole">
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 3000 1779.69 3020 1779.69 2915.83 1814.34 2895.83 1814.34 3000" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3498.78" rx="5.05" ry="2.92" transform="translate(-2690.7 3693.55) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2895.83 1779.69 2915.83 1779.69 2811.67 1814.34 2791.67 1814.34 2895.83" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3394.61" rx="5.05" ry="2.92" transform="translate(-2600.85 3642.09) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2791.67 1779.69 2811.67 1779.69 2707.5 1814.34 2687.5 1814.34 2791.67" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,3294.55c-2,1.14-3.59.23-3.59-2a7.9,7.9,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,3294.55Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2687.5 1779.69 2707.5 1779.69 2603.33 1814.34 2583.33 1814.34 2687.5" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3186.28" rx="5.05" ry="2.92" transform="translate(-2421.15 3539.16) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2583.33 1779.69 2603.33 1779.69 2499.17 1814.34 2479.17 1814.34 2583.33" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="3082.11" rx="5.05" ry="2.92" transform="translate(-2331.3 3487.69) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2479.17 1779.69 2499.17 1779.69 2395 1814.34 2375 1814.34 2479.17" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,2982.05c-2,1.14-3.59.23-3.59-2a7.9,7.9,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,2982.05Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2375 1779.69 2395 1779.69 2290.83 1814.34 2270.83 1814.34 2375" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2873.78" rx="5.05" ry="2.92" transform="matrix(0.51, -0.86, 0.86, 0.51, -2151.6, 3384.77)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2270.83 1779.69 2290.83 1779.69 2186.67 1814.34 2166.67 1814.34 2270.83" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2769.61" rx="5.05" ry="2.92" transform="translate(-2061.75 3333.3) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2166.67 1779.69 2186.67 1779.69 2082.5 1814.34 2062.5 1814.34 2166.67" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,2669.55c-2,1.14-3.59.23-3.59-2a7.9,7.9,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,2669.55Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 2062.5 1779.69 2082.5 1779.69 1978.33 1814.34 1958.33 1814.34 2062.5" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2561.28" rx="5.05" ry="2.92" transform="translate(-1882.04 3230.37) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1958.33 1779.69 1978.33 1779.69 1874.17 1814.34 1854.17 1814.34 1958.33" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2457.11" rx="5.05" ry="2.92" transform="translate(-1792.19 3178.91) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1854.17 1779.69 1874.17 1779.69 1770 1814.34 1750 1814.34 1854.17" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,2357.05c-2,1.14-3.59.23-3.59-2a7.9,7.9,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,2357.05Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1750 1779.69 1770 1779.69 1665.83 1814.34 1645.83 1814.34 1750" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2248.78" rx="5.05" ry="2.92" transform="translate(-1612.49 3075.98) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1645.83 1779.69 1665.83 1779.69 1561.67 1814.34 1541.67 1814.34 1645.83" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="2144.61" rx="5.05" ry="2.92" transform="translate(-1522.64 3024.51) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1541.67 1779.69 1561.67 1779.69 1457.5 1814.34 1437.5 1814.34 1541.67" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,2044.55c-2,1.14-3.59.23-3.59-2a7.9,7.9,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,2044.55Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1437.5 1779.69 1457.5 1779.69 1353.33 1814.34 1333.33 1814.34 1437.5" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="1936.28" rx="5.05" ry="2.92" transform="translate(-1342.94 2921.58) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1333.33 1779.69 1353.33 1779.69 1249.17 1814.34 1229.17 1814.34 1333.33" fill="#365593"/>
          <ellipse class="righthole" data-name="righthole" cx="2905.05" cy="1832.11" rx="5.05" ry="2.92" transform="translate(-1253.09 2870.12) rotate(-59.61)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1229.17 1779.69 1249.17 1779.69 1145 1814.34 1125 1814.34 1229.17" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1732.05c-2,1.14-3.59.23-3.59-2a7.89,7.89,0,0,1,3.59-6.17c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,1732.05Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1125 1779.69 1145 1779.69 1040.83 1814.34 1020.83 1814.34 1125" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1627.89c-2,1.14-3.59.23-3.59-2a7.91,7.91,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.9,7.9,0,0,1,2905.05,1627.89Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 1020.83 1779.69 1040.83 1779.69 936.67 1814.34 916.67 1814.34 1020.83" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1523.72c-2,1.14-3.59.23-3.59-2a7.88,7.88,0,0,1,3.59-6.17c2-1.15,3.58-.24,3.58,2A7.89,7.89,0,0,1,2905.05,1523.72Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 916.67 1779.69 936.67 1779.69 832.5 1814.34 812.5 1814.34 916.67" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1419.55c-2,1.14-3.59.23-3.59-2a7.89,7.89,0,0,1,3.59-6.17c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,1419.55Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 812.5 1779.69 832.5 1779.69 728.33 1814.34 708.33 1814.34 812.5" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1315.39c-2,1.14-3.59.23-3.59-2a7.91,7.91,0,0,1,3.59-6.18c2-1.14,3.58-.23,3.58,2A7.9,7.9,0,0,1,2905.05,1315.39Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 708.33 1779.69 728.33 1779.69 624.17 1814.34 604.17 1814.34 708.33" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1211.22c-2,1.14-3.59.23-3.59-2a7.88,7.88,0,0,1,3.59-6.17c2-1.15,3.58-.24,3.58,2A7.89,7.89,0,0,1,2905.05,1211.22Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
        <g>
          <polygon class="rightside" data-name="rightside" points="1814.34 604.17 1779.69 624.17 1779.69 520 1814.34 500 1814.34 604.17" fill="#365593"/>
          <path class="righthole" data-name="righthole" d="M2905.05,1107.05c-2,1.14-3.59.23-3.59-2a7.89,7.89,0,0,1,3.59-6.17c2-1.14,3.58-.23,3.58,2A7.88,7.88,0,0,1,2905.05,1107.05Z" transform="translate(-1108.04 -540.86)" fill="#383838"/>
        </g>
      </g>
      <g class="frontsidehole" data-name="frontsidehole">
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 3020 1727.73 2990 1727.73 2885.83 1779.69 2915.83 1779.69 3020" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,3495.85c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,3495.85Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2915.83 1727.73 2885.83 1727.73 2781.66 1779.69 2811.66 1779.69 2915.83" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,3391.68c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,3391.68Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2811.66 1727.73 2781.66 1727.73 2677.5 1779.69 2707.5 1779.69 2811.66" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,3287.51c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,3287.51Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2707.5 1727.73 2677.5 1727.73 2573.33 1779.69 2603.33 1779.69 2707.5" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,3183.35c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,3183.35Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2603.33 1727.73 2573.33 1727.73 2469.16 1779.69 2499.16 1779.69 2603.33" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,3079.18c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,3079.18Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2499.16 1727.73 2469.16 1727.73 2365 1779.69 2395 1779.69 2499.16" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2975c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,2975Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2395 1727.73 2365 1727.73 2260.83 1779.69 2290.83 1779.69 2395" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2870.85c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,2870.85Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2290.83 1727.73 2260.83 1727.73 2156.66 1779.69 2186.66 1779.69 2290.83" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2766.68c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,2766.68Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2186.66 1727.73 2156.66 1727.73 2052.5 1779.69 2082.5 1779.69 2186.66" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2662.51c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,2662.51Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 2082.5 1727.73 2052.5 1727.73 1948.33 1779.69 1978.33 1779.69 2082.5" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2558.35c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,2558.35Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1978.33 1727.73 1948.33 1727.73 1844.16 1779.69 1874.16 1779.69 1978.33" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2454.18c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,2454.18Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1874.16 1727.73 1844.16 1727.73 1740 1779.69 1770 1779.69 1874.16" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2350c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,2350Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1770 1727.73 1740 1727.73 1635.83 1779.69 1665.83 1779.69 1770" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2245.85c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,2245.85Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1665.83 1727.73 1635.83 1727.73 1531.66 1779.69 1561.66 1779.69 1665.83" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2141.68c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,2141.68Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1561.66 1727.73 1531.66 1727.73 1427.5 1779.69 1457.5 1779.69 1561.66" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,2037.51c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,2037.51Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1457.5 1727.73 1427.5 1727.73 1323.33 1779.69 1353.33 1779.69 1457.5" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1933.35c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,1933.35Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1353.33 1727.73 1323.33 1727.73 1219.16 1779.69 1249.16 1779.69 1353.33" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1829.18c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,1829.18Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1249.16 1727.73 1219.16 1727.73 1115 1779.69 1145 1779.69 1249.16" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1725c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,1725Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1145 1727.73 1115 1727.73 1010.83 1779.69 1040.83 1779.69 1145" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1620.85c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,1620.85Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 1040.83 1727.73 1010.83 1727.73 906.66 1779.69 936.66 1779.69 1040.83" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1516.68c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,1516.68Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 936.66 1727.73 906.66 1727.73 802.5 1779.69 832.5 1779.69 936.66" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1412.51c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,1412.51Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 832.5 1727.73 802.5 1727.73 698.33 1779.69 728.33 1779.69 832.5" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1308.35c0,2.27-1.61,3.18-3.59,2a7.87,7.87,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.9,7.9,0,0,1,2865.33,1308.35Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 728.33 1727.73 698.33 1727.73 594.16 1779.69 624.16 1779.69 728.33" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1204.18c0,2.27-1.61,3.18-3.59,2a7.9,7.9,0,0,1-3.58-6.18c0-2.27,1.6-3.18,3.58-2A7.91,7.91,0,0,1,2865.33,1204.18Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
        <g>
          <polygon class="frontside" data-name="frontside" points="1779.69 624.16 1727.73 594.16 1727.73 490 1779.69 520 1779.69 624.16" fill="#5e7cb7"/>
          <path class="fronthole" data-name="fronthole" d="M2865.33,1100c0,2.27-1.61,3.18-3.59,2a7.88,7.88,0,0,1-3.58-6.17c0-2.27,1.6-3.18,3.58-2A7.89,7.89,0,0,1,2865.33,1100Z" transform="translate(-1108.04 -540.86)" fill="#f9f9f9"/>
        </g>
      </g>
      <g class="top" data-name="top">
        <polygon class="top-post-2" data-name="top-post" points="1779.69 520 1727.73 490 1762.37 470 1814.33 500 1779.69 520" fill="#5070af"/>
        <polygon class="inner-top-post" data-name="inner-top-post" points="1779.69 515 1736.39 490 1762.37 475 1805.67 500 1779.69 515" fill="#5e7cb7"/>
        <polygon class="rightside" data-name="rightside" points="1762.37 475 1762.37 505.04 1736.39 490 1762.37 475" fill="#365593"/>
      </g>
    </g>
</template>
<script>
export default {
  name: "Post2500-B.vue",
};
</script>