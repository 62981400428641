<template>
    <g class="post3500">
        <polygon class="main-rightside" data-name="main-rightside" points="1693.95 756.98 1893.13 641.98 1893.13 3871.98 1693.95 3986.98 1693.95 756.98" fill="#0b502e"/>
        <polygon class="frontside" data-name="frontside" points="1649.78 731.48 1693.95 756.98 1693.95 3986.98 1649.78 3961.48 1649.78 731.48" fill="#1b7742"/>
        <polygon class="frontside downtriangle" data-name="frontside downtriangle" points="1649.78 3961.48 1649.78 4012.48 1693.95 3986.98 1649.78 3961.48" fill="#1b7742"/>
        <g class="toplock-layer-21mm" data-name="toplock-layer-21mm">
          <polygon class="innertop" data-name="innertop" points="1893.13 641.98 1693.95 756.98 1649.78 731.48 1848.97 616.48 1893.13 641.98" fill="#1f9456"/>
          <polygon class="innertop" data-name="innertop" points="1862.82 644.48 1698.28 739.48 1680.09 728.98 1844.64 633.98 1862.82 644.48" fill="#083a1f"/>
          <polygon class="frontside" data-name="frontside" points="1844.64 633.98 1844.64 654.98 1862.82 644.48 1844.64 633.98" fill="#1b7742"/>
        </g>
    </g>
</template>
<script>
export default {
  name: "Post3500-H.vue",
};
</script>