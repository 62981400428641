<template>
<g class="rightside-signal230mm">
    <g class="single-signallag230mm">
      <g class="bar1220mm">
        <g class="platewithcylinder">
          <polygon points="2071.6 4392.88 1920.1 4480.38 1768.5 4392.88 1920.1 4305.38 2071.6 4392.88" fill="#fba61e"/>
          <g class="topcylinder">
            <path d="M1362.56,4392.28v-89.5h133.8v89.9S1362.36,4392.08,1362.56,4392.28Z" transform="translate(490.24)" fill="#f9c138"/>
            <ellipse cx="1919.7" cy="4392.88" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1919.7" cy="4303.98" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="1109.5 4947.88 957.9 5035.38 806.4 4947.88 957.9 4860.38 1109.5 4947.88" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M401.36,4947.28v-89.5h132.8v89.9S401.16,4947.08,401.36,4947.28Z" transform="translate(490.24)" fill="#f9c138"/>
            <ellipse cx="958.4" cy="4947.88" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="957.9" cy="4858.98" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" d="M534.36,4892.88h-133V4749a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2-.1,105.4Z" transform="translate(490.24)" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" points="1220 4657.68 1250.1 4612.57 1215.9 4545.57 1163.9 4597.57 1213.2 4659.07"/>
      <polyline class="blackmark" data-name="blackmark" points="1673.9 4400.68 1703.9 4355.57 1669.8 4288.57 1617.8 4340.48 1667 4402.07"/>
      <polygon class="rightside-sg-hematit" points="1109.5 4957.88 957.9 5045.38 957.9 5035.38 1109.5 4947.88 1109.5 4957.88" fill="#db8914"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="957.9 5045.27 806.4 4957.77 806.4 4947.77 957.9 5035.27 957.9 5045.27" fill="#e8932e"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="1919.9 4490.48 1768.4 4402.98 1768.4 4392.98 1919.9 4480.48 1919.9 4490.48" fill="#e8932e"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="2071.5 4402.88 1920 4490.38 1920 4480.38 2071.5 4392.88 2071.5 4402.88" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean
  },

  name: "HematitSignalSingleRight.vue",
};
</script>