<template>
    <g class="strut2000">
        <g class="strutunderbox">
            <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="8.66 1938.29 4.33 1935.79 4.33 1805.79 8.66 1808.29 8.66 1938.29" fill="#5e7cb7"/>
            <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="25.98 1928.28 8.66 1938.28 8.66 1808.28 25.98 1798.28 25.98 1928.28" fill="#365593"/>
            <polygon class="topstag" points="25.98 1798.28 8.66 1808.28 4.33 1805.78 21.65 1795.78 25.98 1798.28" fill="#5070af"/>
        </g>
        <g class="understrutbar">
            <polygon class="topstrut2000 topside" data-name="topstrut2000 topside" points="1732.05 2925.21 0 1925.21 25.98 1910.21 1758.03 2910.21 1732.05 2925.21" fill="#5070af"/>
            <polygon class="frontstrut2000 frontside" data-name="frontstrut2000 frontside" points="1732.05 2955.2 0 1955.2 0 1925.2 1732.05 2925.2 1732.05 2955.2" fill="#5e7cb7"/>
        </g>
        <line class="downline" x1="1797.01" y1="1195.51" x2="12.13" y2="1826.09" fill="none" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
        <ellipse class="rightbolt" cx="1127.16" cy="2363.83" rx="7.57" ry="4.37" transform="translate(-2589.2 1588.73) rotate(-59.37)" fill="#d6dde8"/>
        <g class="strutupperbox">
            <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="8.59 209.96 4.26 207.46 4.26 77.46 8.59 79.96 8.59 209.96" fill="#5e7cb7"/>
            <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="25.91 199.95 8.59 209.95 8.59 79.95 25.91 69.95 25.91 199.95" fill="#365593"/>
            <polygon class="topstag" data-name="topstag" points="25.91 69.96 8.59 79.96 4.26 77.46 21.58 67.46 25.91 69.96" fill="#5070af"/>
        </g>
        <g class="overstrutbar">
            <polygon class="topstrut2000 top" data-name="topstrut2000 topside" points="1732.29 1053.32 0.24 53.32 26.22 38.32 1758.27 1038.32 1732.29 1053.32" fill="#5070af"/>
            <polygon class="frontstrut2000 frontside" data-name="frontstrut2000 frontside" points="1732.29 1083.31 0.24 83.31 0.24 53.31 1732.29 1053.31 1732.29 1083.31" fill="#5e7cb7"/>
        </g>
        <line class="upperline" x1="15.77" y1="164.05" x2="1797.01" y2="2854.45" fill="#5070af" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
        <ellipse class="rightbolt" data-name="rightbolt" cx="1125.28" cy="707.14" rx="7.57" ry="4.37" transform="matrix(0.51, -0.86, 0.86, 0.51, -1164.5, 774.37)" fill="#d6dde8"/>
    </g>
</template>
<script>
export default {
  name: "Strut2000-B.vue",
};
</script>