<template>
  <g class="strut1000">
      <g class="understrutbar" data-name="understrutbar">
        <polygon class="frontstrut1000 frontside" data-name="frontstrut1000 frontside" points="1732.67 2947.94 866.64 2447.94 866.64 2417.94 1732.67 2917.94 1732.67 2947.94" fill="#5e7cb7"/>
        <polygon class="topstrut1000 topside" data-name="topstrut1000 topside" points="1732.67 2917.94 866.64 2417.94 892.62 2402.94 1758.65 2902.94 1732.67 2917.94" fill="#5070af"/>
      </g>
      <g class="strutupperbox" data-name="strutupperbox">
        <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="873.13 705.27 868.8 702.77 868.8 572.77 873.13 575.27 873.13 705.27" fill="#5e7cb7"/>
        <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="890.45 695.26 873.13 705.26 873.13 575.26 890.45 565.26 890.45 695.26" fill="#365593"/>
        <polygon class="topstag" data-name="topstag" points="890.45 565.26 873.13 575.26 868.8 572.76 886.12 562.76 890.45 565.26" fill="#5070af"/>
      </g>
      <g class="strutunderbox" data-name="strutunderbox">
        <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="874.99 2418.14 870.66 2415.64 870.66 2285.64 874.99 2288.14 874.99 2418.14" fill="#5e7cb7"/>
        <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="892.31 2408.13 874.99 2418.13 874.99 2288.13 892.31 2278.13 892.31 2408.13" fill="#365593"/>
        <polygon class="topstag" data-name="topstag" points="892.31 2278.13 874.99 2288.13 870.66 2285.63 887.98 2275.63 892.31 2278.13" fill="#5070af"/>
      </g>
      <g class="overstrutbar" data-name="overstrutbar">
        <polygon class="frontstrut1000 frontside" data-name="frontstrut1000 frontside" points="1731.98 1080.39 865.96 580.39 865.96 550.39 1731.98 1050.39 1731.98 1080.39" fill="#5e7cb7"/>
        <polygon class="topstrut1000 top" data-name="topstrut1000 topside" points="1731.98 1050.4 865.96 550.4 891.94 535.4 1757.97 1035.4 1731.98 1050.4" fill="#5070af"/>
      </g>
      <line class="upperline" data-name="upperline" x1="881.9" y1="655.55" x2="1793.43" y2="2856.52" fill="#5070af" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
      <line class="downline" data-name="downline" x1="1797.01" y1="1195.51" x2="881.49" y2="2314.45" fill="none" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
      <ellipse class="rightbolt" data-name="rightbolt" cx="1991.25" cy="1203.38" rx="7.57" ry="4.37" transform="translate(-1166.7 1763) rotate(-59.37)" fill="#d6dde8"/>
      <ellipse class="rightbolt" data-name="rightbolt" cx="1993.98" cy="2851.7" rx="7.57" ry="4.37" transform="translate(-2583.77 2573.99) rotate(-59.37)" fill="#d6dde8"/>
    </g>
</template>
<script>
export default {
  name: "Strut1000-B.vue",
};
</script>