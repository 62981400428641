<template>
 <g class="signalSpetsGlans">
 <g class="single-signallag230mm">
    <g class="bar1220mm">
      <g class="platewithcylinder">
        <polygon points="1769.5 4479.2 1618 4566.7 1466.4 4479.2 1618 4391.7 1769.5 4479.2" fill="#fba61e"/>
        <g class="topcylinder">
          <path d="M1550.7,4478.6v-89.5h133.8V4479S1550.5,4478.4,1550.7,4478.6Z" fill="#f9c138"/>
          <ellipse cx="1617.6" cy="4479.2" rx="67.1" ry="38.9" fill="#f9c138"/>
          <ellipse cx="1617.6" cy="4390.3" rx="67.1" ry="38.9" fill="#f9c138"/>
        </g>
      </g>
      <g class="platewithcylinder" data-name="platewithcylinder">
        <polygon points="807.4 5034.2 655.8 5121.7 504.3 5034.2 655.8 4946.7 807.4 5034.2" fill="#fba61e"/>
        <g class="topcylinder-2" data-name="topcylinder">
          <path d="M589.5,5033.6v-89.5H722.3V5034S589.3,5033.4,589.5,5033.6Z" fill="#f9c138"/>
          <ellipse cx="656.3" cy="5034.2" rx="66.5" ry="38.9" fill="#f9c138"/>
          <ellipse cx="655.8" cy="4945.3" rx="66.6" ry="38.9" fill="#f9c138"/>
        </g>
      </g>
      <path class="bar" d="M722.5,4979.2h-133V4835.3a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3L722.6,4873.8v105.4Z" fill="#f9c138"/>
    </g>
    <polyline class="blackmark" points="917.9 4744 948 4698.9 913.8 4631.9 861.8 4683.9 911.1 4745.4"/>
    <polyline class="blackmark" data-name="blackmark" points="1371.8 4487 1401.8 4441.9 1367.7 4374.9 1315.7 4426.8 1364.9 4488.4"/>
    <polygon class="rightside-sg-signal" points="807.4 5044.2 655.8 5131.7 655.8 5121.7 807.4 5034.2 807.4 5044.2" fill="#db8914"/>
    <polygon class="frontside-sg-signal" points="655.8 5131.6 504.3 5044.1 504.3 5034.1 655.8 5121.6 655.8 5131.6" fill="#e8932e"/>
    <polygon class="frontside-sg-signal" data-name="frontside-sg-signal" points="1617.8 4576.8 1466.3 4489.3 1466.3 4479.3 1617.8 4566.8 1617.8 4576.8" fill="#e8932e"/>
    <polygon class="rightside-sg-signal" data-name="rightside-sg-signal" points="1769.4 4489.2 1617.9 4576.7 1617.9 4566.7 1769.4 4479.2 1769.4 4489.2" fill="#db8914"/>
  </g>
  <g v-if="doubleBranch" class="double-signallag230mm">
    <polygon points="3315.8 3586.7 3164.2 3674.2 3012.7 3586.7 3164.2 3499.2 3315.8 3586.7" fill="#fba61e"/>
    <g class="topcylinder" data-name="topcylinder">
      <path d="M3097,3586.1v-89.5h133.8v89.9S3096.8,3585.9,3097,3586.1Z" fill="#f9c138"/>
      <ellipse cx="3163.9" cy="3586.7" rx="67.1" ry="38.9" fill="#f9c138"/>
      <ellipse cx="3163.9" cy="3497.8" rx="67.1" ry="38.9" fill="#f9c138"/>
    </g>
    <polygon points="2353.7 4141.7 2202.1 4229.2 2050.6 4141.7 2202.1 4054.2 2353.7 4141.7" fill="#fba61e"/>
    <g class="topcylinder" data-name="topcylinder">
      <path d="M2135.8,4141.1v-89.5h132.8v89.9S2135.6,4140.9,2135.8,4141.1Z" fill="#f9c138"/>
      <ellipse cx="2202.6" cy="4141.7" rx="66.5" ry="38.9" fill="#f9c138"/>
      <ellipse cx="2202.1" cy="4052.8" rx="66.6" ry="38.9" fill="#f9c138"/>
    </g>
    <path class="bar" data-name="bar" d="M2268.8,4086.7h-133V3942.8a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2v105.4Z" fill="#f9c138"/>
    <polyline class="blackmark-sg-signal" data-name="blackmark" points="2464.2 3851.5 2494.3 3806.4 2460.1 3739.4 2408.1 3791.4 2457.3 3852.9"/>
    <polyline class="blackmark-sg-signal" data-name="blackmark" points="2918.1 3594.5 2948.1 3549.4 2914 3482.4 2862 3534.3 2911.2 3595.9"/>
    <polygon class="rightside-sg-signal" data-name="rightside-sg-signal" points="2353.7 4151.7 2202.1 4239.2 2202.1 4229.2 2353.7 4141.7 2353.7 4151.7" fill="#db8914"/>
    <polygon class="frontside-sg-signal" data-name="frontside-sg-signal" points="2202.1 4239.1 2050.6 4151.6 2050.6 4141.6 2202.1 4229.1 2202.1 4239.1" fill="#e8932e"/>
    <polygon class="frontside-sg-signal" data-name="frontside-sg-signal" points="3164.1 3684.3 3012.6 3596.8 3012.6 3586.8 3164.1 3674.3 3164.1 3684.3" fill="#e8932e"/>
    <polygon class="rightside-sg-signal" data-name="rightside-sg-signal" points="3315.7 3596.7 3164.2 3684.2 3164.2 3674.2 3315.7 3586.7 3315.7 3596.7" fill="#db8914"/>
  </g>
</g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean
  },

  name: "SpetsSignal1220.vue",
};
</script>