<template>

  <g class="arrows">
    <g class="newarrow">
      <g class="lenght-arrow">

        <line class="arrowline"
            :x1="arrowCoords.x1"
            :y1="arrowCoords.y1"
            :x2="arrowCoords.x2"
            :y2="arrowCoords.y2"
            fill="none"
            stroke="#000"
            stroke-miterlimit="10"
            stroke-width="30"/>

        <text id="lenghtLineInfoText"
            :x="arrowPreviewTextCoords.x"
            :y="arrowPreviewTextCoords.y"
            font-size="300px"
            fill="#000">{{arrowPreviewText}}</text>

        <g class="leftarrow">
          <polygon :points="arrowLeftEndPoints"/>
        </g>

        <g class="rightarrow">
          <polygon :points="arrowRightEndPoints"/>
        </g>

        <line class="leftline-arrow"
            :x1="arrowLeftMarkerLineCoords.x1"
            :y1="arrowLeftMarkerLineCoords.y1"
            :x2="arrowLeftMarkerLineCoords.x2"
            :y2="arrowLeftMarkerLineCoords.y2"
            fill="none"
            stroke="#000"
            stroke-miterlimit="10"
            stroke-width="20"/>

        <line class="righline-arrow"
            :x1="arrowRightMarkerLineCoords.x1"
            :y1="arrowRightMarkerLineCoords.y1"
            :x2="arrowRightMarkerLineCoords.x2"
            :y2="arrowRightMarkerLineCoords.y2"
            fill="none"
            stroke="#000"
            stroke-miterlimit="10"
            stroke-width="20"/>
      </g>
    </g>
  </g>
</template>
<script>

import {translateCoords,translateHorizontalCoords} from '../../../helper';

export default {
  props: {
    startX: Number,
    startY: Number,
    totalLength: Number // In millimeters
  },

  computed: {
    arrowCoords: {
      get() {

        let margin = 150;
        let distance = this.totalLength - (margin*2);

        let marginDistance = translateHorizontalCoords( margin );

        let arrowStartX = this.startX + marginDistance.x;
        let arrowStartY = this.startY + marginDistance.y;

        let transCoords = translateHorizontalCoords(distance);

        let coords = {
          x1: arrowStartX,
          x2: arrowStartX + transCoords.x,
          y1: arrowStartY,
          y2: arrowStartY + transCoords.y
        };

        return coords;
      }
    },

    arrowPreviewTextCoords: {
      get() {
        let arrowCoords = this.arrowCoords;

        let arrowMargin = 600;
        let distance = Math.round( this.totalLength / 2 );

        let startX = arrowCoords.x1 - arrowMargin;
        let startY = arrowCoords.y1 + arrowMargin;

        let transCoords = translateHorizontalCoords( distance - 500 );

        let previewTextCoords = {
          x: startX + transCoords.x,
          y: startY + transCoords.y
        }

        return previewTextCoords;
      }
    },

    arrowPreviewText: {
      get() {
        let totalLenghtMillis = this.totalLength;
        let totalLengthMeters = (totalLenghtMillis/1000).toFixed(1).replace(/\./g, ',');
        return totalLengthMeters + " m";
      }
    },

    arrowLeftEndPoints: {
      get() {
        let margin = 100;
        let coords = this.arrowCoords;

        // 395.08 574.28 245.52 315.23 544.65 315.2 395.08 574.28

        let baseX = coords.x1;  // 395
        let baseY = coords.y1;  // 315

        let arrowHorizontalMargin = 150;
        let arrowVerticalMargin = 260;

        baseX -= arrowHorizontalMargin/2;
        baseY -= (arrowVerticalMargin/2);


        let transCoords = translateHorizontalCoords( arrowVerticalMargin - margin );
        baseX += transCoords.x;
        baseY += transCoords.y;


        let points = "";

        points += baseX+" "+(baseY+260);
        points += " "+(baseX-150)+" "+baseY;
        points += " "+(baseX+150)+" "+baseY;
        points += " "+baseX+" "+(baseY+260);

        return points;
      }
    },

    arrowRightEndPoints: {
      get() {
        let margin = 100;
        // 1429.93 824.88 1579.48 1083.92 1280.35 1083.95 1429.93 824.88
        let coords = this.arrowCoords;

        let arrowHorizontalMargin = 150;
        let arrowVerticalMargin = 260;

        let baseX = coords.x2 - (arrowHorizontalMargin);
        let baseY = coords.y2 - (arrowVerticalMargin);  // 825

        let transCoords = translateHorizontalCoords( margin );

        baseX += transCoords.x;
        baseY += transCoords.y;

        let points = "";
        points += baseX+" "+baseY; // 1429.93 824.88
        points += " "+(baseX+150)+" "+(baseY+260); // 1579.48 1083.92
        points += " "+(baseX-150)+" "+(baseY+260); // 1280.35 1083.95
        points += " "+baseX+" "+baseY; // 1429.93 824.88
        return points;
      }
    },

    arrowLeftMarkerLineCoords: {
      get() {
        let distance = 400;

        let baseX = this.startX;
        let baseY = this.startY;

        let transCoords = translateCoords(distance/2, -60);

        // 400 in height
        let x1 = baseX - transCoords.x;
        let x2 = baseX + transCoords.x;
        let y1 = baseY - transCoords.y;
        let y2 = baseY + transCoords.y;

        let coords = {
          x1: x1,
          x2: x2,
          y1: y1,
          y2: y2
        };

        return coords
      }
    },

    arrowRightMarkerLineCoords: {
      get() {
        let distance = 400;

        let transCoords = translateHorizontalCoords(this.totalLength);

        let baseX = this.startX + transCoords.x;
        let baseY = this.startY + transCoords.y;

        let markerTransCoords = translateCoords(distance/2, -60);

        // 400 in height
        let x1 = baseX - markerTransCoords.x;
        let x2 = baseX + markerTransCoords.x;
        let y1 = baseY - markerTransCoords.y;
        let y2 = baseY + markerTransCoords.y;

        return {
          x1: x1,
          x2: x2,
          y1: y1,
          y2: y2
        };
      }
    },
  },

  name: "ArrowLength.vue",
}
</script>