<template>
  <g class="arm400">
      <g class="armholder">
        <polygon class="armholder-front" points="1779.69 2815.72 1727.73 2785.72 1727.73 2665.72 1779.69 2695.72 1779.69 2815.72" fill="#7793bf"/>
        <polygon class="armholder-right" points="1788.36 2810.72 1779.7 2815.72 1779.7 2695.72 1788.36 2690.72 1788.36 2810.72" fill="#3d6199"/>
      </g>
      <polygon class="rightarm400 rightside" data-name="rightarm400 rightside" points="1770.59 2778.47 1424.18 2978.47 1424.18 2938.47 1770.59 2738.47 1770.59 2778.47" fill="#365593"/>
      <polygon class="toparm400 topside" data-name="toparm400 topside" points="1770.59 2738.47 1424.18 2938.47 1389.54 2918.47 1735.95 2718.47 1770.59 2738.47" fill="#5070af"/>
      <g class="armfront">
        <polygon class="innertop topside" data-name="innertop topside" points="1424.18 2978.47 1389.54 2958.47 1389.54 2918.47 1424.18 2938.47 1424.18 2978.47" fill="#5070af"/>
        <polygon class="innerright rightside" data-name="innerright rightside" points="1389.54 2918.47 1389.54 2958.47 1424.18 2938.47 1389.54 2918.47" fill="#365593"/>
      </g>
      <g class="double-arm400" v-if="doubleBranch">
        <polygon class="darm400 rightside" data-name="darm400 rightside" points="1814.35 2753.2 2151.64 2558.47 2151.64 2518.47 1814.33 2713.22 1814.35 2753.2" fill="#3e5c96"/>
        <polygon class="darm400_frontside" data-name="darm400 frontside" points="1814.33 2713.22 2151.64 2518.47 2117 2498.47 1814.35 2673.2 1814.33 2713.22" fill="#5070af"/>
        <polygon class="armholder-right-10px-2" data-name="armholder-right-10px" points="1814.33 2795.44 1805.67 2800.44 1805.67 2680.44 1814.33 2675.44 1814.33 2795.44" fill="#3d6199"/>
      </g>
    </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean,
  },

  name: "Arm400-B.vue",
};
</script>