<template>
  <div class="home">
    <aside class="asideBar">
    <Sidebar/>
</aside>
    <section class="wrapperMaincontent">
    <GraphicArea/>
    <Specification/>
    
    </section>
   
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Specification from '@/components/Specification.vue'
import GraphicArea from '@/components/GraphicArea.vue'

export default {
  name: 'Grenstall',
  components: {
    Sidebar,
    Specification,
    GraphicArea
  },
  
}
</script>

<style scoped>
.home {
  display: flex;
}




</style>