<template>
 <g class="foot2225">
        <g class="leftsidefoot">
          <g class="topside">
            <polygon points="872.53 4680.26 785.93 4730.26 749.56 4709.26 836.16 4659.26 872.53 4680.26" fill="#1f9456"/>
          </g>
          <g class="top-rightside">
            <polygon points="792.86 4684.26 749.56 4709.26 749.56 4688.26 792.86 4663.26 792.86 4684.26" fill="#157b46"/>
          </g>
          <g class="top-rightside" data-name="top-rightside">
            <polygon points="792.85 4435.26 749.55 4460.26 749.55 4439.26 792.85 4414.26 792.85 4435.26" fill="#157b46"/>
          </g>
          <g class="right-backside">
            <polygon points="2712.83 3617.76 785.92 4730.26 785.92 4460.26 2712.83 3347.76 2712.83 3617.76" fill="#083a1f"/>
          </g>
          <g class="topside" data-name="topside">
            <polygon points="2712.83 3347.76 785.92 4460.26 749.55 4439.26 2676.46 3326.76 2712.83 3347.76" fill="#1f9456"/>
          </g>
          <g class="bolt">
            <path d="M2803.35,3640.81l-38.29-22.11a7,7,0,0,1-2.94-5.61v-.18c0-2.16,1.32-3.15,2.94-2.21l38.29,22.11a7,7,0,0,1,2.94,5.6v.18C2806.29,3640.75,2805,3641.74,2803.35,3640.81Z" transform="translate(-1963.6 909.45)" fill="#c9c3c7"/>
            <path d="M2800.7,3636.77l-34.15-19.71a2,2,0,0,1-.83-1.58v-.8c0-.61.37-.89.83-.62l34.15,19.71a2,2,0,0,1,.83,1.58v.8C2801.53,3636.76,2801.16,3637,2800.7,3636.77Z" transform="translate(-1963.6 909.45)" fill="#e5e3e4"/>
          </g>
          <g class="bolt" data-name="bolt">
            <path d="M2803.43,3778.22l-38.3-22.11a7,7,0,0,1-2.93-5.61v-.17c0-2.16,1.31-3.15,2.93-2.22l38.3,22.11a7,7,0,0,1,2.93,5.61v.17C2806.36,3778.17,2805.05,3779.16,2803.43,3778.22Z" transform="translate(-1963.6 909.45)" fill="#c9c3c7"/>
            <path d="M2800.77,3774.19l-34.15-19.72a2,2,0,0,1-.82-1.58v-.79c0-.61.37-.89.82-.63l34.15,19.72a2,2,0,0,1,.83,1.58v.79C2801.6,3774.17,2801.23,3774.45,2800.77,3774.19Z" transform="translate(-1963.6 909.45)" fill="#e5e3e4"/>
          </g>
        </g>
        <g class="rightsidefoot">
          <g class="main-rightside">
            <polygon points="2757 3643.26 830.09 4755.76 830.09 4485.76 2757 3373.26 2757 3643.26" fill="#0b502e"/>
          </g>
          <g class="topside" data-name="topside">
            <polygon points="2793.37 3664.26 866.46 4776.76 830.09 4755.76 2757 3643.26 2793.37 3664.26" fill="#1f9456"/>
          </g>
          <g class="topside" data-name="topside">
            <polygon points="2793.37 3394.26 866.47 4506.76 830.09 4485.76 2757 3373.26 2793.37 3394.26" fill="#1f9456"/>
          </g>
          <g class="top-rightside" data-name="top-rightside">
            <polygon points="2793.37 3415.26 866.47 4527.76 866.47 4506.76 2793.37 3394.26 2793.37 3415.26" fill="#157b46"/>
          </g>
          <g class="top-rightside" data-name="top-rightside">
            <polygon points="2793.37 3664.26 866.46 4776.76 866.46 4755.76 2793.37 3643.26 2793.37 3664.26" fill="#157b46"/>
          </g>
        </g>
      </g>
</template>
<script>
export default {
  name: "Foot2225-H.vue",
};
</script>