<template>
  <g class="strut2000">
    <polygon class="cross-7" data-name="cross" points="1558 1949.97 20.43 2121.22 7.78 2089.42 1545.34 1918.17 1558 1949.97" fill="#7db0c9"/>
    <polygon class="cross-8" data-name="cross" points="19.44 1039.05 1558.94 2983.09 1546.33 3000.34 6.83 1056.29 19.44 1039.05" fill="#7db0c9"/>
    <ellipse class="centerNut-4" data-name="centerNut" cx="1949.79" cy="1133.93" rx="6.92" ry="11.99" transform="translate(-1472.65 2012.58) rotate(-30)" fill="#c7d0d3"/>
    <polygon class="bottomBeam-4" data-name="bottomBeam" points="1565.77 3039.86 0 2135.86 0 2055.86 1565.77 2959.86 1565.77 3039.86" fill="#88c3e6"/>
    <polygon class="topBeam-4" data-name="topBeam" points="1565.77 1999.87 0 1095.87 0 1015.87 1565.77 1919.87 1565.77 1999.87" fill="#88c3e6"/>
    <polygon class="top-3" data-name="top" points="1565.77 2960.23 0 2056.23 13.37 2048.51 1579.14 2952.51 1565.77 2960.23" fill="#7db0c9"/>
    <polygon class="top-4" data-name="top" points="1565.77 1919.84 0 1015.84 13.37 1008.12 1579.14 1912.12 1565.77 1919.84" fill="#7db0c9"/>
  </g>
</template>
<script>
export default {
  name: "Strut2000",
};
</script>
