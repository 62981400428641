<template>
<g class="leftside-signal230mm">
    <g class="single-signallag230mm" data-name="single-signallag230mm">
      <g class="bar1220mm" data-name="bar1220mm">
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="1265.2 3911.68 1113.7 3999.18 962.1 3911.68 1113.7 3824.18 1265.2 3911.68" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M556.16,3911.08v-89.5H690v89.9S556,3910.88,556.16,3911.08Z" transform="translate(490.24)" fill="#f9c138"/>
            <ellipse cx="1113.3" cy="3911.68" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1113.3" cy="3822.78" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="303.1 4466.68 151.5 4554.18 0 4466.68 151.5 4379.18 303.1 4466.68" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M-405,4466.08v-89.5h132.8v89.9S-405.24,4465.88-405,4466.08Z" transform="translate(490.24)" fill="#f9c138"/>
            <ellipse cx="152" cy="4466.68" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="151.5" cy="4377.78" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" data-name="bar" d="M-272,4411.68H-405v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2-.1,105.4Z" transform="translate(490.24)" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" data-name="blackmark" points="413.6 4176.48 443.7 4131.38 409.5 4064.38 357.5 4116.38 406.8 4177.88"/>
      <polyline class="blackmark" data-name="blackmark" points="867.5 3919.47 897.5 3874.38 863.4 3807.38 811.4 3859.28 860.6 3920.88"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="303.1 4476.68 151.5 4564.18 151.5 4554.18 303.1 4466.68 303.1 4476.68" fill="#db8914"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="151.5 4564.07 0 4476.57 0 4466.57 151.5 4554.07 151.5 4564.07" fill="#e8932e"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="1113.5 4009.28 962 3921.78 962 3911.78 1113.5 3999.28 1113.5 4009.28" fill="#e8932e"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="1265.1 3921.68 1113.6 4009.18 1113.6 3999.18 1265.1 3911.68 1265.1 3921.68" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean
  },

  name: "HematitSignalSingleLeft.vue",
};
</script>