<template>
  <g class="strut2000">
        <g class="overstrut"
            v-show="postOpt >= 3500"
        >
          <g class="cross" data-name="cross">
            <line class="underline" data-name="underline" x1="19.65" y1="1366.17" x2="1732.05" y2="1020" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" data-name="overline" x1="1705.27" y1="2313.57" x2="34.64" y2="59.16" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.05 1080 0 80 0 20 1732.05 1020 1732.05 1080" fill="#1b7742"/>
            <polygon class="topside" data-name="topside" points="1732.05 1020 0 20 34.64 0 1766.69 1000 1732.05 1020" fill="#1f9456"/>
          </g>
        </g>
        <g class="understrut">
          <g class="cross" data-name="cross">
            <line class="underline" data-name="underline" x1="19.65" y1="2675.33" x2="1732.06" y2="2329.16" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" data-name="overline" x1="1705.27" y1="3622.73" x2="34.65" y2="1368.32" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.06 3698.32 0.01 2698.32 0.01 2638.32 1732.06 3638.32 1732.06 3698.32" fill="#1b7742"/>
            <polygon class="topside" data-name="topside" points="1732.06 3638.32 0.01 2638.32 34.65 2618.32 1766.7 3618.32 1732.06 3638.32" fill="#1f9456"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside" data-name="frontside" points="1732.06 2389.16 0.01 1389.16 0.01 1329.16 1732.06 2329.16 1732.06 2389.16" fill="#1b7742"/>
            <polygon class="topside" data-name="topside" points="1732.06 2329.16 0.01 1329.16 34.65 1309.16 1766.7 2309.16 1732.06 2329.16" fill="#1f9456"/>
          </g>
        </g>
    </g>
</template>
<script>
export default {
  name: "Strut2000-H.vue",

  computed: {
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
  }
};
</script>