<template>
  <g class="leftside-signal230mm">
    <g class="single-signallag230mm" data-name="single-signallag230mm">
      <g class="bar1220mm" data-name="bar1220mm">
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="1265.2 4028.08 1113.7 4115.58 962.1 4028.08 1113.7 3940.58 1265.2 4028.08" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M971.35,4027.48V3938h133.8v89.9S971.15,4027.28,971.35,4027.48Z" transform="translate(75.05)" fill="#f9c138"/>
            <ellipse cx="1113.3" cy="4028.08" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1113.3" cy="3939.18" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="303.1 4583.08 151.5 4670.58 0 4583.08 151.5 4495.58 303.1 4583.08" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M10.15,4582.48V4493H143v89.9S10,4582.28,10.15,4582.48Z" transform="translate(75.05)" fill="#f9c138"/>
            <ellipse cx="152" cy="4583.08" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="151.5" cy="4494.18" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" data-name="bar" d="M143.15,4528.08h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2-.1,105.4Z" transform="translate(75.05)" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" data-name="blackmark" points="413.6 4292.88 443.7 4247.78 409.5 4180.78 357.5 4232.78 406.8 4294.28"/>
      <polyline class="blackmark" data-name="blackmark" points="867.5 4035.88 897.5 3990.78 863.4 3923.78 811.4 3975.68 860.6 4037.28"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="303.1 4593.08 151.5 4680.58 151.5 4670.58 303.1 4583.08 303.1 4593.08" fill="#db8914"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="151.5 4680.48 0 4592.98 0 4582.98 151.5 4670.48 151.5 4680.48" fill="#e8932e"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="1113.5 4125.68 962 4038.18 962 4028.18 1113.5 4115.68 1113.5 4125.68" fill="#e8932e"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="1265.1 4038.08 1113.6 4125.58 1113.6 4115.58 1265.1 4028.08 1265.1 4038.08" fill="#db8914"/>
    </g>
    <g  class="double-signallag230mm" data-name="double-signallag230mm">
      <polygon points="2811.5 3135.58 2659.9 3223.08 2508.4 3135.58 2659.9 3048.08 2811.5 3135.58" fill="#fba61e"/>
      <g class="topcylinder" data-name="topcylinder">
        <path d="M2517.65,3135v-89.5h133.8v89.9S2517.45,3134.78,2517.65,3135Z" transform="translate(75.05)" fill="#f9c138"/>
        <ellipse cx="2659.6" cy="3135.58" rx="67.1" ry="38.9" fill="#f9c138"/>
        <ellipse cx="2659.6" cy="3046.68" rx="67.1" ry="38.9" fill="#f9c138"/>
      </g>
      <polygon points="1849.4 3690.58 1697.8 3778.08 1546.3 3690.58 1697.8 3603.08 1849.4 3690.58" fill="#fba61e"/>
      <g class="topcylinder" data-name="topcylinder">
        <path d="M1556.45,3690v-89.5h132.8v89.9S1556.25,3689.78,1556.45,3690Z" transform="translate(75.05)" fill="#f9c138"/>
        <ellipse cx="1698.3" cy="3690.58" rx="66.5" ry="38.9" fill="#f9c138"/>
        <ellipse cx="1697.8" cy="3601.68" rx="66.6" ry="38.9" fill="#f9c138"/>
      </g>
      <path class="bar" data-name="bar" d="M1689.45,3635.58h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2v105.4Z" transform="translate(75.05)" fill="#f9c138"/>
      <polyline class="blackmark" data-name="blackmark" points="1959.9 3400.38 1990 3355.28 1955.8 3288.28 1903.8 3340.28 1953 3401.78"/>
      <polyline class="blackmark" data-name="blackmark" points="2413.8 3143.38 2443.8 3098.28 2409.7 3031.28 2357.7 3083.18 2406.9 3144.78"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="1849.4 3700.58 1697.8 3788.08 1697.8 3778.08 1849.4 3690.58 1849.4 3700.58" fill="#db8914"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="1697.8 3787.98 1546.3 3700.48 1546.3 3690.48 1697.8 3777.98 1697.8 3787.98" fill="#e8932e"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="2659.8 3233.18 2508.3 3145.68 2508.3 3135.68 2659.8 3223.18 2659.8 3233.18" fill="#e8932e"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="2811.4 3145.58 2659.9 3233.08 2659.9 3223.08 2811.4 3135.58 2811.4 3145.58" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "HematitSignalDoubleLeft.vue",
};
</script>