<template>
  <g class="post2432">
      <g class="rightSide-3" data-name="rightSide">
        <g class="holeSection-3" data-name="holeSection">
          <g>
            <polygon points="1821.17 3433.64 1654.89 3529.64 1654.89 3458.87 1821.17 3362.87 1821.17 3433.64" fill="#64a7d1"/>
            <polygon points="1699.93 3499.92 1675.7 3513.89 1675.68 3488.45 1699.9 3474.47 1699.93 3499.92" fill="#010015"/>
            <polygon points="1800.36 3441.92 1776.14 3455.92 1776.16 3430.45 1800.38 3416.45 1800.36 3441.92" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3362.87 1654.89 3458.87 1654.89 3388.1 1821.17 3292.1 1821.17 3362.87" fill="#64a7d1"/>
            <polygon points="1699.93 3429.15 1675.7 3443.13 1675.68 3417.68 1699.9 3403.7 1699.93 3429.15" fill="#010015"/>
            <polygon points="1800.36 3371.15 1776.14 3385.15 1776.16 3359.68 1800.38 3345.68 1800.36 3371.15" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3292.1 1654.89 3388.1 1654.89 3317.33 1821.17 3221.33 1821.17 3292.1" fill="#64a7d1"/>
            <polygon points="1699.93 3358.38 1675.7 3372.36 1675.68 3346.91 1699.9 3332.93 1699.93 3358.38" fill="#010015"/>
            <polygon points="1800.36 3300.38 1776.14 3314.38 1776.16 3288.91 1800.38 3274.91 1800.36 3300.38" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3221.33 1654.89 3317.33 1654.89 3246.57 1821.17 3150.56 1821.17 3221.33" fill="#64a7d1"/>
            <polygon points="1699.93 3287.61 1675.7 3301.59 1675.68 3276.14 1699.9 3262.16 1699.93 3287.61" fill="#010015"/>
            <polygon points="1800.36 3229.61 1776.14 3243.61 1776.16 3218.14 1800.38 3204.14 1800.36 3229.61" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3150.56 1654.89 3246.56 1654.89 3175.8 1821.17 3079.8 1821.17 3150.56" fill="#64a7d1"/>
            <polygon points="1699.93 3216.84 1675.7 3230.82 1675.68 3205.37 1699.9 3191.4 1699.93 3216.84" fill="#010015"/>
            <polygon points="1800.36 3158.84 1776.14 3172.84 1776.16 3147.37 1800.38 3133.37 1800.36 3158.84" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3079.8 1654.89 3175.8 1654.89 3105.03 1821.17 3009.03 1821.17 3079.8" fill="#64a7d1"/>
            <polygon points="1699.93 3146.08 1675.7 3160.05 1675.68 3134.6 1699.9 3120.63 1699.93 3146.08" fill="#010015"/>
            <polygon points="1800.36 3088.07 1776.14 3102.08 1776.16 3076.6 1800.38 3062.6 1800.36 3088.07" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 3009.03 1654.89 3105.03 1654.89 3034.26 1821.17 2938.26 1821.17 3009.03" fill="#64a7d1"/>
            <polygon points="1699.93 3075.31 1675.7 3089.28 1675.68 3063.83 1699.9 3049.86 1699.93 3075.31" fill="#010015"/>
            <polygon points="1800.36 3017.31 1776.14 3031.31 1776.16 3005.83 1800.38 2991.83 1800.36 3017.31" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2938.26 1654.89 3034.26 1654.89 2963.49 1821.17 2867.49 1821.17 2938.26" fill="#64a7d1"/>
            <polygon points="1699.93 3004.54 1675.7 3018.51 1675.68 2993.06 1699.9 2979.09 1699.93 3004.54" fill="#010015"/>
            <polygon points="1800.36 2946.54 1776.14 2960.54 1776.16 2935.06 1800.38 2921.06 1800.36 2946.54" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2867.49 1654.89 2963.49 1654.89 2892.72 1821.17 2796.72 1821.17 2867.49" fill="#64a7d1"/>
            <polygon points="1699.93 2933.77 1675.7 2947.74 1675.68 2922.29 1699.9 2908.32 1699.93 2933.77" fill="#010015"/>
            <polygon points="1800.36 2875.77 1776.14 2889.77 1776.16 2864.29 1800.38 2850.29 1800.36 2875.77" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2796.72 1654.89 2892.72 1654.89 2821.95 1821.17 2725.95 1821.17 2796.72" fill="#64a7d1"/>
            <polygon points="1699.93 2863 1675.7 2876.97 1675.68 2851.52 1699.9 2837.55 1699.93 2863" fill="#010015"/>
            <polygon points="1800.36 2805 1776.14 2819 1776.16 2793.52 1800.38 2779.52 1800.36 2805" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2725.95 1654.89 2821.95 1654.89 2751.18 1821.17 2655.18 1821.17 2725.95" fill="#64a7d1"/>
            <polygon points="1699.93 2792.23 1675.7 2806.2 1675.68 2780.75 1699.9 2766.78 1699.93 2792.23" fill="#010015"/>
            <polygon points="1800.36 2734.23 1776.14 2748.23 1776.16 2722.75 1800.38 2708.75 1800.36 2734.23" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2655.18 1654.89 2751.18 1654.89 2680.41 1821.17 2584.41 1821.17 2655.18" fill="#64a7d1"/>
            <polygon points="1699.93 2721.46 1675.7 2735.43 1675.68 2709.98 1699.9 2696.01 1699.93 2721.46" fill="#010015"/>
            <polygon points="1800.36 2663.46 1776.14 2677.46 1776.16 2651.98 1800.38 2637.98 1800.36 2663.46" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2584.41 1654.89 2680.41 1654.89 2609.64 1821.17 2513.64 1821.17 2584.41" fill="#64a7d1"/>
            <polygon points="1699.93 2650.69 1675.7 2664.66 1675.68 2639.21 1699.9 2625.24 1699.93 2650.69" fill="#010015"/>
            <polygon points="1800.36 2592.69 1776.14 2606.69 1776.16 2581.21 1800.38 2567.21 1800.36 2592.69" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2513.64 1654.89 2609.64 1654.89 2538.87 1821.17 2442.87 1821.17 2513.64" fill="#64a7d1"/>
            <polygon points="1699.93 2579.92 1675.7 2593.89 1675.68 2568.45 1699.9 2554.47 1699.93 2579.92" fill="#010015"/>
            <polygon points="1800.36 2521.92 1776.14 2535.92 1776.16 2510.45 1800.38 2496.45 1800.36 2521.92" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2442.87 1654.89 2538.87 1654.89 2468.1 1821.17 2372.1 1821.17 2442.87" fill="#64a7d1"/>
            <polygon points="1699.93 2509.15 1675.7 2523.13 1675.68 2497.68 1699.9 2483.7 1699.93 2509.15" fill="#010015"/>
            <polygon points="1800.36 2451.15 1776.14 2465.15 1776.16 2439.68 1800.38 2425.68 1800.36 2451.15" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2372.1 1654.89 2468.1 1654.89 2397.33 1821.17 2301.33 1821.17 2372.1" fill="#64a7d1"/>
            <polygon points="1699.93 2438.38 1675.7 2452.36 1675.68 2426.91 1699.9 2412.93 1699.93 2438.38" fill="#010015"/>
            <polygon points="1800.36 2380.38 1776.14 2394.38 1776.16 2368.91 1800.38 2354.91 1800.36 2380.38" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2301.33 1654.89 2397.33 1654.89 2326.57 1821.17 2230.57 1821.17 2301.33" fill="#64a7d1"/>
            <polygon points="1699.93 2367.61 1675.7 2381.59 1675.68 2356.14 1699.9 2342.16 1699.93 2367.61" fill="#010015"/>
            <polygon points="1800.36 2309.61 1776.14 2323.61 1776.16 2298.14 1800.38 2284.14 1800.36 2309.61" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2230.56 1654.89 2326.57 1654.89 2255.8 1821.17 2159.8 1821.17 2230.56" fill="#64a7d1"/>
            <polygon points="1699.93 2296.84 1675.7 2310.82 1675.68 2285.37 1699.9 2271.4 1699.93 2296.84" fill="#010015"/>
            <polygon points="1800.36 2238.84 1776.14 2252.84 1776.16 2227.37 1800.38 2213.37 1800.36 2238.84" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2159.8 1654.89 2255.8 1654.89 2185.03 1821.17 2089.03 1821.17 2159.8" fill="#64a7d1"/>
            <polygon points="1699.93 2226.08 1675.7 2240.05 1675.68 2214.6 1699.9 2200.63 1699.93 2226.08" fill="#010015"/>
            <polygon points="1800.36 2168.07 1776.14 2182.08 1776.16 2156.6 1800.38 2142.6 1800.36 2168.07" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2089.03 1654.89 2185.03 1654.89 2114.26 1821.17 2018.26 1821.17 2089.03" fill="#64a7d1"/>
            <polygon points="1699.93 2155.31 1675.7 2169.28 1675.68 2143.83 1699.9 2129.86 1699.93 2155.31" fill="#010015"/>
            <polygon points="1800.36 2097.31 1776.14 2111.31 1776.16 2085.83 1800.38 2071.83 1800.36 2097.31" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 2018.26 1654.89 2114.26 1654.89 2043.49 1821.17 1947.49 1821.17 2018.26" fill="#64a7d1"/>
            <polygon points="1699.93 2084.54 1675.7 2098.51 1675.68 2073.06 1699.9 2059.09 1699.93 2084.54" fill="#010015"/>
            <polygon points="1800.36 2026.54 1776.14 2040.54 1776.16 2015.06 1800.38 2001.06 1800.36 2026.54" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1947.49 1654.89 2043.49 1654.89 1972.72 1821.17 1876.72 1821.17 1947.49" fill="#64a7d1"/>
            <polygon points="1699.93 2013.77 1675.7 2027.74 1675.68 2002.29 1699.9 1988.32 1699.93 2013.77" fill="#010015"/>
            <polygon points="1800.36 1955.77 1776.14 1969.77 1776.16 1944.29 1800.38 1930.29 1800.36 1955.77" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1876.72 1654.89 1972.72 1654.89 1901.95 1821.17 1805.95 1821.17 1876.72" fill="#64a7d1"/>
            <polygon points="1699.93 1943 1675.7 1956.97 1675.68 1931.52 1699.9 1917.55 1699.93 1943" fill="#010015"/>
            <polygon points="1800.36 1885 1776.14 1899 1776.16 1873.52 1800.38 1859.52 1800.36 1885" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1805.95 1654.89 1901.95 1654.89 1831.18 1821.17 1735.18 1821.17 1805.95" fill="#64a7d1"/>
            <polygon points="1699.93 1872.23 1675.7 1886.2 1675.68 1860.75 1699.9 1846.78 1699.93 1872.23" fill="#010015"/>
            <polygon points="1800.36 1814.23 1776.14 1828.23 1776.16 1802.75 1800.38 1788.75 1800.36 1814.23" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1735.18 1654.89 1831.18 1654.89 1760.41 1821.17 1664.41 1821.17 1735.18" fill="#64a7d1"/>
            <polygon points="1699.93 1801.46 1675.7 1815.43 1675.68 1789.98 1699.9 1776.01 1699.93 1801.46" fill="#010015"/>
            <polygon points="1800.36 1743.46 1776.14 1757.46 1776.16 1731.98 1800.38 1717.98 1800.36 1743.46" fill="#010015"/>
          </g>
          <g>
            <polygon points="1821.17 1664.41 1654.89 1760.41 1654.89 1689.64 1821.17 1593.64 1821.17 1664.41" fill="#64a7d1"/>
            <polygon points="1699.93 1730.69 1675.7 1744.66 1675.68 1719.21 1699.9 1705.24 1699.93 1730.69" fill="#010015"/>
            <polygon points="1800.36 1672.69 1776.14 1686.69 1776.16 1661.21 1800.38 1647.21 1800.36 1672.69" fill="#010015"/>
          </g>
        </g>
        <polygon class="rightSideBottom-3" data-name="rightSideBottom" points="1821.17 3833.64 1654.89 3929.64 1654.89 3529.64 1821.17 3433.64 1821.17 3833.64" fill="#64a7d1"/>
      </g>
      <g class="frontParts-3" data-name="frontParts">
        <polygon class="frontPost-3" data-name="frontPost" points="1654.89 3930.07 1526.72 3856.07 1526.72 1616.07 1654.89 1690.07 1654.89 3930.07" fill="#88c3e6"/>
        <polygon class="frontTriangle-3" data-name="frontTriangle" points="1526.72 3856.07 1526.72 4004.51 1654.89 3929.64 1526.72 3856.07" fill="#88c3e6"/>
      </g>
      <g class="topPost-3" data-name="topPost">
        <polygon class="top-post-inner-3" data-name="top-post-inner" points="1654.89 1690.08 1526.72 1616.08 1693 1520.08 1821.17 1594.08 1654.89 1690.08" fill="#a2d2ee"/>
        <polygon class="top-post-outer-3" data-name="top-post-outer" points="1653.16 1667.08 1566.56 1617.08 1694.73 1543.08 1781.33 1593.08 1653.16 1667.08" fill="#64a7d1"/>
        <polygon class="top-post-front" points="1694.73 1543.08 1694.73 1643.08 1781.33 1593.08 1694.73 1543.08" fill="#88c3e6"/>
      </g>
    </g> 
</template>
<script>
export default {
  name: "Post2432.vue",
};
</script>