<template>
 <g class="strut1000">
      <polygon class="cross" points="1524.99 1936.63 837.84 2598.87 832.19 2571.11 1519.33 1908.87 1524.99 1936.63" fill="#7db0c9"/>
      <polygon class="cross-2" data-name="cross" points="837.4 1517.01 1525.41 2969.45 1519.77 2990.72 831.77 1538.29 837.4 1517.01" fill="#7db0c9"/>
      <ellipse class="centerNut" cx="2345.49" cy="1368.1" rx="3.68" ry="10.07" transform="translate(-1286.01 1114.23) rotate(-5.44)" fill="#c7d0d3"/>
      <polygon class="bottomBeam" points="1528.46 3024.04 828.71 2620.04 828.71 2540.04 1528.46 2944.04 1528.46 3024.04" fill="#88c3e6"/>
      <polygon class="topBeam" points="1528.46 1984.04 828.71 1580.04 828.71 1500.04 1528.46 1904.04 1528.46 1984.04" fill="#88c3e6"/>
      <polygon class="top" points="1528.46 1904.04 828.71 1500.04 842.08 1492.32 1541.83 1896.32 1528.46 1904.04" fill="#7db0c9"/>
      <polygon class="top-2" data-name="top" points="1528.46 2944.05 828.71 2540.05 842.08 2532.33 1541.83 2936.33 1528.46 2944.05" fill="#7db0c9"/>
    </g>
</template>
<script>
export default {
  name: "Strut1000.vue",
};
</script>
