import Vue from 'vue'
import Vuex from 'vuex'
import serverInfo from './../serverinfo/'
import {translateDepthCoords, translateHorizontalCoords, showErrorMessage} from '../helper';

// Added jquery
import $ from "jquery";

window.$ = $;

Vue.use(Vuex)

const SERVER_BASE_URL = serverInfo.SERVER_BASE;

function resetSelectedArmPlanes(state) {
	// We reset this based on the maximum number of arm planes allowed
	state.dataArmPlanes = calculateMaxArmPlanes(state);
}

/**
 *
 * @param state
 */
function setFootPostOpt(state) {
	// Sets the post foot for the brand Spetsglans
	let flength = "";

	if (state.selectedBrand === "Spetsglans") {
		let carryingArm = state.selectedCarryingArm;

		if (!carryingArm) {
			state.postFootLength = "";
			return;
		}

		switch (carryingArm.length) {
			case 600:
				flength = state.doubleBranch ? "1430" : "830";
				break;
			case 1000:
				flength = state.doubleBranch ? "2230" : "1230";
				break;
			case 1500:
				flength = state.doubleBranch ? "3230" : "1830";
				break;
			default:
				break;
		}

		state.postFootLength = flength;
	} else if (state.selectedBrand === "Hematit")
		state.postFootLength = state.doubleBranch ? "2225" : "1425";
	else if (state.selectedBrand === "Brucit")
		state.postFootLength = state.doubleBranch ? "1300" : "685";

}

/**
 *
 * @param desiredLengthMeters
 * @param stagLength
 * @returns {number}
 */
function countBranchStands(desiredLengthMeters, stagLength) {
	let desiredLength = desiredLengthMeters * 1000;

	if (!desiredLength)
		return 0;

	let stagSectionCount = Math.ceil(desiredLength / stagLength);

	// The number of posts will be equal to the number of stags + 1. If we have 3 posts we have only 2 stags in between :)
	return stagSectionCount + 1;
}

/**
 *
 * @param state
 * @returns {number} in millimeters
 */
function calculateTotalLength(state) {
	let stagSect = state.selectedStagSection;

	if (!stagSect) {
		return 0;
	}

	let stagWidth = stagSect.width;
	let branchStandCount = countBranchStands(state.desiredSectionLength, stagWidth)

	let stagSectCount = branchStandCount - 1;
	let totalLength = (stagSectCount * stagWidth);

	// We add the postWidth also, since the stagwidth is cc 2 halvs of one post length, or 1 post length is added

	totalLength += state.postWidth;

	// Add extra if applicable
	if (state.includeUprightProtectors)
		totalLength += state.uprightProtectorBonusLength;

	return totalLength;
}

function getSelectedPostStand(state) {
	if (!state.selectedCarryingArm)
		return null;

	let selectedBranchStand = null;
	let carryingArm = state.selectedCarryingArm;
	let availablePosts = [];

	for (let i = 0; i < state.dataPostOptions.length; i++) {
		if (state.selectedBrand !== state.dataAvailablePosts[i].brand)
			continue;

		availablePosts = state.dataAvailablePosts[i];
		break;
	}

	if (state.selectedBrand === "Spetsglans") {
		mainLoop: for (let y = 0; y < availablePosts.options.length; y++) {
			let postHeightOpt = availablePosts.options[y];

			if (postHeightOpt.height !== state.selectedPostOpt)
				continue;

			for (let z = 0; z < postHeightOpt.options.length; z++) {
				let capacityOpt = postHeightOpt.options[z];

				if (capacityOpt.max_capacity !== carryingArm.post_capacity)
					continue;

				// Too many nested loops but this is necessary for getting the article along with it's capacity
				for (let d = 0; d < capacityOpt.variants.length; d++) {
					let branchStand = capacityOpt.variants[d];

					if (branchStand.double_sided === state.doubleBranch) {
						selectedBranchStand = branchStand;
						selectedBranchStand.capacity = capacityOpt.max_capacity;
						break mainLoop;
					}
				}
			}
		}
	} else if (state.selectedBrand === "Brucit") {
		mainLoop: for (let i = 0; i < availablePosts.options.length; i++) {
			let postOpt = availablePosts.options[i];

			if (postOpt.height !== state.selectedPostOpt)
				continue;

			for (let x = 0; x < postOpt.options.length; x++) {
				// Each capacity only has one variant for Brucit so all we have to do is to check
				// If the selected variant is doublesided or not since there will be only 2 variants
				let capacityOpt = postOpt.options[x];

				if (capacityOpt.variants[0].double_sided === state.doubleBranch) {
					selectedBranchStand = capacityOpt.variants[0];
					selectedBranchStand.capacity = capacityOpt.max_capacity;

					break mainLoop;
				}
			}
		}
	} else if (state.selectedBrand === "Hematit") {
		mainLoop: for (let i = 0; i < availablePosts.options.length; i++) {
			let postOpt = availablePosts.options[i];

			if (postOpt.height !== state.selectedPostOpt)
				continue;

			let validCapacity = carryingArm.allowed_post_capacity;
			let allowedPostCapacities = validCapacity.split(",");

			for (let x = 0; x < postOpt.options.length; x++) {
				let capacityOpt = postOpt.options[x];
				let postMaxCapacity = capacityOpt.max_capacity;

				if (!allowedPostCapacities.includes(postMaxCapacity + ""))
					continue;

				for (let v = 0; v < capacityOpt.variants.length; v++) {
					let postVar = capacityOpt.variants[v];
					if (postVar.double_sided !== state.doubleBranch)
						continue;

					selectedBranchStand = postVar;
					selectedBranchStand.capacity = postMaxCapacity;
					break;
				}

				break mainLoop;
			}
		}
	}

	return selectedBranchStand;
}

function calculateCapacityPerSide(state) {
	let selectedArm = state.selectedCarryingArm;
	let stagSect = state.selectedStagSection;

	if (!selectedArm || !stagSect)
		return 0;

	let branchCount = countBranchStands(state.desiredSectionLength, stagSect.width);
	let armCount = state.dataArmPlanes * branchCount;
	return armCount * selectedArm.capacity;
}

function calculateCapacityPerArmPlane(state) {
	let selectedArm = state.selectedCarryingArm;

	let stagSect = state.selectedStagSection;

	if (!selectedArm || !stagSect)
		return 0;

	let branchCount = countBranchStands(state.desiredSectionLength, stagSect.width);
	return selectedArm.capacity * branchCount;
}

function calculateSelectedPostCapacity(state) {
	let brand = state.selectedBrand;
	let selectedArm = state.selectedCarryingArm;

	if (brand === "Spetsglans") {
		if (!selectedArm)
			return 0;

		return selectedArm.post_capacity;
	} else if (brand === "Brucit") {
		let availablePosts = state.dataAvailablePosts;
		let selectedPostHeight = state.selectedPostOpt;

		for (let i = 0; i < availablePosts.length; i++) {
			if (availablePosts[i].brand !== brand) {
				continue;
			}

			let heightOpt = availablePosts[i];

			for (let x = 0; x < heightOpt.options.length; x++) {
				if (heightOpt.options[x].height !== selectedPostHeight) {
					continue;
				}

				let capacityOpts = heightOpt.options[x];

				for (let y = 0; y < capacityOpts.options.length; y++) {
					let doubleSidedPost = capacityOpts.options[y].variants[0].double_sided;

					if (doubleSidedPost !== state.doubleBranch) {
						continue;
					}

					return capacityOpts.options[y].max_capacity;
				}
			}
		}

		return 0;
	} else if (brand === "Hematit") {
		let availablePosts = state.dataAvailablePosts;
		let selectedPostHeight = state.selectedPostOpt;

		let allowedPostCapacities = selectedArm.allowed_post_capacity;
		let supportedCapacities = allowedPostCapacities.split(",");

		mainLoop: for (let i = 0; i < availablePosts.length; i++) {
			if (availablePosts[i].brand !== brand)
				continue;

			for (let x = 0; x < availablePosts[i].options.length; x++) {
				let heightPostOpt = availablePosts[i].options[x];

				if (heightPostOpt.height !== selectedPostHeight) {
					continue;
				}

				for (let y = 0; y < heightPostOpt.options.length; y++) {
					let capacityOpt = heightPostOpt.options[y];
					let maxCapacity = capacityOpt.max_capacity;


					if (!supportedCapacities.includes(maxCapacity + "")) {
						continue;
					}

					return maxCapacity;
				}

				break mainLoop;
			}
		}

		return 0;
	}

	return 0;
}

function calculateMaxArmPlanes(state) {
	let selectedArm = state.selectedCarryingArm;
	let selectedPostCapacity = state.selectedPostCapacity;
	let selectedBrand = state.selectedBrand;
	let doubleBranch = state.doubleBranch;
	let selectedPostHeight = state.selectedPostOpt;

	if (!selectedArm || !selectedPostCapacity || !selectedBrand)
		return 0;

	let maxArms = 0;
	let armsCount = 0;

	switch (selectedBrand) {
		case "Spetsglans":
			armsCount = (selectedArm.post_capacity / selectedArm.capacity);
			if (doubleBranch)
				armsCount /= 2;

			armsCount = Math.floor(armsCount);

			return armsCount;
		case "Brucit":
		case "Hematit":
			maxArms = Math.floor((selectedPostHeight / 1000) * 2);
			armsCount = (selectedPostCapacity / selectedArm.capacity);

			if (doubleBranch)
				armsCount /= 2;

			armsCount = Math.floor(armsCount);

			if (maxArms < armsCount)
				return maxArms;

			return armsCount;
	}
}

function recalculateStats(state) {
	state.totalLength = calculateTotalLength(state);

	state.totalHeight = parseInt(state.selectedPostOpt);

	state.selectedPostCapacity = calculateSelectedPostCapacity(state);

	state.maxAllowedArmPlanes = calculateMaxArmPlanes(state);

	state.totalCapacityPerSide = calculateCapacityPerSide(state);

	state.totalCapacityPerArmPlane = calculateCapacityPerArmPlane(state)

	state.totalDepth = parseInt(state.postFootLength);
}

export default new Vuex.Store({
	state: {
		selectedBrand: null,
		doubleBranch: false,
		includeUprightProtectors: false,
		includeEndStop: false,
		desiredSectionLength: null,
		selectedCarryingArm: null,
		selectedStagSection: null,
		selectedStagIndex: 0,
		selectedPostOpt: null,
		selectedPostOptIndex: 0,
		selectedEndStop: null,
		selectedPostCapacity: 0,
		selectedUprightProtector: null,
		postFootLength: null,
		postFootHeight: 0,
		totalLength: 0,
		totalHeight: 0,
		totalDepth: 0,
		totalCapacityPerSide: 0,
		totalCapacityPerArmPlane: 0,

		postWidth: 0,
		uprightProtectorBonusLength: 0,

		minAllowedArmPlanes: 1,
		maxAllowedArmPlanes: 1,

		dataArmPlanes: 0,
		dataArmOptions: [],
		dataStagSectionsOptions: [],
		dataPostOptions: [],
		dataPostHeightOptions: [],
		dataEndStopOptions: [],

		dataAvailableBrands: [],
		dataAvailableWidthSpans: [],
		minAllowedSectionLength: 1,
		maxAllowedSectionLength: 10,
		dataAvailableArms: [],
		dataAvailableCapacities: [],
		dataAvailableStagWidths: [],
		dataAvailableStagSections: [],
		dataAvailableHeights: [],
		dataAvailablePosts: [],
		dataAvailableUprightProtectors: [],
		dataAvailableEndStops: [],

		dataCartDataRows: []
	},
	getters: {
		availableBrands: state => {
			return state.dataAvailableBrands;
		},
		availableWidthSpans: state => {
			return state.dataAvailableWidthSpans;
		},
		availableArms: state => {
			return state.dataAvailableArms;
		},
		availableCapacities: state => {
			return state.dataAvailableCapacities;
		},
		availableStagWidths: state => {
			return state.dataAvailableStagWidths;
		},
		desiredSectionLength: state => {
			return state.desiredSectionLength;
		},
		selectedArm: state => {
			return state.data
		},
		selectedStagWidth: state => {
			return state.selectedStagSection;
		},
		availableHeights: state => {
			return state.dataAvailableHeights;
		},
		selectedBrand: state => {
			return state.selectedBrand;
		},
		armOptions: state => {
			return state.dataArmOptions;
		},
		stagSectionOptions: state => {
			return state.dataStagSectionsOptions;
		},

		// eslint-disable-next-line no-unused-vars
		totalLength: state => {
			return calculateTotalLength(state);
		},

		countBranchStands: state => {
			let stagSect = state.selectedStagSection;
			if (!stagSect)
				return 0;

			let desiredWidth = state.desiredSectionLength;

			if (!desiredWidth)
				return [];

			if (!state.selectedStagSection)
				return [];

			let desSectLength = state.desiredSectionLength;
			let stagWidth = stagSect.width;

			return countBranchStands(desSectLength, stagWidth);
		},

		branchStandPosInfo: state => {
			let desiredWidth = state.desiredSectionLength;

			if (!desiredWidth || !state.selectedStagSection)
				return [];

			let stagWidth = state.selectedStagSection.width;

			let branchStandCount = countBranchStands(desiredWidth, stagWidth);

			let posInfo = [];

			for (let i = 0; i < branchStandCount; i++) {
				let transCoords = translateHorizontalCoords(stagWidth * i);

				posInfo.push({
					index: i,
					style: "transform: translate(" + transCoords.x + "px, " + transCoords.y + "px);",
				});
			}

			return posInfo;
		},

		arrowHeightPosBaseCoords: state => {
			let baseX = 0;
			let baseY = 0;
			let margin = 0;

			switch (state.selectedBrand) {
				case "Spetsglans":
					baseX = 2000 + 200;
					baseY = 4400 - 200;

					if (state.doubleBranch) {
						let footLength = parseInt(state.postFootLength);
						let distance = footLength / 2;

						let depthTransCoords = translateDepthCoords(distance);

						baseX += depthTransCoords.x;
						baseY += depthTransCoords.y;

						baseY -= 300;
					} else {
						baseY -= 300;
					}

					break;
				case "Hematit":
					baseX = 2000 + 200;
					baseY = 4400 - 200;

					if (state.doubleBranch) {
						let footLength = parseInt(state.postFootLength);
						let distance = footLength / 2;

						let depthTransCoords = translateDepthCoords(distance);

						baseX += depthTransCoords.x;
						baseY += depthTransCoords.y;

						baseY -= 100;
					} else {
						baseY -= 300;

						let hordistance = 200;
						let transCoords = translateHorizontalCoords(hordistance);

						baseX += transCoords.x;
						baseY += transCoords.y;
					}

					break;
				case "Brucit":
					baseX = 2850;
					baseY = 4500;

					// eslint-disable-next-line no-case-declarations
					let hordistance = 0;

					if (state.doubleBranch) {
						hordistance = 300;
						baseY -= 1700;
					} else {
						hordistance = 500;
						baseY -= 1200;
					}

					// eslint-disable-next-line no-case-declarations
					let tcoords = translateHorizontalCoords(hordistance);

					baseX -= tcoords.x;
					baseY -= tcoords.y;

					break;
			}

			let distance = calculateTotalLength(state) + margin;
			let translatedCoords = translateHorizontalCoords(distance);

			return {
				x: baseX + translatedCoords.x,
				y: baseY + translatedCoords.y
			};
		},

		// eslint-disable-next-line no-unused-vars
		arrowLengthPosBaseCoords: state => {

			let baseX = 0;
			let baseY = 0;

			switch (state.selectedBrand) {
				case "Spetsglans":
				case "Hematit":
					baseX = 750 - 346 + 1299 - 260;
					baseY = 5000 + 200 - 750 - 150;

					// eslint-disable-next-line no-case-declarations,no-unused-vars
					var postFootLength = parseInt(state.postFootLength);
					if (state.doubleBranch)
						postFootLength /= 2;

					var depthMargin = +200;

					if (state.selectedBrand === "Spetsglans" && state.includeUprightProtectors)
						depthMargin += 500;

					// eslint-disable-next-line no-case-declarations
					let dtcoords = translateDepthCoords(depthMargin + postFootLength);

					baseX -= dtcoords.x;
					baseY -= dtcoords.y;
					break;
				case "Brucit":
					baseX = 750;
					baseY = 4000;
					break;
			}

			return {
				x: baseX,
				y: baseY
			};
		},

		// eslint-disable-next-line no-unused-vars
		arrowDepthPosBaseCoords: state => {

			let centerX = 0;
			let centerY = 0;

			switch (state.selectedBrand) {
				case "Spetsglans":
					centerX = (2116 + 325) - 779;
					centerY = (4933 - 188) - 450;

					if (state.doubleBranch) {
						let distance = 300;
						let dtcoords = translateDepthCoords(distance);

						centerX += dtcoords.x;
						centerY += dtcoords.y;
					} else {
						let depthDistance = 0;
						if (parseInt(state.postFootLength) === 1230)
							depthDistance = -200;
						else if (parseInt(state.postFootLength) === 1830)
							depthDistance = -500;

						let dtcoords = translateDepthCoords(depthDistance);

						centerX += dtcoords.x;
						centerY += dtcoords.y;
					}


					break;
				case "Hematit":
					centerX = (2116 + 325) - 779 + 173;
					centerY = (4933 - 188) - 450 + 100;

					if (state.doubleBranch) {
						let distance = 300;
						let dtcoords = translateDepthCoords(distance);

						centerX += dtcoords.x;
						centerY += dtcoords.y;
					} else {
						let distance = -300;
						let dtcoords = translateDepthCoords(distance);

						centerX += dtcoords.x;
						centerY += dtcoords.y;
					}


					break;
				case "Brucit":
					centerX = 1750;
					centerY = 3400;

					if (state.doubleBranch) {
						let depthDistance = 300;
						let dtcoords = translateDepthCoords(depthDistance);

						centerX += dtcoords.x;
						centerY += dtcoords.y;
					}

					break;
			}

			let transCoords = translateHorizontalCoords(state.totalLength);

			centerX += transCoords.x;
			centerY += transCoords.y;

			return {
				x: centerX,
				y: centerY
			};
		},

		postHeightOptions: state => {
			return state.dataPostHeightOptions;
		},

		armPosInfo: state => {
			let selectedArm = state.selectedCarryingArm;

			if (!selectedArm)
				return [];

			let armsCount = state.dataArmPlanes;

			let armPosInfo = [];

			let minArmHeight = 500;
			let maxArmHeight = parseInt(state.selectedPostOpt);

			if (armsCount === 1) {
				// This is going to set the post in the middle
				let armRange = maxArmHeight - minArmHeight;
				let mmPos = Math.round(armRange / 2);

				armPosInfo.push({
					index: 0,
					mmPos: mmPos,
					stylePos: "transform: translate(0, -" + mmPos + "px)",
				});
			} else {
				let topArmMargin = 0;

				if (state.selectedBrand === "Brucit") {
					// Adding extra margin for Brucit to make it look better
					topArmMargin = -200;
				}

				let armRange = (maxArmHeight - minArmHeight);
				let armPosInterval = Math.round(armRange / armsCount);

				for (let i = armsCount; i > 0; i--) {
					// This is going to set the posts height
					let mmPos = ((i - 1) * (armPosInterval)) - topArmMargin;
					armPosInfo.push({
						index: i,
						mmPos: mmPos,
						stylePos: "transform: translate(0, -" + mmPos + "px)",
					});
				}
			}

			return armPosInfo;
		},

		postStandPosInfo: (state, index) => {
			let stagSection = state.selectedStagSection;

			if (!stagSection)
				return null;

			let stagwidth = stagSection.width;

			// selectedStagSection.width
			let xTransform = Math.round(Math.sin(stagwidth) * index);
			let yTransform = Math.round(Math.sin(stagwidth) * index);

			return "transform: translate(" + xTransform + " px, " + yTransform + " px)";
		},

		// eslint-disable-next-line no-unused-vars
		selectedPostStand: state => {
			return getSelectedPostStand(state);
		},

		hasEndStop: state => {
			return state.dataEndStopOptions.length > 0;
		},
	},

	mutations: {
		selectBrand: (state, selectedBrand) => {
			// If null we set the first available brand
			if (!selectedBrand)
				selectedBrand = state.dataAvailableArms[0].brand;

			state.selectedBrand = selectedBrand;

			// Reset control state variables
			// NOTE No need to reset the doubleSidedBranchStand, desiredSectionLength variables
			state.selectedCarryingArm = null;
			state.selectedStagSection = null;
			state.selectedPostOpt = null;

			// Sets the available arm options
			for (let i = 0; i < state.dataAvailableArms.length; i++) {
				let brandArmsConfig = state.dataAvailableArms[i];

				if (brandArmsConfig.brand !== selectedBrand)
					continue;

				state.dataArmOptions = brandArmsConfig.options;

				// We select the first value by default
				state.selectedCarryingArm = state.dataArmOptions[0];

			}

			// Set the available stag width options
			state.dataStagSectionsOptions = [];
			for (let i = 0; i < state.dataAvailableStagSections.length; i++) {
				let brandStagsConfig = state.dataAvailableStagSections[i];

				if (brandStagsConfig.brand !== selectedBrand)
					continue;

				let stagSectOpts = [];
				let stagIndex = 0;

				brandStagsConfig.options.forEach(stagWidthOpts => {
					let opt = [];
					opt.name = stagWidthOpts.width + " mm";
					opt.width = stagWidthOpts.width;
					opt.index = stagIndex;
					stagIndex++;

					if (state.selectedBrand === "Hematit") {
						opt.value = stagWidthOpts.options;
					} else {
						opt.value = stagWidthOpts.variants[0];
					}

					stagSectOpts.push(opt);
				});

				state.dataStagSectionsOptions = stagSectOpts;
				state.selectedStagSection = stagSectOpts[0];
				state.selectedStagIndex = 0;
			}

			// Set's the available height options
			state.dataPostHeightOptions = [];

			for (let i = 0; i < state.dataAvailablePosts.length; i++) {
				let brandPostConfig = state.dataAvailablePosts[i];

				if (brandPostConfig.brand !== selectedBrand)
					continue;

				state.postFootHeight = brandPostConfig.foot_height;
				state.dataPostOptions = brandPostConfig.options;

				let postHeightOptions = [];
				for (let y = 0; y < state.dataPostOptions.length; y++) {
					let postOpt = state.dataPostOptions[y];

					postOpt.name = postOpt.height + " mm";
					postOpt.index = y;

					postHeightOptions.push(postOpt);
				}

				state.dataPostHeightOptions = postHeightOptions;

				// Selects the first item as the default value
				state.selectedPostOptIndex = 0;
				state.selectedPostOpt = postHeightOptions[0].height;

				switch (selectedBrand) {
					case "Spetsglans":
						state.postFootLength = "830";
						break;
					case "Hematit":
						state.postFootLength = "1425";
						break;
					case "Brucit":
						state.postFootLength = "685";
						break;
				}

				// We also set the postWidth if available
				state.postWidth = brandPostConfig.post_width;
				break;
			}

			// Sets available end stops
			state.dataEndStopOptions = [];
			state.selectedEndStop = null;
			for (let i = 0; i < state.dataAvailableEndStops.length; i++) {
				let brandEndStopConfig = state.dataAvailableEndStops[i];

				if (brandEndStopConfig.brand !== selectedBrand)
					continue;

				state.dataEndStopOptions = brandEndStopConfig.options;

				// We reset this to false if the brand doesn't have, otherwise we let the same value remain
				if (state.dataEndStopOptions.length === 0)
					state.includeEndStop = false;
				else
					state.selectedEndStop = state.dataEndStopOptions[0];
			}

			setFootPostOpt(state);

			recalculateStats(state);

			// Resets default value which may depend on calculated values in recaclulatestats
			resetSelectedArmPlanes(state);
		},

		setDoubleBranch: (state, value) => {
			state.doubleBranch = (value === true)

			if (state.selectedBrand === 'Spetsglans') {
				resetSelectedArmPlanes(state);
			}

			setFootPostOpt(state);

			recalculateStats(state);
		},

		setDesiredSectionLength: (state, value) => {
			state.desiredSectionLength = value;

			recalculateStats(state);
		},

		setCarryingArm: (state, value) => {
			state.selectedCarryingArm = value;

			if (!value)
				return;

			setFootPostOpt(state);

			recalculateStats(state);

			resetSelectedArmPlanes(state);
		},

		setAvailableArms: (state, value) => {
			state.dataAvailableArms = value;
		},

		setArmPlanes: (state, value) => {
			state.dataArmPlanes = value;

			recalculateStats(state);
		},

		selectedStagSection: (state, index) => {
			state.selectedStagIndex = index;
			state.selectedStagSection = state.dataStagSectionsOptions[index];

			recalculateStats(state);
		},

		setSelectedPostOpt: (state, index) => {
			state.selectedPostOptIndex = index;

			let postOpt = state.dataPostHeightOptions[index];

			// The height is used in different evaluations
			state.selectedPostOpt = postOpt.height;

			recalculateStats(state);
		},

		setAvailablePosts: (state, value) => {
			state.dataAvailablePosts = value;

			// Sets the available brands to choose from
			var brands = [];
			for (var i = 0; i < value.length; i++) {
				brands.push(value[i].brand);
			}
			state.dataAvailableBrands = brands;
		},

		setUprightProtectors: (state, value) => {
			state.includeUprightProtectors = (value === true);

			recalculateStats(state);
		},

		setIncludeEndStop: (state, value) => {
			state.includeEndStop = (value === true);

			recalculateStats(state);
		},

		setAvailableBrands: (state, value) => {
			state.dataAvailableBrands = value;
		},

		setAvailableStagSections: (state, value) => {
			state.dataAvailableStagSections = value;
		},

		setAvailableUprightProtectors: (state, value) => {
			state.dataAvailableUprightProtectors = value;
			state.selectedUprightProtector = value[0];

			if (state.selectedUprightProtector.lengthBonus)
				state.uprightProtectorBonusLength = state.selectedUprightProtector.lengthBonus;

		},

		setAvailableEndStops: (state, value) => {
			state.dataAvailableEndStops = value;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		requestData({commit}) {
			$.ajax({
				"url": SERVER_BASE_URL + "?cmd=loadconfig",
				"data": "",
				"method": "POST",
				"dataType": "json",
				/*eslint-disable-next-line*/ // Added comment to avoid warnings because textStatus and xhr aren't used :)
				"success": function (data, textStatus, xhr) {
					// NVM data.data :)
					const configData = data.data;

					if( configData.arms === undefined )
					{
						showErrorMessage( "Serverfel, kunde ej starta grenställsbyggaren. Vänligen pröva igen senare.." );
						return;
					}

					commit("setAvailableArms", configData.arms);
					commit("setAvailablePosts", configData.available_posts);
					commit("setAvailableStagSections", configData.stag_sections);
					commit("setAvailableUprightProtectors", configData.upright_protectors);
					commit("setAvailableEndStops", configData.end_stops);

					// Sets brand to null in order to select the default/first value
					commit("selectBrand", null);
				},

				// eslint-disable-next-line no-unused-vars
				"error": function(qXHR, textStatus, errorThrown)
				{
					showErrorMessage( "Serverfel, kunde ej starta grenställsbyggaren. Vänligen pröva igen senare." );
				}
			});
		},
	},
})
