<template>
  <g class="leftside-signal230mm">
    <g class="single-signallag230mm" data-name="single-signallag230mm">
      <g class="bar1220mm" data-name="bar1220mm">
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="2046.15 2768.32 1894.65 2855.82 1743.05 2768.32 1894.65 2680.82 2046.15 2768.32" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M1827.35,2767.72v-89.5h133.8v89.9S1827.15,2767.52,1827.35,2767.72Z" fill="#f9c138"/>
            <ellipse cx="1894.25" cy="2768.32" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1894.25" cy="2679.42" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="1084.05 3323.32 932.45 3410.82 780.95 3323.32 932.45 3235.82 1084.05 3323.32" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M866.15,3322.72v-89.5H999v89.9S866,3322.52,866.15,3322.72Z" fill="#f9c138"/>
            <ellipse cx="932.95" cy="3323.32" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="932.45" cy="3234.42" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" data-name="bar" d="M999.15,3268.32h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2-.1,105.4Z" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" data-name="blackmark" points="1194.55 3033.12 1224.65 2988.02 1190.45 2921.02 1138.45 2973.02 1187.75 3034.52"/>
      <polyline class="blackmark" data-name="blackmark" points="1648.45 2776.12 1678.45 2731.02 1644.35 2664.02 1592.35 2715.92 1641.55 2777.52"/>
      <polygon class="rightside-sg-brucit" data-name="rightside-sg-brucit" points="1084.05 3333.32 932.45 3420.82 932.45 3410.82 1084.05 3323.32 1084.05 3333.32" fill="#db8914"/>
      <polygon class="frontside-sg-brucit" data-name="frontside-sg-brucit" points="932.45 3420.72 780.95 3333.22 780.95 3323.22 932.45 3410.72 932.45 3420.72" fill="#e8932e"/>
      <polygon class="frontside-sg-brucit" data-name="frontside-sg-brucit" points="1894.45 2865.92 1742.95 2778.42 1742.95 2768.42 1894.45 2855.92 1894.45 2865.92" fill="#e8932e"/>
      <polygon class="rightside-sg-brucit" data-name="rightside-sg-brucit" points="2046.05 2778.32 1894.55 2865.82 1894.55 2855.82 2046.05 2768.32 2046.05 2778.32" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "BrucitSignalLeft.vue",
};
</script>