<template>
  <g class="rightside-signal230mm">
    <g class="single-signallag230mm">
      <g class="bar1220mm">
        <g class="platewithcylinder">
          <polygon points="2549.97 3050.84 2398.47 3138.34 2246.87 3050.84 2398.47 2963.34 2549.97 3050.84" fill="#fba61e"/>
          <g class="topcylinder">
            <path d="M2331.17,3050.24v-89.5H2465v89.9S2331,3050,2331.17,3050.24Z" fill="#f9c138"/>
            <ellipse cx="2398.07" cy="3050.84" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="2398.07" cy="2961.94" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="1587.87 3605.84 1436.27 3693.34 1284.77 3605.84 1436.27 3518.34 1587.87 3605.84" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M1370,3605.24v-89.5h132.8v89.9S1369.77,3605,1370,3605.24Z" fill="#f9c138"/>
            <ellipse cx="1436.77" cy="3605.84" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1436.27" cy="3516.94" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" d="M1503,3550.84H1370v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2-.1,105.4Z" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" points="1698.37 3315.64 1728.47 3270.54 1694.27 3203.54 1642.27 3255.54 1691.57 3317.04"/>
      <polyline class="blackmark" data-name="blackmark" points="2152.27 3058.64 2182.27 3013.54 2148.17 2946.54 2096.17 2998.44 2145.37 3060.04"/>
      <polygon class="rightside-sg-brucit" data-name="rightside-sg-brucit" points="1587.87 3615.84 1436.27 3703.34 1436.27 3693.34 1587.87 3605.84 1587.87 3615.84" fill="#db8914"/>
      <polygon class="frontside-sg-brucit" data-name="frontside" points="1436.27 3703.24 1284.77 3615.74 1284.77 3605.74 1436.27 3693.24 1436.27 3703.24" fill="#e8932e"/>
      <polygon class="frontside-sg-brucit" data-name="frontside-sg-brucit" points="2398.27 3148.44 2246.77 3060.94 2246.77 3050.94 2398.27 3138.44 2398.27 3148.44" fill="#e8932e"/>
      <polygon class="rightside-sg-brucit" data-name="rightside-sg-brucit" points="2549.87 3060.84 2398.37 3148.34 2398.37 3138.34 2549.87 3050.84 2549.87 3060.84" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "BrucitSignalRight.vue",
};
</script>