<template>
<g class="strut750">
        <g class="overstrut"
        v-show="postOpt >= 3500"
        >
          <g class="cross">
            <line class="underline" x1="1105.57" y1="2000.97" x2="1668.53" y2="998.71" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline" x1="1693.05" y1="2319.1" x2="1117.46" y2="682.22" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut">
            <polygon class="frontside" points="1732.34 1077.22 1082.82 702.22 1082.82 642.22 1732.34 1017.22 1732.34 1077.22" fill="#1b7742"/>
            <polygon class="topside-5" data-name="topside" points="1732.34 1017.22 1082.82 642.22 1117.46 622.22 1766.98 997.22 1732.34 1017.22" fill="#1f9456"/>
          </g>
        </g>
        <g class="understrut">
          <g class="cross" data-name="cross">
            <line class="underline-2" data-name="underline" x1="1105.31" y1="3312.1" x2="1668.27" y2="2309.84" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
            <line class="overline-2" data-name="overline" x1="1692.79" y1="3630.23" x2="1117.2" y2="1993.35" fill="#0e4c27" stroke="#155e33" stroke-miterlimit="10" stroke-width="30"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside-2" data-name="frontside" points="1732.34 2387.22 1082.82 2012.21 1082.82 1952.21 1732.34 2327.22 1732.34 2387.22" fill="#1b7742"/>
            <polygon class="topside-6" data-name="topside" points="1732.34 2327.22 1082.82 1952.21 1117.46 1932.21 1766.98 2307.22 1732.34 2327.22" fill="#1f9456"/>
          </g>
          <g class="strut" data-name="strut">
            <polygon class="frontside-3" data-name="frontside" points="1732.08 3698.35 1082.56 3323.35 1082.56 3263.35 1732.08 3638.35 1732.08 3698.35" fill="#1b7742"/>
            <polygon class="topside-7" data-name="topside" points="1732.08 3638.34 1082.56 3263.34 1117.2 3243.34 1766.72 3618.34 1732.08 3638.34" fill="#1f9456"/>
          </g>
        </g>
      </g>
</template>
<script>
export default {
  name: "Strut750-H.vue",

  computed: {
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
  }
};
</script>