<template>
    <g class="arm600">
        <g class="singelarm">
          <polygon class="arm-right-backside" points="1645.14 3598.96 1125.52 3898.96 1125.52 3843.96 1645.14 3543.96 1645.14 3598.96" fill="#155431"/>
          <polygon class="arm-rightside arm-rightform" data-name="arm-rightside arm-rightform" points="1731.6 3553.96 1731.6 3693.96 1696.95 3713.96 1177.53 3928.95 1177.53 3873.95 1731.6 3553.96" fill="#19683f"/>
          <polygon class="topside" data-name="topside" points="1697.1 3573.96 1177.48 3873.96 1125.52 3843.96 1645.14 3543.96 1697.1 3573.96" fill="#1f9456"/>
        </g>
        <g class="doublearm" v-show="doubleBranch">
          <polygon class="arm-rightside darm-rightform" data-name="arm-rightside darm-rightform" points="1858.49 3620.71 1858.49 3480.77 2415.9 3158.95 2415.9 3213.95 1893.13 3600.7 1858.49 3620.71" fill="#19683f"/>
          <polygon class="topside armtopform" data-name="topside armtopform" points="1893.14 3400.76 1893.14 3460.7 2415.9 3158.95 2363.94 3128.95 1893.14 3400.76" fill="#1f9456"/>
        </g>
    </g>
</template>
<script>
export default {
  name: "Arm600-H.vue",

  computed: {
    doubleBranch: {
      get() {
        return this.$store.state.doubleBranch;
      }
    }
  }
};
</script>