<template>

<svg
    class="strutLayers wrapperSvgNeg30 marginstrutMax2000" xmlns="http://www.w3.org/2000/svg" 
    width="3174.1" height="4940.94" viewBox="-900 -400 14900 11000">

  <g
      v-for="postInfo in branchStandPosInfo"
      :key="postInfo.index"
      class="rightGroup postStandSector"
      v-bind:style="postInfo.style"
    >
    <!-- Manages first upright protector -->
    <template
        v-if="includeUprightProtector && postInfo.index === 0">
      <SpetsSignalLeft1220
          :doubleBranch="doubleBranch"
      />
    </template>

    <!-- Manages stag/strut-->
    <template  v-if="postInfo.index > 0">
      <Strut1000 v-if="selectedStagSection.width == 1000" />
      <Strut1200 v-if="selectedStagSection.width == 1200" />
      <Strut1500 v-if="selectedStagSection.width == 1500" />
      <Strut2000 v-if="selectedStagSection.width == 2000" />
    </template>

    <!-- Manages the post and the foot -->
     <template v-if="doubleBranch">
       <FootDouble1430 v-if="footLength == 1430" />
       <FootDouble2230 v-if="footLength == 2230" />
       <FootDouble3230 v-if="footLength == 3230" />
    </template>
    <template v-else>
      <Foot830 v-if="footLength == 830" />
      <Foot1230 v-if="footLength == 1230" />
      <Foot1830 v-if="footLength == 1830" />
    </template>

    <!-- Manages the Post stand -->
    <template>
        <Post2432 v-if="postOpt == 2432" />
        <Post2964 v-if="postOpt == 2964" />
        <Post3952 v-if="postOpt == 3952" />
    </template>

    <template v-if="selectedArm.length == 600">
      <Arm600
          v-for="armPos in armPosInfo"
          :key="armPos.index"
          :doubleBranch="doubleBranch"
          v-bind:style ="armPos.stylePos"
      />
    </template>

    <template v-if="selectedArm.length == 1000">
      <Arm1000
          v-for="armPos in armPosInfo"
          :key="armPos.index"
          :doubleBranch="doubleBranch"
          v-bind:style ="armPos.stylePos"
      />
    </template>

    <template v-if="selectedArm.length == 1500">
      <Arm1500
          v-for="armPos in armPosInfo"
          :key="armPos.index"
          :doubleBranch="doubleBranch"
          v-bind:style ="armPos.stylePos"
      />
    </template>

    <!-- Manages second upright protector -->
    <template
        v-if="includeUprightProtector && ((postInfo.index+1) === branchStandPosInfo.length)">
      <SpetsSignal1220
          :doubleBranch="doubleBranch"
      />
    </template>
  </g>

  <ArrowLength
      :startX="arrowLengthPosBaseCoords.x"
      :startY="arrowLengthPosBaseCoords.y"
      :totalLength="totalLength"
  />

  <ArrowDepth
      :startX="arrowDepthPosBaseCoords.x"
      :startY="arrowDepthPosBaseCoords.y"
      :totalDepth="totalDepth"
  />

  <ArrowHeight
      :startX="arrowHeightPosBaseCoords.x"
      :startY="arrowHeightPosBaseCoords.y"
      :totalHeight="totalHeight"
  />
</svg>

</template>
<script>
import Arm600 from "@/components/svg-parts/spetsglans/arms/Arm600.vue";
import Arm1000 from "@/components/svg-parts/spetsglans/arms/Arm1000.vue";
import Arm1500 from "@/components/svg-parts/spetsglans/arms/Arm1500.vue";

import Post2432 from "@/components/svg-parts/spetsglans/posts/Post2432.vue";
import Post2964 from "@/components/svg-parts/spetsglans/posts/Post2964.vue";
import Post3952 from "@/components/svg-parts/spetsglans/posts/Post3952.vue";

import Foot830 from "@/components/svg-parts/spetsglans/foots/Foot830";
import Foot1230 from "@/components/svg-parts/spetsglans/foots/Foot1230";
import Foot1830 from "@/components/svg-parts/spetsglans/foots/Foot1830";

import FootDouble1430 from "@/components/svg-parts/spetsglans/foots/FootDouble1430";
import FootDouble2230 from "@/components/svg-parts/spetsglans/foots/FootDouble2230";
import FootDouble3230 from "@/components/svg-parts/spetsglans/foots/FootDouble3230";

// Struts
import Strut2000 from "@/components/svg-parts/spetsglans/struts/Strut2000.vue";
import Strut1500 from "@/components/svg-parts/spetsglans/struts/Strut1500.vue";
import Strut1200 from "@/components/svg-parts/spetsglans/struts/Strut1200.vue";
import Strut1000 from "@/components/svg-parts/spetsglans/struts/Strut1000.vue";

// post protection
import SpetsSignal1220 from "@/components/svg-parts/post-protection/SpetsSignal1220.vue";
import SpetsSignalLeft1220 from "@/components/svg-parts/post-protection/SpetsSignalLeft1220.vue";

// Arrows
import ArrowHeight from "@/components/svg-parts/misc/ArrowHeight.vue";
import ArrowLength from "@/components/svg-parts/misc/ArrowLength.vue";
import ArrowDepth from "@/components/svg-parts/misc/ArrowDepth.vue";

import {mapGetters} from "vuex";

export default {
  name: "SpetsglansCombinedParts.vue",
  components: {
   // Test,
    Arm600,
    Arm1000,
    Arm1500,

    Post2432,
    Post2964,
    Post3952,

    Foot830,
    Foot1230,
    Foot1830,

    FootDouble1430,
    FootDouble2230,
    FootDouble3230,

    Strut1000,
    Strut1200,
    Strut1500,
    Strut2000,

    ArrowHeight,
    ArrowLength,
    ArrowDepth,

    SpetsSignal1220,
    SpetsSignalLeft1220
  },

  computed: {
    selectedStagSection: {
      get() {
        return this.$store.state.selectedStagSection;
      },
    },
    selectedArm: {
      get() {
        return this.$store.state.selectedCarryingArm;
      }
    },
    selectedArmPlanes: {
      get() {
        return this.$store.state.selectedArmPlanes;
      }
    },
    doubleBranch: {
      get() {
        return this.$store.state.doubleBranch;
      }
    },
    postOpt: {
      get() {
        return this.$store.state.selectedPostOpt;
      }
    },
    footLength: {
      get() {
        return this.$store.state.postFootLength;
      }
    },
    totalHeight: {
      get() {
        return this.$store.state.totalHeight;
      }
    },
    totalLength: {
      get() {
        return this.$store.state.totalLength;
      }
    },
    totalDepth: {
      get() {
        return this.$store.state.totalDepth;
      }
    },
    includeUprightProtector: {
      get() {
        return this.$store.state.includeUprightProtectors;
      }
    },
    uprightProtectorPosStyle: {
      get() {
        return "translate: transform(0, "+this.totalHeight+" px)";
      }
    },
    ...mapGetters([
      "availableHeights",
      "armPosInfo",
      "selectedPostStand",
      "countBranchStands",
      "branchStandPosInfo",
      "arrowHeightPosBaseCoords",
      "arrowLengthPosBaseCoords",
      "arrowDepthPosBaseCoords"
    ]),
  }
};
</script>

<style scoped>
</style>
