<template>
<g class="strut1500">
    <g class="strutunderbox" data-name="strutunderbox">
        <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="435.31 2180.9 430.98 2178.4 430.98 2048.4 435.31 2050.9 435.31 2180.9" fill="#5e7cb7"/>
        <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="452.63 2170.89 435.31 2180.89 435.31 2050.89 452.63 2040.89 452.63 2170.89" fill="#365593"/>
        <polygon class="topstag-3" data-name="topstag" points="452.63 2040.9 435.31 2050.9 430.98 2048.4 448.3 2038.4 452.63 2040.9" fill="#5070af"/>
    </g>
    <g class="understrutbar" data-name="understrutbar">
        <polygon class="topstrut1500 topside" data-name="topstrut1500 topside" points="1727.73 2923.19 428.69 2173.19 454.68 2158.19 1753.71 2908.19 1727.73 2923.19" fill="#5070af"/>
        <polygon class="frontstrut1500 frontside" data-name="frontstrut1500 frontside" points="1727.72 2953.16 428.68 2203.16 428.68 2173.16 1727.72 2923.16 1727.72 2953.16" fill="#5e7cb7"/>
    </g>
    <g class="strutupperbox" data-name="strutupperbox">
        <polygon class="strutstagfront frontside" data-name="strutstagfront frontside" points="436.68 456.66 432.35 454.16 432.35 324.16 436.68 326.66 436.68 456.66" fill="#5e7cb7"/>
        <polygon class="strutstagright rightside" data-name="strutstagright rightside" points="454 446.65 436.68 456.65 436.68 326.65 454 316.65 454 446.65" fill="#365593"/>
        <polygon class="topstag" data-name="topstag" points="454 316.65 436.68 326.65 432.35 324.15 449.67 314.15 454 316.65" fill="#5070af"/>
    </g>
    <g class="overstrutbar" data-name="overstrutbar">
        <polygon class="topstrut1500 top" data-name="topstrut1500 topside" points="1730.24 1052 431.2 302 457.18 287 1756.22 1037 1730.24 1052" fill="#5070af"/>
        <polygon class="frontstrut1500 frontside" data-name="frontstrut1500 frontside" points="1730.24 1082 431.2 332 431.2 302 1730.24 1052 1730.24 1082" fill="#5e7cb7"/>
    </g>
      <line class="upperline" data-name="upperline" x1="443.17" y1="408.25" x2="1797.01" y2="2854.45" fill="#5070af" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
      <ellipse class="rightbolt" data-name="rightbolt" cx="1554.79" cy="955.59" rx="7.57" ry="4.37" transform="translate(-1167.59 1265.86) rotate(-59.37)" fill="#d6dde8"/>
      <line class="downline" data-name="downline" x1="1797.01" y1="1195.51" x2="443.17" y2="2067.2" fill="none" stroke="#5070af" stroke-miterlimit="10" stroke-width="17"/>
      <ellipse class="rightbolt" data-name="rightbolt" cx="1558.76" cy="2603.08" rx="7.57" ry="4.37" transform="translate(-2583.34 2077.5) rotate(-59.37)" fill="#d6dde8"/>
    </g>
</template>
<script>
export default {
  name: "Strut1500-B.vue",
};
</script>