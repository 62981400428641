
<template>
  <div class="dropDownInfoWrapper">
             <p>Ändstoppet hindrar godset att ramla ner från armarna.</p>
  </div>
</template>
<script>
export default {
  name: "Andstopp.vue",
};
</script>