<template>
  <div class="specification">
    <div class="specificationWrapper">

      <b-table :data="articles" :columns="columns"></b-table>

      <div class="totalArticleWrapper">

        <div class="totalSumWrapper">
          <span>SUMMA ARTIKLAR</span>
          {{totalSum}} kr
        </div>
        <div class="priceInfoWrapper">
          <div class="divPriceExVat">
            (exkl. moms)
          </div>

        </div>
        <div class="bottomSpecWrapper">
          <div class="pdfWrapper">
            <a
              class="aIcon"
              href="#"
            ><img
                src="../assets/download-pdf.svg"
                alt="Ladda ner pdf"
              ></a>
            <a
              class="aText"
              href="#"
            >Ladda ned pdf</a>
          </div>
          <button
            title="Lägg i varukorgen"
            class="level-item is-large buy-btn"
            style=""
            @click="addToCart"
          >
            Lägg i varukorg
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import serverInfo from './../serverinfo/';
import {showErrorMessage} from './../helper';
import $ from "jquery";

const SERVER_BASE_URL = serverInfo.SERVER_BASE;
const API_URL         = serverInfo.API_URL;

export default {
  data() {
    return {
      columns: [
          {
            field: "displayId",
            label: "Artnr"
          },
          {
            field: "articleName",
            label: "Artikelnamn"
          },
          {
            field: "quantity",
            label: "Antal"
          },
          {
            field: "price",
            label: "Pris"
          },
          {
            field: "total",
            label: "Total"
          }
        ],
    };
  },

  name: "Specification.vue",

  computed: {
    articles() {
      let state = this.$store.state;

      // We can only do this if a brand has already been selected
      if( !state.selectedBrand )
        return [];

      let set = [];

      let doubleBranch = state.doubleBranch;

      //let branchStandCount = state.countBranchStands;'

      // branchStandPosInfo | countBranchStands

      let branchCount = this.countBranchStands;
      let stagSectionCount = branchCount-1;

      // Sets artnr
      let branchStandProd = this.selectedPostStand;

      let stagSectProd = this.getSelectedStagSectionArticle( state );

      let armProd = state.selectedCarryingArm;

      if(!branchStandProd)
      {
        console.log("Specification calculateCart now returning, branchStandProd isn't set");
        return [];
      }

      // Sets for branch stand prod
      set[branchStandProd.artNo] = {
        articleNumber: branchStandProd.artNo,
        articleName: branchStandProd.name,
        displayId: branchStandProd.displayid,
        quantity: branchCount,
        price: branchStandProd.price,
        total: (branchStandProd.price * branchCount),
      };

      // Sets for the stag section prod
      set[stagSectProd.artNo] = {
        articleNumber: stagSectProd.artNo,
        articleName: stagSectProd.name,
        displayId: stagSectProd.displayid,
        quantity: stagSectionCount,
        price: stagSectProd.price,
        total: (stagSectProd.price * stagSectionCount),
      };

      // Sets for the stag section prod
      let armPlaneLevels = state.dataArmPlanes;
      let armQty = armPlaneLevels * branchCount *  ( doubleBranch ? 2 : 1 );
      set[armProd.artNo] = {
        articleNumber: armProd.artNo,
        articleName: armProd.name,
        displayId: armProd.displayid,
        quantity: armQty,
        price: armProd.price,
        total: (armProd.price * armQty),
      };

      if( state.includeEndStop )
      {
        let endStopArticle = state.selectedEndStop;

        // The endstop qty is the same as the number of arms
        let endStopQty = armQty;

        set[endStopArticle.artNo] = {
          articleNumber: endStopArticle.artNo,
          articleName: endStopArticle.name,
          displayId: endStopArticle.displayid,
          quantity: endStopQty,
          price: endStopArticle.price,
          total: (endStopArticle.price * endStopQty),
        };
      }

      if( state.includeUprightProtectors )
      {
        let uprightProd = state.selectedUprightProtector;

        let uprightProtQty = state.doubleBranch ? 4 : 2;

        set[uprightProd.artNo] = {
          articleNumber: uprightProd.artNo,
          articleName: uprightProd.name,
          displayId: uprightProd.displayid,
          quantity: uprightProtQty,
          price: uprightProd.price,
          total: (uprightProd.price * uprightProtQty),
        };
      }

      var items = [];
      for (var key in set) {
        items.push(set[key]);
      }

      return items;
    },

    totalSum() {
      let calcSum = (sum, a) => {
        return sum + Math.round(a.total);
      };

      return this.articles.reduce(calcSum, 0);
    },

    ...mapGetters([
      "branchStandPosInfo",
      "countBranchStands",
      "selectedPostStand"
    ]),
  },

  methods: {
    async addToCart() {
      // Build rows to add
      const itemArgs = [];

      this.articles.forEach( article => {
        itemArgs.push({
          "prodno": parseInt( article.articleNumber ),  // Note Using articleNumber instead of artNo is intentional :)
          "quantity": parseInt( article.quantity )
        });
      });

      const args = {
        "items": itemArgs,
      };

      const submitPurchase = this.submitPurchase;

      $.ajax({
        "url": SERVER_BASE_URL+"?cmd=sign_purchase",
        "data": JSON.stringify(args),
        "method": "POST",
        "dataType": "json",
        /* eslint-disable-next-line */ // Added comment to avoid warnings because textStatus and xhr aren't used :)
        "success": function (data, textStatus, xhr) {
          if (data.status !== "OK")
            showErrorMessage( "Serverfel, kunde ej lägga till varorna i varukorgen, prova igen senare." );
          else
            submitPurchase(data.signature, data.script);
        },
        // eslint-disable-next-line no-unused-vars
        "error": function(qXHR, textStatus, errorThrown)
        {
          showErrorMessage("Serverfel, kunde ej lägga till varorna i varukorgen, prova igen senare.");
        }
      });
    },

    submitPurchase( signature, script )
    {
      $.ajax({
        "url": API_URL,
        "headers": {
          "X-API-Signature": signature,
          "Content-Type": "application/json"
        },
        "data": JSON.stringify( script ),
        "method": "POST",
        "dataType": "json",
        "xhrFields": {
          "withCredentials": true
        },
        // eslint-disable-next-line no-unused-vars
        "success": function( data, textStatus, xhr ) {
          const lastResponse = data.response.pop();

          if( lastResponse && lastResponse.location )
            window.location.href = lastResponse.location;
          else
            showErrorMessage("Serverfel, kunde ej lägga till varorna i varukorgen, prova igen senare.");
        },
        // eslint-disable-next-line no-unused-vars
        "error": function(qXHR, textStatus, errorThrown)
        {
          showErrorMessage("Serverfel, kunde ej lägga till varorna i varukorgen, prova igen senare.");
        }
      });
    },

    getSelectedStagSectionArticle: (state ) =>
    {
      let stagOpts = state.dataStagSectionsOptions;
      let selectedStagSect = state.selectedStagSection;

      if( !stagOpts || !selectedStagSect )
        return null;

      if( state.selectedBrand === 'Hematit' ) {
        let heightRange = parseInt( state.selectedPostOpt ) >= 3500 ? "3.5-4" : "2-3";

        for( let i = 0; i < selectedStagSect.value.length; i++ )
        {
          let stagHeightOptions = selectedStagSect.value[i];

          if( stagHeightOptions.height_range !== heightRange )
            continue;

          for( let v = 0; v < stagHeightOptions.variants.length; v++ )
          {
            let stagVariant = stagHeightOptions.variants[v];

            // We simply return the variant that has the cross here
            if( stagVariant.cross )
              return stagVariant;
          }
        }

        return null;
      } else {
        for( let i = 0; i < stagOpts.length; i++ )
        {
          let stagData = stagOpts[i];

          if( stagData.width !== selectedStagSect.width )
            continue;

          return stagData.value;
        }

        return null;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
