<template>
<div class="modelHeadingWrapper">
    <div class="underModelHeadingWrapper">
        <p>modell:</p>
        <h1>{{ selectedBrand }}</h1>
    </div>

</div>
</template>

<script>
export default {
  name: "ModelHeading.vue",

  computed: {
    selectedBrand: {
      get() {
        return this.$store.state.selectedBrand;
      }
    }
  }

};
</script>

<style scoped>
</style>
