<template>
 <g class="rightside-signal230mm">
    <g class="single-signallag230mm">
      <g class="bar1220mm">
        <g class="platewithcylinder">
          <polygon points="2071.6 4509.28 1920.1 4596.78 1768.5 4509.28 1920.1 4421.78 2071.6 4509.28" fill="#fba61e"/>
          <g class="topcylinder">
            <path d="M1777.75,4508.68v-89.5h133.8v89.9S1777.55,4508.48,1777.75,4508.68Z" transform="translate(75.05)" fill="#f9c138"/>
            <ellipse cx="1919.7" cy="4509.28" rx="67.1" ry="38.9" fill="#f9c138"/>
            <ellipse cx="1919.7" cy="4420.38" rx="67.1" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <g class="platewithcylinder" data-name="platewithcylinder">
          <polygon points="1109.5 5064.28 957.9 5151.78 806.4 5064.28 957.9 4976.78 1109.5 5064.28" fill="#fba61e"/>
          <g class="topcylinder" data-name="topcylinder">
            <path d="M816.55,5063.68v-89.5h132.8v89.9S816.35,5063.48,816.55,5063.68Z" transform="translate(75.05)" fill="#f9c138"/>
            <ellipse cx="958.4" cy="5064.28" rx="66.5" ry="38.9" fill="#f9c138"/>
            <ellipse cx="957.9" cy="4975.38" rx="66.6" ry="38.9" fill="#f9c138"/>
          </g>
        </g>
        <path class="bar" d="M949.55,5009.28h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1a66.59,66.59,0,0,1,99.8,57.8l-.4,143.9-133-.4.1-28.3-828.7,478.2-.1,105.4Z" transform="translate(75.05)" fill="#f9c138"/>
      </g>
      <polyline class="blackmark" points="1220 4774.08 1250.1 4728.98 1215.9 4661.98 1163.9 4713.98 1213.2 4775.48"/>
      <polyline class="blackmark" data-name="blackmark" points="1673.9 4517.08 1703.9 4471.98 1669.8 4404.98 1617.8 4456.88 1667 4518.48"/>
      <polygon class="rightside-sg-hematit" points="1109.5 5074.28 957.9 5161.78 957.9 5151.78 1109.5 5064.28 1109.5 5074.28" fill="#db8914"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="957.9 5161.68 806.4 5074.18 806.4 5064.18 957.9 5151.68 957.9 5161.68" fill="#e8932e"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="1919.9 4606.88 1768.4 4519.38 1768.4 4509.38 1919.9 4596.88 1919.9 4606.88" fill="#e8932e"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="2071.5 4519.28 1920 4606.78 1920 4596.78 2071.5 4509.28 2071.5 4519.28" fill="#db8914"/>
    </g>
    <g  class="double-signallag230mm">
      <polygon points="3617.9 3616.78 3466.3 3704.28 3314.8 3616.78 3466.3 3529.28 3617.9 3616.78" fill="#fba61e"/>
      <g class="topcylinder" data-name="topcylinder">
        <path d="M3324.05,3616.18v-89.5h133.8v89.9S3323.85,3616,3324.05,3616.18Z" transform="translate(75.05)" fill="#f9c138"/>
        <ellipse cx="3466" cy="3616.78" rx="67.1" ry="38.9" fill="#f9c138"/>
        <ellipse cx="3466" cy="3527.88" rx="67.1" ry="38.9" fill="#f9c138"/>
      </g>
      <polygon points="2655.8 4171.78 2504.2 4259.28 2352.7 4171.78 2504.2 4084.28 2655.8 4171.78" fill="#fba61e"/>
      <g class="topcylinder" data-name="topcylinder">
        <path d="M2362.85,4171.18v-89.5h132.8v89.9S2362.65,4171,2362.85,4171.18Z" transform="translate(75.05)" fill="#f9c138"/>
        <ellipse cx="2504.7" cy="4171.78" rx="66.5" ry="38.9" fill="#f9c138"/>
        <ellipse cx="2504.2" cy="4082.88" rx="66.6" ry="38.9" fill="#f9c138"/>
      </g>
      <path class="bar" data-name="bar" d="M2495.85,4116.78h-133v-143.9a66.48,66.48,0,0,1,33.3-57.6l962-555.1A66.59,66.59,0,0,1,3458,3418l-.4,143.9-133-.4.1-28.3L2496,4011.38v105.4Z" transform="translate(75.05)" fill="#f9c138"/>
      <polyline class="blackmark" data-name="blackmark" points="2766.3 3881.58 2796.4 3836.48 2762.2 3769.48 2710.2 3821.48 2759.4 3882.98"/>
      <polyline class="blackmark" data-name="blackmark" points="3220.2 3624.58 3250.2 3579.48 3216.1 3512.48 3164.1 3564.38 3213.3 3625.98"/>
      <polygon class="rightside" data-name="rightside" points="2655.8 4181.78 2504.2 4269.28 2504.2 4259.28 2655.8 4171.78 2655.8 4181.78" fill="#db8914"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="2504.2 4269.18 2352.7 4181.68 2352.7 4171.68 2504.2 4259.18 2504.2 4269.18" fill="#e8932e"/>
      <polygon class="frontside-sg-hematit" data-name="frontside-sg-hematit" points="3466.2 3714.38 3314.7 3626.88 3314.7 3616.88 3466.2 3704.38 3466.2 3714.38" fill="#e8932e"/>
      <polygon class="rightside-sg-hematit" data-name="rightside-sg-hematit" points="3617.8 3626.78 3466.3 3714.28 3466.3 3704.28 3617.8 3616.78 3617.8 3626.78" fill="#db8914"/>
    </g>
  </g>
</template>
<script>
export default {
  name: "HematitSignalDoubleRight.vue",
};
</script>