<template>
   <g class="arm600">
        <g class="armfront" data-name="armfront">
          <polygon class="innertop topside" data-name="innertop topside" points="1250.97 3078.47 1216.33 3058.47 1216.33 3018.47 1250.97 3038.47 1250.97 3078.47" fill="#5070af"/>
          <polygon class="innerright rightside" data-name="innerright rightside" points="1216.33 3018.47 1216.33 3058.47 1250.97 3038.47 1216.33 3018.47" fill="#365593"/>
        </g>
        <g class="armholder" data-name="armholder">
          <polygon class="armholder-front" data-name="armholder-front" points="1779.69 2815.72 1727.73 2785.72 1727.73 2665.72 1779.69 2695.72 1779.69 2815.72" fill="#7793bf"/>
          <polygon class="armholder-right" data-name="armholder-right-10px" points="1788.36 2810.72 1779.7 2815.72 1779.7 2695.72 1788.36 2690.72 1788.36 2810.72" fill="#3d6199"/>
        </g>
        <polygon class="toparm600 topside" data-name="toparm600 topside" points="1770.58 2738.47 1250.97 3038.47 1216.33 3018.47 1735.94 2718.47 1770.58 2738.47" fill="#5070af"/>
        <polygon class="rightarm600 rightside" data-name="rightarm600 rightside" points="1770.58 2778.47 1250.97 3078.47 1250.97 3038.47 1770.58 2738.47 1770.58 2778.47" fill="#365593"/>
        <g class="double-arm600" v-if="doubleBranch">
          <polygon class="armholder-right" data-name="armholder-right-10px" points="1814.33 2795.44 1805.67 2800.44 1805.67 2680.44 1814.33 2675.44 1814.33 2795.44" fill="#3d6199"/>
          <polygon class="darm600 rightside" data-name="darm600 rightside" points="1814.35 2753.2 2324.84 2458.47 2324.84 2418.47 1814.33 2713.22 1814.35 2753.2" fill="#365593"/>
          <polygon class="darm600 frontside" data-name="darm600 frontside" points="1814.33 2713.22 1814.34 2673.21 2290.2 2398.47 2324.84 2418.47 1814.33 2713.22" fill="#5e7cb7"/>
        </g>
      </g>
</template>
<script>
export default {
  props: {
    doubleBranch: Boolean,
  },

  name: "Arm600-B.vue",
};
</script>